import { GlobalVariablesService } from 'src/app/Shared/global-variables.service';
import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import {  ScrollPanel } from 'primeng/scrollpanel';
import { AppMainComponent } from 'src/app/app.main.component';
import { LanguageTranslateService } from 'src/app/Shared/language-translate.service';
import { SharedService } from 'src/app/Shared/shared.service';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { UserInput, Sys_users, Menu, Languages } from 'src/app/Classes/login';
import { httpService } from '../Shared/http.service';
@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    providers: [ConfirmationService, MessageService],
    animations: [
        trigger('inline', [
            state('hidden', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visible', style({
                height: '*',
            })),
            state('hiddenAnimated', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppMenuComponent implements OnInit, AfterViewInit {

    @Input() reset: boolean;

    model: any[];
    UserName: string;
    inlineModel: any[];
    langgObj: Languages;
    objUserInput : UserInput;
    @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;

    constructor(public app: AppMainComponent,private confirmationService: ConfirmationService , 
        private messageService: MessageService, private _svc: httpService, public global: GlobalVariablesService) {
            this.objUserInput= new UserInput();
         }
    ngAfterViewInit(): void {
        //throw new Error('Method not implemented.');
    }

    GetMenu(){
       
        if (sessionStorage.getItem('menu') !== null)
        {
            this.model = JSON.parse(sessionStorage.getItem('menu')); 
            return;
        }

        this.langgObj = JSON.parse(sessionStorage.getItem('languageObj'));

         this.objUserInput.lang=this.langgObj.langId;
         this.objUserInput.userid=  +this.global.getUserID();
        this._svc.postGenericParmas(this.objUserInput, 'Forms/GetAngularMenu','Security').subscribe(
          data => {
          
    
            this.model= data;

            if (sessionStorage.getItem('menu') == null)
            {
               
                sessionStorage.setItem('menu',JSON.stringify( this.model));
            }
          }, (err) => {
            this.showMessageService("Menu Load","Some Error Occured.", "error");
          }); 
      
      }
      showMessageService(msgsummary : string, msgdetail : string, smsgeverity :string = 'warn') {
        this.messageService.add({severity:smsgeverity, summary: msgsummary, detail: msgdetail});
    }
    ngOnInit() {
        this.UserName = this.global.getUserFullName();


        this.GetMenu();
        // this.model = [
        //     { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/homepage']},
        //     {
        //         label: 'Components', icon: 'pi pi-fw pi-star',
        //         items: [
        //             { label: 'Sample Page', icon: 'pi pi-fw pi-th-large', routerLink: ['/sample']  },
        //             { label: 'Forms', icon: 'pi pi-fw pi-file', routerLink: ['/forms'] },
        //             { label: 'Data', icon: 'pi pi-fw pi-table', routerLink: ['/data'] },
        //             { label: 'Panels', icon: 'pi pi-fw pi-list', routerLink: ['/panels'] },
        //             { label: 'Overlays', icon: 'pi pi-fw pi-clone', routerLink: ['/overlays'] },
        //             { label: 'Menus', icon: 'pi pi-fw pi-plus', routerLink: ['/menus'] },
        //             { label: 'Messages', icon: 'pi pi-fw pi-envelope', routerLink: ['/messages'] },
        //             { label: 'Charts', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/charts'] },
        //             { label: 'File', icon: 'pi pi-fw pi-upload', routerLink: ['/file'] },
        //             { label: 'Misc', icon: 'pi pi-fw pi-spinner', routerLink: ['/misc'] }
        //         ]
        //     },
        //     {
        //         label: 'Pages', icon: 'pi pi-fw pi-copy',
        //         items: [
        //             { label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/empty'] },
        //             { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'], target: '_blank' },
        //             { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
        //             { label: 'Error', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/error'], target: '_blank' },
        //             { label: '404', icon: 'pi pi-fw pi-times', routerLink: ['/404'], target: '_blank' },
        //             {
        //                 label: 'Access Denied', icon: 'pi pi-fw pi-ban',
        //                 routerLink: ['/accessdenied'], target: '_blank'
        //             }
        //         ]
        //     },
        //     {
        //         label: 'Hierarchy', icon: 'pi pi-fw pi-sitemap',
        //         items: [
        //             {
        //                 label: 'Submenu 1', icon: 'pi pi-fw pi-sign-in',
        //                 items: [
        //                     {
        //                         label: 'Submenu 1.1', icon: 'pi pi-fw pi-sign-in',
        //                         items: [
        //                             { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-sign-in' },
        //                             { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-sign-in' },
        //                             { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-sign-in' },
        //                         ]
        //                     },
        //                     {
        //                         label: 'Submenu 1.2', icon: 'pi pi-fw pi-sign-in',
        //                         items: [
        //                             { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-sign-in' }
        //                         ]
        //                     },
        //                 ]
        //             },
        //             {
        //                 label: 'Submenu 2', icon: 'pi pi-fw pi-sign-in',
        //                 items: [
        //                     {
        //                         label: 'Submenu 2.1', icon: 'pi pi-fw pi-sign-in',
        //                         items: [
        //                             { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-sign-in' },
        //                             { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-sign-in' },
        //                         ]
        //                     },
        //                     {
        //                         label: 'Submenu 2.2', icon: 'pi pi-fw pi-sign-in',
        //                         items: [
        //                             { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-sign-in' },
        //                         ]
        //                     },
        //                 ]
        //             }
        //         ]
        //     },
        //     {
        //         label: 'Docs', icon: 'pi pi-fw pi-file', routerLink: ['/documentation']
        //     },
        //     {
        //         label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
        //     }
        // ];
        // this.inlineModel = [
        //     {
        //         label: 'Profile', icon: 'pi pi-fw pi-user'
        //     },
        //     {
        //         label: 'Settings', icon: 'pi pi-fw pi-cog'
        //     },
        //     {
        //         label: 'Messages', icon: 'pi pi-fw pi-envelope'
        //     },
        //     {
        //         label: 'Notifications', icon: 'pi pi-fw pi-bell'
        //     }
        // ];
    }

  

    onMenuClick(event) {
   
        this.app.onMenuClick(event);
    }
}

// @Component({
//     /* tslint:disable:component-selector */
//     selector: '[app-submenu]',
//     /* tslint:enable:component-selector */
//     template: `
//         <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
//             <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
//                 <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
//                    *ngIf="!child.routerLink" [ngClass]="child.styleClass"
//                    [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
//                     <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
//                     <span class="layout-menuitem-text">{{child.label}}</span>
//                     <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
//                 </a>
//                 <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
//                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [fragment]="child.fragment"
//                    [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
//                     <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
//                     <span class="layout-menuitem-text">{{child.label}}</span>
//                     <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
//                 </a>
//                 <div class="layout-menu-tooltip">
//                     <div class="layout-menu-tooltip-arrow"></div>
//                     <div class="layout-menu-tooltip-text">{{child.label}}</div>
//                 </div>
//                 <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
//                     [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
//                     'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
//             </li>
//         </ng-template>
//     `,
//     animations: [
//         trigger('children', [
//             state('hiddenAnimated', style({
//                 height: '0px'
//             })),
//             state('visibleAnimated', style({
//                 height: '*'
//             })),
//             state('visible', style({
//                 height: '*',
//                 'z-index': 100
//             })),
//             state('hidden', style({
//                 height: '0px',
//                 'z-index': '*'
//             })),
//             transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
//             transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
//         ])
//     ]
// })
// export class AppSubMenuComponent {

//     @Input() item: MenuItem;

//     @Input() root: boolean;

//     @Input() visible: boolean;

//     _parentActive: boolean;

//     _reset: boolean;

//     activeIndex: number;

//     constructor(public app: AppMainComponent, public appMenu: AppMenuComponent, public global: GlobalVariablesService) { }

//     itemClick(event: Event, item: MenuItem, index: number) {
//         if (this.root) {
//             this.app.menuHoverActive = !this.app.menuHoverActive;
//         }
       
//         // avoid processing disabled items
//         if (item.disabled) {
//             event.preventDefault();
//             return true;
//         }
//         debugger;
//         this.global.FormName = item.label;
//         // activate current item and deactivate active sibling if any
//         this.activeIndex = (this.activeIndex === index) ? null : index;

//         // execute command
//         if (item.command) {
//             item.command({ originalEvent: event, item });
//         }

//         // prevent hash change
//         if (item.items || (!item.url && !item.routerLink)) {
//             setTimeout(() => {
//                 this.appMenu.layoutMenuScrollerViewChild.moveBar();
//             }, 450);
//             event.preventDefault();
//         }

//         // hide menu
//         if (!item.items) {
//             if (this.app.isHorizontal() || this.app.isSlim()) {
//                 this.app.resetMenu = true;
//             } else {
//                 this.app.resetMenu = false;
//             }

//             this.app.overlayMenuActive = false;
//             this.app.staticMenuMobileActive = false;
//             this.app.menuHoverActive = !this.app.menuHoverActive;
//             this.app.unblockBodyScroll();
//         }
//     }

//     onMouseEnter(index: number) {
//         if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
//             && !this.app.isMobile() && !this.app.isTablet()) {
//             this.activeIndex = index;
//         }
//     }

//     isActive(index: number): boolean {
//         return this.activeIndex === index;
//     }

//     @Input() get reset(): boolean {
//         return this._reset;
//     }

//     set reset(val: boolean) {
//         this._reset = val;
//         if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
//             this.activeIndex = null;
//         }
//     }

//     @Input() get parentActive(): boolean {
//         return this._parentActive;
//     }
//     set parentActive(val: boolean) {
//         this._parentActive = val;
//         if (!this._parentActive) {
//             this.activeIndex = null;
//         }
//     }
// }
