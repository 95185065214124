import { Injectable } from '@angular/core';

import { catchError, map, tap } from 'rxjs/operators';


import { Observable, of } from 'rxjs';
import { Country, LookUpTable, LookUpTableValues, LookUpTableValuesCommon,lookup_ServiceUrls } from '../models/lookup';
import { GlobalVariablesService } from 'src/app/Shared/global-variables.service';
import { Languages, Modules } from 'src/app/Classes/login';
import { httpService } from 'src/app/Shared/http.service';
import { WeekDays, WeekDays_ServiceUrls } from 'src/app/Classes/weekDays';

@Injectable({
  providedIn: 'root'
})
export class DefinitionService {
    objlookup_ServiceUrls: lookup_ServiceUrls;
    objWeekDays_ServiceUrls: WeekDays_ServiceUrls;

  constructor(private _svc: httpService, public global: GlobalVariablesService) {
    this.objlookup_ServiceUrls = new lookup_ServiceUrls();
    this.objWeekDays_ServiceUrls = new WeekDays_ServiceUrls();

   }
   GetAllLanguages(): Observable<Languages[]>  {
    var FK_CompanyId = this.global.FK_CompanyId();

    return this._svc.get(FK_CompanyId, 'FK_CompanyId', 'Language/GetAllLanguages', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetAllLanguages')),
      catchError(this.handleError<Languages[]>('GetAllLanguages', []))
    );
  }

   GetCountryList(): Observable<Country[]>  {
    return this._svc.get(null, null, this.objlookup_ServiceUrls.GetCountryList, 'Core')
     .pipe(
      tap(_ => this.log('fetched Country')),
      catchError(this.handleError<Country[]>('Country', []))
    );
  }

  GetAllLanguagesByCompany(): Observable<Languages[]>  {
    var FK_CompanyId = this.global.FK_CompanyId();

    return this._svc.get(FK_CompanyId, 'FK_CompanyId', 'Language/GetAllLanguagesByCompany', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetAllLanguagesByCompany')),
      catchError(this.handleError<Languages[]>('GetAllLanguagesByCompany', []))
    );
  }

  GetLookUpTable_ByFK_CompanyId(): Observable<LookUpTable[]>  {
    var FK_CompanyId = this.global.FK_CompanyId();

    return this._svc.get(FK_CompanyId, 'FK_CompanyId','LKP/GetLookUpTable_ByFK_CompanyId', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetLookUpTable_ByFK_CompanyId')),
      catchError(this.handleError<LookUpTable[]>('GetLookUpTable_ByFK_CompanyId', []))
    );
  }

  GetLookUpValuesByCompanyForDropDown(LookUpName : string): Observable<LookUpTableValues[]> {
    return this._svc.get(LookUpName,'LKPName',  'LKP/GetLookUpValuesByCompanyForDropDown', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetLookUpValuesByCompanyForDropDown')),
      catchError(this.handleError<LookUpTableValues[]>(' GetLookUpValuesByCompanyForDropDown'))
    );
  }
  GetLookUpValuesByCompany(objlookup : LookUpTable): Observable<LookUpTableValues[]> {
    return this._svc.post(objlookup, 'LKP/GetLookUpValuesByCompany', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetLookUpValuesByCompany')),
      catchError(this.handleError<LookUpTableValues[]>(' GetLookUpValuesByCompany'))
    );
  }
  GetLookUpTableDetails(LookUpId : number): Observable<LookUpTable> {
    return this._svc.get(LookUpId, "LookUpId","LookUpTables/GetLookUpTableDetails", 'Core')
     .pipe(
      tap(_ => this.log('fetched GetLookUpTableDetails')),
      catchError(this.handleError<LookUpTable>(' GetLookUpTableDetails'))
    );
  }

  GetAllLookUpValuesCommon(LookUpId : number): Observable<LookUpTableValues[]> {
    return this._svc.get(LookUpId, 'FK_LookUpId',  'LKP/GetAllLookUpValuesCommon', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetAllLookUpValuesCommon')),
      catchError(this.handleError<LookUpTableValues[]>(' GetAllLookUpValuesCommon', []))
    );
  }

  GetAllLookUpValues(LookUpId : number): Observable<LookUpTableValues[]> {
    return this._svc.get(LookUpId, 'FK_LookUpId',  'LKP/GetAllLookUpValues', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetAllLookUpValues')),
      catchError(this.handleError<LookUpTableValues[]>(' GetAllLookUpValues', []))
    );
  }

  GetLookUpValueCommon(objlookup : LookUpTableValuesCommon): Observable<any> {
    return this._svc.post(objlookup, 'LKP/GetLookUpValueCommon', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetLookUpValueCommon')),
      catchError(this.handleError<LookUpTableValues[]>(' GetLookUpValueCommon', []))
    );
  }

  GetLookUpValue(objlookup : LookUpTableValues): Observable<LookUpTableValues> {
    return this._svc.post(objlookup, 'LKP/GetLookUpValue', 'Core')
     .pipe(
      tap(_ => this.log('fetched GetLookUpValue')),
      catchError(this.handleError<LookUpTableValues[]>(' GetLookUpValue', []))
    );
  }

  GetAllWeekDays(): Observable<WeekDays[]> {
    return this._svc.get(null, null, this.objWeekDays_ServiceUrls.GetAllWeekDays, 'Core')
     .pipe(
      tap(_ => this.log('fetched WeekDays')),
      catchError(this.handleError<WeekDays[]>(' WeekDays', []))
    );
  }

  DeleteLKPValues(objlookup : LookUpTableValues): Observable<any> {
    return this._svc.post(objlookup, 'LKP/DeleteLKPValues', 'Core')
     .pipe(
      tap(_ => this.log('fetched DeleteLKPValues')),
      catchError(this.handleError<LookUpTableValues>(' DeleteLKPValues'))
    );
  }

  DeleteLKPValuesCommon(objlookup : any): Observable<any> {
    return this._svc.post(objlookup, 'LKP/DeleteLKPValuesCommon', 'Core')
     .pipe(
      tap(_ => this.log('fetched DeleteLKPValuesCommon')),
      catchError(this.handleError<LookUpTableValuesCommon>(' DeleteLKPValuesCommon'))
    );
  }


  SaveLKPValues(objlookup : LookUpTableValues): Observable<any> {
    return this._svc.post(objlookup, 'LKP/SaveLKPValues', 'Core')
     .pipe(
      tap(_ => this.log('fetched SaveLKPValues')),
      catchError(this.handleError<LookUpTableValues>(' SaveLKPValues'))
    );
  }

  SaveLKPValuesCommon(objlookup : LookUpTableValuesCommon): Observable<any> {
    return this._svc.post(objlookup, 'LKP/SaveLKPValuesCommon', 'Core')
     .pipe(
      tap(_ => this.log('fetched SaveLKPValuesCommon')),
      catchError(this.handleError<LookUpTableValuesCommon>(' SaveLKPValuesCommon'))
    );
  }



///////Look Up APIs ////////////////////


DeleteLookUpTable(lookupid : number): Observable<any> {
    return this._svc.delete(lookupid, 'LookUpId', this.objlookup_ServiceUrls.DeleteLookUpTable, 'Core')
     .pipe(
      tap(_ => this.log('fetched DeleteLookUpTable')),
      catchError(this.handleError<LookUpTableValuesCommon>(' DeleteLookUpTable'))
    );
  }


  SaveLookUpTable(objlookup : LookUpTable): Observable<any> {
    return this._svc.post(objlookup, this.objlookup_ServiceUrls.SaveLookUpTable,'Core')
     .pipe(
      tap(_ => this.log('fetched SaveLKPValues')),
      catchError(this.handleError<LookUpTableValues>(' SaveLKPValues'))
    );
  }

  GetLookUpTables_ByCompany(): Observable<LookUpTable[]> {
    var FK_CompanyId = this.global.FK_CompanyId();

    return this._svc.get(FK_CompanyId,'FK_CompanyId', this.objlookup_ServiceUrls.GetLookUpTables_ByCompany,'Core')
     .pipe(
      tap(_ => this.log('fetched GetAllLookUpValues')),
      catchError(this.handleError<LookUpTable[]>(' GetAllLookUpValues', []))
    );
  }

  GetLKPPagesForDefinition(): Observable<Modules[]> {
    var langid = this.global.GetLanguage();

    return this._svc.get(langid, "lang", 'Groups/GetLKPPagesForDefinition', 'Security')
     .pipe(
      tap(_ => this.log('fetched GetLKPPagesForDefinition')),
      catchError(this.handleError<Modules[]>(' GetLKPPagesForDefinition', []))
    );
  }


    /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
     private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);
  
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
  
    /** Log a HeroService message with the MessageService */
    private log(message: string) {
      //this.messageService.add(`HeroService: ${message}`);
      console.log(message);
    }
  }
  

