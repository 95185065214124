export const DROPDOWN_LANGS = ['الانجليزية', 'العربية']
export const BUTTONS = ['حفظ', 'حذف', 'مسح', 'الغاء', 'تحديث']
export const ApplicationSetting = ['اعدادات النظام', 'اعدادات النظام', 'اسم العميل بالانجليزية', 'اسم العميل المختصر بالانجليزية', 'اسم العميل بالعربية', 'اسم العميل المختصر بالعربية']
export const Login = 'تسجيل الدخول'
export const Yes = "نعم"
export const No = "لا"
export const Number = "رقم"
export const NumberShortName = "رقم"
export const View = "عرض"
export const LookUpValue = ' الاسم'
export const LookUpValueCode = ' الرمز'
export const OrgUnit = "وحدة العمل";
export const Group = "المجموعة";
export const EmployeeNo = 'رقم الموظف';
export const Search = 'بحث';
export const AddNew = 'اضافة جديد';
export const Clear = 'مسح';
export const Delete = 'حذف';
export const Save = 'حفظ';
export const AddRamadan = 'اضافة رمضان';
export const UpdateRamadan = 'تحديث رمضان';
export const EditRamadan = 'تعديل رمضان';
export const UserName = 'اسم المستخدم';
export const FullName = "الاسم الكامل"
export const HasParent = "يوجد جهة اتصال رئيسية"
export const Edit = "تعديل"
export const addLanguage = "اضافة لغة"
export const ModalHeader = "ترويسة الشكل"
export const AddNewUser = "اضافة مستخدم جديد"
export const Organization = "المؤسسة"
export const Name = "الاسم"
export const ShortName = "الاسم المختصر"
export const Address = "العنوان"
export const PhoneNo = "رقم الهاتف"
export const Fax = "الفاكس"
export const URL = "الموقع الالكتروني"
export const OganizationLevels = "مستويات المؤسسة"
export const Level = "المستوى "
export const LevelName = "اسم المستوى"
export const LevelNameLocal = "Level Name Local Language"
export const ArabicName = "اسم المستوى بالعربية"
export const OrganizationStructure = 'هيكلية المؤسسة'
export const EnglishName = 'الاسم بالانجليزية'
export const EntityCode = 'رمز وحدة العمل'
export const EntityInfo = 'معلومات وحدة العمل'
export const Definitions = 'التعريفات'
export const EmployeeStatus = 'حالة الموظف'
export const EmployeeType = 'نوع الموظف'
export const DefineService = 'تعريف الخدمة'
export const ListofServices = 'قائمة الخدمات'
export const DynamicFormsDefinition = 'تعريف النماذج'
export const AddTemplate = 'اضافة نموذج'
export const Select = 'اختر'
export const Showing = 'عرض'
export const of = 'من'
export const to = 'الى'
export const Records = 'سجلات'

export const UserID = "اسم المستخدم";
export const Email = "البريد الالكتروني";
export const FullNameInlocalLanguage = "الاسم الكامل باللغة المحلية ";
export const UniqueID = "الرمز المميز";
export const ConfirmPassword = "تأكيد كلمة السر";
export const Company = "المؤسسة";
export const PhoneNumber = "رقم الهاتف";
export const OrganizationEntity = "وحدة عمل المؤسسة"
export const JobDescription = "الوصف الوظيفي";
export const isActive = "نشط"
export const Password = "كلمة السر"
export const FilterBy = "الفلترة من خلال"
export const SavedSuccessfully = "تم الحفظ بنجاح"
export const DeletedSuccessfully = "تم الحذف بنجاح"
export const Recordcannotbedeleted = 'لايمكن حذف السجل لانه قيد الاستخدام.';
export const NoRecordFound = "لم يتم العثور على بيانات"
export const SomeErrorOccured = "حدث خطأ"
export const FillMentionedFields = "الرجاء تعبئة الحقول التالية : "
export const BasicInformation = "البيانات الاساسية";
export const OrgInformation = "بيانات المؤسسة";
export const MoreInformation = "المزيد";
export const Color = "اللون";
export const ErrorWhileSaving = "خطأ أثناء الحفظ";


export const January = 'يناير';
export const February = 'فبراير';
export const March = 'مارس';
export const April = 'ابريل';
export const May = 'مايو';
export const June = 'يونيو';
export const July = 'يوليو';
export const Augest = 'اغسطس';
export const September = 'سبتمبر';
export const October = 'اوكتوبر';
export const November = 'نوفمبر';
export const December = 'ديسمبر';

export const Sunday = 'الاحد';
export const Monday = 'الاثنين';
export const Tuesday = 'الثلاثاء';
export const Wednesday = 'الاربعاء';
export const Thursday = 'الخميس';
export const Friday = 'الجمعة';
export const Saturday = 'السبت';

export const languageName = "اللغة"
export const LangId = "LangId"
export const LangCode = "رمز اللغة"
export const lookUpValue_lang = " Value language"
export const Country = "الدولة"
export const orgUnit = "وحدة العمل"
export const SaveDefinition = "حفظ التعريفات"

export const orgUnitName_LL = "اسم وحدة العمل باللغة المحلية";
export const orgUnitName = "اسم وحدة العمل";
export const orgUnitCode = "رمز وحدة العمل";
export const fK_LevelId = "fK_LevelId";
export const CompanyName = "اسم المؤسسة";

export const OrgStructure = "هيكلية المؤسسة";
export const WorkGroup = "مجموعة العمل";

export const id = "id"
export const HasChildren = "يوجد تفرعات";
export const parentid = "parent id ";
export const UnitName = "اسم وحدة العمل";
export const value = "value";
export const ChildName = "اسم التفرع";
export const WorkLocation = "موقع العمل"


export const radius = "نصف القطر";
export const workLocationId = "Work Location Id";
export const workLocationCode = "رمز موقع العمل";
export const workLocationName = "اسم موقع العمل";
export const workLocationName_LL = "اسم موقع العمل باللغة المحلية";
export const gPSCoordinates = "احداثيات الموقع";

export const logicalGroup = 'مجموعة العمل';
export const EmployeeWorkGroup = 'مجموعة عمل الموظف';
export const GroupId = "Group Id"
export const GroupName = "اسم مجموعة العمل";
export const GroupName_LL = "اسم مجموعة العمل باللغة المحلية";
export const EmployeeWorkGroupPeriodSearch = "بحث مجموعة عمل الموظف";

export const statusCode = "رمز الحالة"
export const StatusName = "اسم الحالة"
export const StatusName_LL = "اسم الحالة باللغة المحلية"
export const StatusDescription = "وصف الحالة"
export const CosiderEmployeeActive = "اعتبار الموظف نشط"

export const TypeName = "اسم النوع"
export const TypeName_LL = "اسم النوع باللغة المحلية"
export const IsInternaltype = "داخلي"
export const IsInternal = "داخلي"
export const EmployeeNumberInitial = "ترميز رقم الموظف"
export const EmployeeNumber = "رقم الموظف"
export const Employee = 'الموظف'
export const LstEmployee = 'List of Employee(s)'
export const employeeName = "اسم الموظف"
export const joinDate = "تاريخ التعيين"

export const FK_Status = "الحالة"
export const FK_EmployeeTypeId = "EmployeeTypeId"
export const FK_CompanyId = "Company Id"
export const FK_OrgUnitId = "Unit Id"
export const FK_WorkLocation = "موقع العمل"
export const FK_LogicalGroup = "مجموعة العمل"
export const FK_lkpNationality = "الجنسية"
export const FK_lkpReligion = "الديانة"
export const FK_lkpMaritalStatus = "الحالة الاجتماعية"
export const FK_lkpGrade = "الدرجة الزظيفية"
export const FK_lkpDesignation = "المسمى الوظيفي"
export const IsTerminated = "منتهية خدماته"
export const EmployeeActiveCard = "بطاقة الموظف النشطة"
export const ExternalPartyName = "اسم جهة العمل الخارجية"
export const employeeName_LL = "اسم الموظف باللغة المحلية"
export const Gender = "الجنس"
export const OrgUnitHierarchy = "هيكلية المؤسسة"
export const Remarks = "الملاحظات"
export const EmpImagePath = "موقع صورة الموظف"
export const MobileNo = "رقم الهاتف المحمول"
export const ContractEndDate = "تاريخ انتهاء العقد"
export const DOB = "تاريخ الولادة"
export const TerminateDate = "تاريخ انهاء الخدمات"
export const select = "اختيار"
export const AddNewEmployee = "اضافة موظف جديد"
export const addUnit = 'اضافة وحدة عمل'
export const Exceptions = 'الاستثناءات'

export const GroupID = 'GroupID';
export const DefaultPage = 'الصفحة الافتراضية'
export const AddNewGroup = 'اضافة مجموعة جديدة'
export const Language = 'اللغة'

export const DefaultLanguage = 'اللغة الافتراضية'
export const LocalLanguage = 'اللغة المحلية'
export const LanguageList = 'قائمة اللغات'

export const RamadanWorkSchedule = "جدول رمضان"
export const WorkScheduleTime = "وقت جدول العمل"
export const WorkScheduleSearch = "بحث جدول العمل"
export const FlexibleDuration = "المدة المرنة";
export const ShiftWorkSchedule = "جدول عمل النوبات";
export const ScheduleName = "اسم الجدول"
export const ScheduleNameLL = "اسم الجدول باللغة المحلية"
export const Shifts="النوبات";
export const ShiftCode = "رمز النوبة";
export const ShiftName = "اسم النوبة";
export const ShiftName_LL = "اسم النوبة باللغة المحلية";
export const Normal = "عادي"
export const Flexible = "مرن"
export const Shift = "النوبة"
export const Open = "مفتوح"
export const GraceIn = "مهلة الدخول"
export const GraceOut = "مهلة الخروج"
export const ConsiderDelayFromGrace = "اعتبار التأخير من المهلة"
export const ConsiderEarlyOutFromGrace = "اعتبار الخروج المبكر من المهلة"
export const Default = "الافتراضي"
export const Active = "نشط"
export const InActive = "غير نشط"
export const ScheduleTiming = "وقت الجدول"
export const WorkDays = "ايام العمل"
export const WorkHours = "ساعات العمل"


export const Terminated = "منتهية خدماته"
export const Male = "ذكر"
export const Female = "انثى"
export const DelayIsFromGrace = "التأخير من المهلة"
export const EarlyOutIsFromGrace = "الخروج المبكر من المهلة"
export const Selected = "Selected"
export const IsRamadanSch = "Is Ramadan"
export const IsDefault = "Is Default"
export const AddRamazanSchedule = "اضافة جدول رمضان"
export const SaveRamazanSchedule = "حفظ جدول رمضان";
export const scheduleType = "نوع الجدول"
export const ViewRamazanSchedule = "عرض جدول رمضان"

export const flixibleDuration = "المدة المرنة";
export const fromTime = "من وقت";
export const toTime = "الى وقت";
export const isFlixible = "مرن";
export const toBeCompensated = "يجب تعويضها";
export const noOfAllowedBreak = "عدد الاستراحات المسموحة";




export const Add = "اضافة"
export const Remove = "ازالة"
export const PleaseSelect = "--الرجاء الاختيار--"

export const fromDate = "من تاريخ";
export const toDate = "الى تاريخ";

export const Absent = "غياب"
export const NotAbsent = "ليس غياب"
export const DelayEarlyOut = "التأخير و الخروج المبكر"
export const MissingInorOut = "عدم تسجيل دخول او خروج"
export const NormalAttendees = "الحضور"

export const Continuous = "متواصل"
export const Seperated = "منفصل"
export const Occuerence = "حدوث"

export const Notice = "تنبيه"
export const Warning = "انذار"
export const DeductfromSalary = "اقتطاع من الراتب"
export const Suspension = "ايقاف عن العمل"
export const Dismiss = "انهاء خدمات"
export const Overtime = "العمل الاضافي"

export const WorkDay = "يوم عمل"
export const OffDay = "يوم استراحة"
export const Holiday = "عطلة رسمية"
export const ReligionHoliday = "عطلة دينية"

//Leaves Types 

export const LeaveTypeTitle = "انواع الاجازات";
export const LeaveTypeSearch = "بحث نوع الاجازة";
export const LeaveTypeOccurrenceSearch = "بحث مرات حدوث نوع الاجازة";
export const LeaveTypeOccurrence = "مرات حدوث نوع الاجازة";
export const AdditionalConfiguration = "اعدادات اضافية";
export const AdvancedSettings = "اعدادات متقدمة";
export const LeaveType = "نوع الاجازة";
export const LeaveTypeName = "اسم نوع الاجازة";
export const LeaveTypeNameLL = "اسم نوع الاجازة باللغة المحلية";
export const HourlyLeave = "اجازة ساعية";
export const LeaveTypeCode = "رمز نوع الاجازة";
export const DailyLeave = "اجازة يومية";
export const Balancing = "الترصيد";
export const Monthly = "شهري";
export const Yearly = "سنوي";
export const MinimumDuration = "اقل مدة";
export const MaximumDuration = "اقصى مدة";
export const ServiceDays = "ايام الخدمة";
export const MaximumRoundBalance = "اقصى رصيد مدور";
export const balance = "الرصيد";
export const allowIfBalanceInsufficient = "السماح مع عدم توفر رصيد";
export const generalGuide = "ارشادات عامة";
export const generalGuide_LL = "ارشادات عامة باللغة المحلية";
export const ParentLeaveType = "نوع الاجازة الرئيسية";
export const allowedForSelfService = "السماح في الخدمة الذاتية"
export const showRemainingBalance = "عرض الرصيد المتبقي"
export const NotificationException = "استثناء من الاشعارات";
export const attachmentIsMandatory = "المرفقات مطلوبة"
export const remarksIsMandatory = "الملاحظات مطلوبة";
export const AllGrades = "جميع الدرجات الوظيقية";
export const SpecificGrade = "درجان وظيفية محددة";
export const Grades = "الدرجات الوظيفية";
export const GradeBalance = "رصيد الدرجة الوظيفية";
export const AllOrganizationUnits = "جميع وحدات عمل المؤسسة";
export const SpecificOrganizationUnits = "وحدات عمل محددة";
export const OrganizationUnits = "وحدات عمل المؤسسة";
export const allowedGender = "الجنس المسموح";
export const allowInRamadan = "مسموح في شهر رمضان";
export const ApprovalRequired = "مسموح في رمضان"
export const ApprovalWorkFlow = "مخطط سير الموافقة"
export const isConsiderInWork = "اعتبارها داخل العمل";
export const allowedAfterSpecificTime = "مسموج التقديم بعد وقت محدد";
export const allowedAfter = "مسمومح بعد";
export const allowedBeforeSpecificTime = "مسموح قبل وقت محدد";
export const allowedBefore = "مسموح قبل"
export const TransactionType = "نوع الحركة"
export const notAllowedWithStudyNursing = "غير مسموح عند وجود اجازة دراسة او عناية بالمولود";
export const hasFullDay = "يوجد اجازة ساعية ليوم كامل";
export const hasHourlyLeaveForPeriod = "يوجد اجازة ساعية لفترة";
export const hasHourlyLeaveSpecificTime = "يوجد اجازة ساعية لوقت محدد";
export const hasFlexibleHourlyLeave = "يوجد اجازة ساعية لمدة مرنة";
export const considerRequestWithinBalance = "اعتبار الطلب ضمن الرصيد";
export const convertToLeave_ExceedDuration = "تحويل الى اجازة يومية في حال تجاوز المدة";
export const mustHaveTransaction = "يجب وجود حركة";
export const deductBalanceFromOvertime = "خصم الرصيد من العمل الاضافي";
export const overtimeValidityDays = "ايام صلاحية العمل الاضافي";
export const flexibleAllowedTime = "الوقت المرن المسموح";
export const defaultNoOfDays = "عدد الايام الاولي";
export const Annual = "سنوي"
export const ExcludeOffDays = "استثناء ايام الاستراحة"
export const ExcludeHolidays = "استثناء العطل الرسمية و الاعياد"
export const AllowedBeforeRestDays = "مسموح قبل ايام الاستراحة"
export const LeaveTypeDurationSearch = "بحث مدة نوع الاجازة"
export const LeaveTypeDuration = "مدة نوع الاجازة"
export const balanceConsideration_TC = "Balance Consideration TC";
export const leavestypes = "انواع الاجازات"
export const occuerrence = "الحدوث"
export const duration = "المدة"
export const MaximumOccuerence = "اقصى تكرار"
export const durationName = "اسم المدة"
export const durationNameLL = "اسم المدة باللغة المحلية"
export const maximumRamadanDuration = "اقصى مدة في رمضان"
export const LeaveTypesGrades = "درجات نوع الاجازة"
export const LeaveTypesOrganizationUnits = "وحدة عمل نوع الاجازة"
export const LeaveTypesEmployeeTypes = "انواع موظفين نوع الاجازة"
export const LeaveTypeHourlyTransactionType = "نوع حركة الاجازة الساعية"
export const LeaveTypesDaily = "انواع الاجازة اليومية";
export const LeaveTypesHourly = "انواع الاجازة الساعية";
export const WeekDays = "ايام الاسبوع";

//Application Settings
export const GeneralSettings = "اعدادات عامة"
export const ConsequenceTransactions = "الحركات المتتالية"
export const FirstInOut = "اول دخول/اخر خروج"
export const LastInFirstOut = "اخر دخول/اول خروج"
export const MinimumTimeBetweenTransactions = "(بالدقائق) اقل مدة بين الحركات"
export const TAPolicyGraceBy = "مهلة سماح سياسة الحضور من خلال"
export const WorkSchedule = "جدول العمل"
export const WorkSchedules = "جداول العمل"
export const ConsiderAbsentAfter = "(بالدقائق) اعتبار الموظف غياب بعد"
export const ConsiderAbsentEvenIfAttend = "اعتبار الموظف غياب حتى اذا حضر"
export const ShiftScheduleWithNoShifts = "اعتبار جدول النوبات في حال عدم تعيين نوبات"
export const DefaultWorkSchedule = "جدول العمل الافتراضي"
export const DayMinutes = "اعتبار اليوم (بالدقائق)"
export const ArchivingMonths = "ارشفة البيانات بعد (شهور)"
export const ViolationJustificationDays = "تصحيح المخالفات خلال (ايام)"
export const ExcludeFromViolationJustificationDaysChecklist = "استثناء من ايام تصحيح المخالفات"
export const AllowSelfPunch = "السماح بتسجيل الحركات ذاتياً"
export const Holidays = "العطل الرسمية و الاعياد"
export const DailyLeaves = "الاجازات اليومية"
export const RestDay = "يوم استراحة"
export const Leave = "اجازة"
export const ManualAttendanceWorkFlow = "سير عمل الحركات اليدوية";
export const UpdateRequestWorkFlow = "سير عمل طلب التعديل";
export const AutoHourlyLeaveType = "نوع اجازة ساعية تلقائية";

export const LeaveSettings = "اعدادات الاجازة"
export const AutoPersonalHourlyLeaveForDelay = "اجازة ساعية شخصية تلقائية للتأخير"
export const AutoPersonalHourlyLeaveDuration = "مدة الاجازة التلقائية للاجازة الساعية الشخصية"
export const AutoPersonalHourlyLeaveForEarlyOut = "اجازة ساعية شخصية تلقائية للخروج المبكر"
export const ShowDailyLeaveRequestLinkInViolationCorrection = "السماح لطلب الاجازة اليومية في تصحيح المخالفات"
export const ShowHourlyLeaveRequestLinkInViolationCorrection = "السماح لطلب الاجازة الساعية في تصحيح المخالفات"
export const ConsiderLeaveOnOffDays = "عرض الاجازة اليومية في ايام الاستراحة"

export const ManualEntrySettings = "اعدادات الادخال اليدوي"
export const AttendanceTransactionsSettings = "اعدادات حركات الحضور"
export const attachmentIsMandatoryForRequest = "المرفقات مطلوبة لطلب الادخال اليدوي"
export const NoOfManualEntryPerDay = "عدد الادخالات اليدوية في البوم"
export const AllowedRequestsPerDay = "عدد الطلبات المسموحة في البوم"
export const NoOfManualEntryPerMonth = "عدد الادخالات اليدوية في الشهر"
export const AllowedRequestsPerMonth = "عدد الطلبات المسموحة في الشهر"
export const AutoApproveManualTrans = "الموافقة التلقائية لطلب الحركة اليدوية"
export const ManualTransServiceName = "اسم خدمة طلب الحركة اليدوية"
export const AutoApproveUpdateTrans = "الموافقة التلقائية لطلب تحديث الحركة"
export const UpdateTransServiceName = "اسم خدمة طلب تحديث الحركة"
export const LeaveApprovalWorkflow = "مخطط السير الفتراضي لطلب الحركة اليدوية"
export const ApprovalWorkflow = "مخطط سير الموافقة"
export const AllowEditDate = "السماح بتعديل التاريخ"
export const AllowEditTime = "السماح بتعديل الوقت"

export const TAReason = "اسباب الحضور و الانصراف"
export const AttendanceReasons = "اسباب الحضور"
export const TAReasonTitle = "اسباب الحضور و الانصراف"
export const AttendanceReasonsTitle = "اسباب الحضور"
export const TAReasonSearch = "بحث اسباب الحضور و الانصراف"
export const ReasonCode = "رمز السبب"
export const ReasonName = "اسم السبب"
export const ReasonNameLL = "اسم السبب باللغة المحلية"
export const ConsiderInside = "اعتبار داخل العمل"
export const DisplayType = "عرض النوع"
export const TA_ReasonCodeExists = "رمز السبب موجود مسبقاً";
export const TA_ReasonNameExists = "اسم السبب موجود مسبقاً";
export const TA_ReasonName_LLExists ="اسم السبب باللغة المحلية موجود مسبقاً";

export const LeaveWorkFlow = "سير موافقة الاجازة"
export const ApplicationUsersType = "نوع مستخدم النظام"
export const SystemUsersOnly = "مستخدمي النظام فقط"
export const ActiveDirectoryOnly = "السجل النشط فقط"
export const Both = "كلاهما"
export const ShowLoginPage = "عرض صفحة تسجيل الدخول"
export const WeekStartDay = "يوم بداية الاسبوع"
export const RadioButtonsRestDay = "يوم استراحة"
export const WorkDaysMinutes = "عدد دقائق يوم العمل"

export const ShowDailyLeaveRequest = "عرض طلب الاجازة اليومية"




export const AutoPersonalHourlyLeaveForDuration = "مدة الاجازة التلقائية للاجازة الساعية الشخصية"
export const SpecificEntity = "وحدة عمل محددة"
export const All = "الكل"
export const employees = "الموظفين"
export const Entity = "وحدة العمل"
export const LogicalGroup = "مجموعة العمل"
export const EmployeeList = "قائمة الموظفين"
export const entityList = "قائمة وحدات العمل"
export const WorklocationsList = "مواقع العمل"
export const Temporary = "مؤقت"

export const SpecificGroup = 'مجموعة عمل محددة'
export const Worklocation = "موقع العمل"
export const entity = "وحدة العمل"
export const Manager = "المدير"
export const Managers = "المدراء"

export const ServiceDefinitionServiceCategoryTitle = "تعريف الخدمة -(تصنيف الخدمة";
export const ServiceCode = "رمز الخدمة";
export const ServiceName = "اسم الخدمة";
export const ServiceName_LL = "اسم الخدمة باللغة المحلية";
export const ServiceAbbreviation = "اختصار الخدمة";
export const AppNoFormula = "صيغة رقم الطلب";
export const ServiceCategory = "مجموعة الخدمة";
export const HasWorkflow = "يوجد سير عمل";
export const TemplateDocument = "نموذج الوثيقة";
export const Workflow = "سير العمل";
export const HasFormTemplate = "يوجد نموذج للطلب";
export const FormTemplate = "نموذج الطلب";
export const FormTemplateName = "نموذج الطلب";
export const FormTemplateNameLL = "نموذج الطلب باللغة المحلية";
export const FilesSecurityOption = "خيارات حماية الملفات";
export const SaveEncrypted = "حفظ مشفر";
export const SubmittionType = "نوع التقديم";
export const PublishOnWeb = "نشر على تطبيق الويب";
export const PublishOnMobile = "نشر على تطبيق المحمول";
export const PublishOnKiosk = "نشر على تطبيق الويب";
export const DocumentName = "اسم الوثيقة";
export const TemplateText = "نص النموذج";
export const IsRequired = "مطلوب";
export const IntroText = "نص المقدمة";
export const TOA = "شروط الموافقة";
export const PrivilegesType = "نوع الصلاحيات";
export const SecurityGroup = "مجموعة الحماية";
export const SecurityGroups = "مجموعات الحماية";
export const SaveSecurityGroup = "حفظ مجموعة الحماية";
export const Users = "المستخدمين";
export const User = "المستخدم";
export const IsManualAssign = "تخصيص يدوي";
export const Definition = "التعريفات";
export const ServiceDocuments = "وثائق الخدمة";
export const AddPanel = "اضافة لوحة";
export const AddControl = "اضافة حقل";

export const PanelName = "اسم اللوحة";
export const PanelNameLL = "اسم اللوحة باللغة المحلية";
export const FieldCaption = "اسم الحقل";
export const FieldCaptionLL = "اسم الحقل باللغة الحلية";
export const Mandatory = "اجباري";
export const Unique = "مميز";
export const Indexed = "متسلسل";
export const Hidden = "مخفي";
export const MinimumValue = "اقل قيم";
export const MaximumValue = "اقصى قيمة";
export const AddListItems = "اضافة خيارات القوائم";
export const FieldText = "نص الحقل";
export const FieldTextLL = "نص الحقل باللغة المحلية";
export const FieldValue = "قيمة الحقل";
export const FieldOrder = "ترتيب الحقل";


//// WorkFlow //
export const WorkflowName = 'اسم سير العمل';
export const WorkflowName_LL = 'اسم سير العمل باللغة المحلية';
export const IsStartPoint = 'نقطة بداية';
export const IsEndPoint = 'نقطة نهاية';
export const AllowEdit = 'السماح بالتعديل';
export const AllowDocumentsUpload = 'السماح بتحميل الوثائق';
export const AllowDocumentsDownload = 'السماح بتنزيل الوثائق';
export const HasReasons = 'يوجد اسباب';
export const EditStatus = 'تعديل الحالة';
export const DefineStatus = 'تعريف حالة سير العمل';
export const Privilliges = 'الصلاحيات';
export const StatusActions = 'اجراءات الحالة';
export const ActionName = 'اسم الاجراء';
export const ActionStatus = 'الحالة التالية للاجراء';
export const IsApprove = 'حالة موافقة';
export const ReasonName_LL = 'اسم السبب باللغة المحلية';
export const ReasonOrder = 'ترتيب السبب';
export const ActionReason = 'اسباب الاجراء';
export const StatusList = 'قائمة الحالة لسير العمل';
export const AddEscalation = 'اضافة اجراءات التصعيد';
export const StatusDefinition = 'تعريف الحالة';
export const FormList = 'قائمة الطلبات';
export const FormName = 'اسم نموذج الطلب';
export const Escalation = 'حالة التصعيد';
export const AddReason = 'اضافة سبب للاجراء';
export const IsDirectManager = 'مدير مباشر';
export const EscalationName = 'اسم التصعيد';
export const EscalationName_LL = 'اسم التصعيد باللغة المحلية';
export const EscalationAction = 'اجراءات التصعيد';
export const NotificationGroup = 'مجموعات الاشعار';
export const NumberofDays = 'عدد الايام';
export const ActionList = 'قائمة الاجراءات للحالة';
export const SendNotification = 'ارسال اشعار';
export const ActionsOption = 'اختيار اجراء من اجراءات الحالة';
export const MoveToNewStatus = 'تقديم الطلب لحالة جديدة';
export const EscalationList = 'قائمة التصعيد';

export const DefineCompany = 'تعريف جهات اتصال المؤسسة';
export const ContactCompanyName = 'اسم جهة اتصال المؤسسة';
export const ContactCompanyName_LL = 'اسم جهة اتصال المؤسسة باللغة المحلية';
export const lstCompany = 'قائمة المؤسسات';
export const CompanyInfo = 'معلومات جهة اتصال المؤسسة';
export const CompanyDocuments = 'وثائق المؤسسة';
export const CompanyContactInfo = 'معلومات جهة اتصال المؤسسة';
export const EmployeesNo = 'عدد الموظفين';
export const ParentCompany = 'المؤسسة الرئيسية';
export const CompanyType = 'نوع المؤسسة';
export const CompanyIndustry = 'قطاع المؤسسة';
export const CompanyLogo = 'شعار المؤسسة';
export const IsPublic = 'عام';
export const IsActive = 'نشط';
export const City = 'المدينة';
export const DocumentType = 'نوع الوثيقة';
export const UploadFile = 'تحميل ملف';
export const ContactChannel = 'قناة جهة الاتصال';
export const ContactInfo = 'معلومات جهة الاتصال';
export const FileName = 'اسم الملف';
export const FileExtension = 'امتداد الملف';
export const lstCompanyContactInfo = 'قائمة جهات الاتصال بالمؤسسة';
export const lstCompanyPapers = 'قائمة وثائق المؤسسة';

export const Salutation = 'اللقب';
export const ListOfContacts = 'قائمة جهات الاتصال';
export const Nationality = 'الجنسية';
export const ContactType = 'نوع جهة الاتصال';
export const ContactSource = 'مصدر جهة الاتصال';
export const Position = 'الموقع';
export const Description = 'الوصف';
export const ContactDocuments = 'وثائق جهة الاتصال';
export const UploadDocument = 'تحميل وثيقة';
export const CreateDocument = 'انشاء وثيقة';
export const SavePDF = 'حفظ PDF';
export const AddRemarkPDF = 'اضافة ملاحظات PDF';
export const AddAudioRemark = 'اضافة ملاحظة صوتية';
export const SignDocument = 'توقيع الوثيقة';
export const ShowRemarkFile = 'عرض ملاحظات الملف';

export const LinkToCompany = 'ارتباط بمؤسسة';
export const isMain = 'مؤسسة رئيسية';
export const ContactCompanyList = 'قائمة جهات الاتصال';


export const SearchAllApplications = 'بحث و عرض جميع الطلبات';
export const SearchAssignedApplications = 'بحث و عرض جميع الطلبات التي تم تخصيصها';
export const ListAllApplications = 'قائمة الطلبات';
export const ViewApplicationDetails = 'عرض تفاصيل الطلب';
export const SearchPendingApplications = 'بحث وعرض الطلبات قيد الدراسة';
export const ApplicationNo = 'رقم الطلب';
export const ApplicationDate = 'تاريخ الطلب';
export const ApplicationStatus = 'حالة الطلب';
export const SystemUser = 'مستخدم النظام';
export const FromDate = 'من تاريخ';
export const ToDate = 'الى تاريخ';
export const ApplicationIntroduction = 'نص مقدمة الطلب';
export const ServiceIntroduction = 'مقدمة الخدمة';
export const ServiceFields = 'حقول الخدمة';
export const ServiceAttachments = 'مرفقات الخدمة';
export const ServiceReview = 'مراجعة طلب الخدمة';
export const ServiceTOA = 'شروط الموافقة لطلب الحدمة';
export const isMainAttach = 'المرفق الرئيسي';
export const lstAttacment = 'قائمة مرفقات الطلب';
export const AppFieldName = 'اسم حقل الطلب';
export const AppFieldValue = 'قيمة حقل الطلب';
export const lstFields = 'قائمة حقول و قيم الطلب';
export const ViewContactList = 'عرض جهات اتصال المؤسسة';
export const Contact = 'جهة الاتصال';
export const ViewDownload = 'تنزيل للعرض';
export const UploadEmployeeImage = 'تحميل صورة الموظف';
///// TA Policy Page////

export const TAPolicy = "سياسة الحضور و الانصراف"
export const TA_Policy = "سياسة الحضور"
export const TAPolicyBreak = "سياسة الاستراحة"
export const TAPolicyViolations = "سياسة المخالفات"
export const TAPolicyViolationActions = "سياسية اجراءات المحالفات"
export const TAOvertimeTypes = "انواع العمل الاضافي"
export const TAPolicySearch = "بحث سياسة الحضور و الانصراف"
export const TAPolicyBreakSearch = "بحث استراحات سياسة الحضور و الانصراف"
export const TAPolicyViolationsSearch = "بحث مخالفات سياسة الحضور و الانصراف"

export const TAPolicyName = 'سياسة الحضور و الانصراف';
export const TAPolicyName_LL = 'سياسة الحضور و الانصراف باللغة المحلية';
export const taPolicyengName = 'اسم سياسة الحضور و الانصراف';
export const taPolicyNameLL = 'اسم سياسة الحضور و الانصراف باللغة المحلية';
export const taPolicygracein = 'مهلة بداية الجدول (بالدقائق)';
export const taPolicygraceout = 'مهلة نهاية الجدول (بالدقائق)';
export const taPolicyDelayfromGrace = 'اعتبار التأخير بعد المهلة';
export const taPolicyEarlyOutIsFromGrace = 'اعتبار الخروج المبكر حتى المهلة';
export const taPolicyconsider_FILO_Only = 'اعتبار اول دخول و اخر خروج فقط';
export const taPolicyConsiderAbsentIfNotCompleteNoOfHours = 'اعتبار الموظف غياب في حال عدم اتمام ساعات العمل';
export const taPolicyNoOfNotCompleteHours = 'عدد ساعات عدم اتمام ساعات العمل';
export const taPolicyActive = 'نشط';
export const taPolicyDefault = 'سياسة الحضور الافتراضية';
export const taPolicyviolationName = 'اسم المخالفة';
export const taPolicyviolationName_LL = 'اسم المخالفة باللغة المحلية';
export const taPolicyNoAbsentDays = 'عدد ايام الغياب';
export const taPolicyScenarioMode = 'نوع السيناريو';
export const taPolicyConsideredPeriod = 'الفترة التي سيتم اعتبارها';
export const taPolicyMoreThan = 'اكثر من';
export const taPolicyUpTo = 'حتى';

export const taminovertime = 'اقل مدة عمل اضافي';
export const tamaxovertime = 'اقصى مدة عمل اضافي';
export const taovertimerate = 'معدل العمل الاضافي';
export const taPolicyCompensateToLeave = 'تعويضها برصيد اجازة';
export const taPolicyMustRequested = 'يجب تقديم طلب';
export const taPolicyApprovalRequired = 'الموافقة مطلوبة';
export const taPolicyMinAutoApproveDuration = 'اقل مدة للموافقة التلقائية';
export const taPolicyAllowEditOverTime = 'السماح بتعديل مدة العمل الاضافي';
export const otworkingdays = 'ايام العمل';
export const otoffdays = 'ايام الاستراحة';
export const otholidays = 'العطل الرسمية';
export const otreligionholidays = 'العطل الدينية';
export const otLeaveType = "نوع الاجازة";

export const taPolicyBreakType = "نوع الاستراحة";
export const taPolicyAttendanceReason = "سبب الحضور ذات الصلة";
export const taPolicy_BreakFlixible = "مرن";
export const taPolicyFlexibleDuration = "المدة المسموحة (باليوم)";
export const FromTime = "من وقت";
export const ToTime = "الى وقت";
export const ToBeCompensated = "يجب تعويض مدة الاستراحة";
export const NoOfAllowedBreak = "عدد الاستراحات المسموح بها";
export const BreakType = "نوع الاستراحة";
export const BreakType_LL = "نوع الاستراحة باللغة المحلية";

export const violationRuleType = "نوع المخالفة"
export const considerAbsent = "اعتبار غياب"

export const violationId = "Violation Id"
export const actionSequence = "تسلسل الاجراء"
export const fK_lkpViolationActionId = "LKP Violation Action Id"
export const HighTimeExists = "وقت عالي موجود";
export const WorkDayOverTimeExists = "وقت اضافي ليوم العمل موجود";
export const OffDayOverTimeExists = "وقت اضافي ليوم راحة موجود";
export const HolidayOverTimeExists = "وقت اضافي ليوم العطلة موجود";
export const ReligionHolidayOverTimeExists = "وقت اضافي ليوم العطلة الدينية موجود";

///// Correspondence////
export const CreateCorrespondence = 'انشاء مراسلة';
export const CorrespondenceList = 'قائمة المراسلات';
export const CorrespondenceInfo = 'معلومات المراسلة';
export const RelatedCorrespondence = 'مراسلة ذات الصلة';
export const RelatedDocuments = 'وثائق ذات الصلة';
export const Attachments = 'المرفقات';
export const ExternalAttachments = 'المرفقات الخارجية';
export const History = 'السجل';
export const MetaData = 'بيانات التعريف';
export const Comments = 'التعليقات';
export const CorrespondenceParties = 'جهات المراسلة ذات الصلة';
export const InternalContacts = 'جهات اتصال داخلية';
export const ExternalContacts = 'جهات اتصال خارجية';
export const CorrespondenceType = 'نوع المراسلة';
export const CorrespondenceCategory = 'فئة المراسلة';
export const Subject = 'الموضوع';
export const Priority = 'الاولوية';
export const Classification = 'التصنيف';
export const DueDate = 'تاريخ الاستحقاق';
export const ReplyDate = 'تاريخ الرد';
export const SearchRelatedCorrespondence = 'البحث عن مراسلات ذات الصلة';
export const ListRelatedCorrespondence = 'قائمة المراسلات ذات الصلة';
export const CorrespondenceNumber = 'رقم المراسلة';
export const CorrespondenceSubject = 'موضوع المراسلة';
export const ViewCorrespondenceDetails = 'عرض تفاصيل المراسلة';
//Document Approval
export const ViewDocumentDetails = 'عرض تفاصيل الوثيقة';
export const SearchRelatedDocument = 'البحث عن وثيقة ذات صلة';
export const ListRelatedDocument = 'قائمة الوثائق ذات الصلة';
export const DocumentNumber = 'رقم الوثيقة';
export const AttachDoc = 'ارفاق وثيقة';
export const AttachmentType = 'نوع المرفقات';
export const AttachmentName = 'اسم الرفق';
export const AttachmentFormat = 'صيغة المرفق';
export const ListOfAttachment = 'قائمة المرفقات';
export const ListOfExternalContacts = 'قائمة جهات الاتصال الخارجية';
export const AttachCorrespondence = 'ارفاق مراسلة';
export const ListOfComments = 'قائمة التعليقات';
export const CommentedBy = 'تم التعليق بواسطة';
export const CommentedTime = 'تاريخ ووقت التعليق';
export const Comment = 'التعليق';
export const PrivilligeEntity = 'صلاحية وحدة العمل';
export const ManagerRequiredLevel = 'المستوى الاداري المطلوب';

/// WorkFlow Messages
export const DeleteStatusActionsMsg = 'هل انت متأكد من انك ترغب في حذف اجراءات الحالة؟';
export const DeletePriviligesMsg = 'هل انت متأكد من انك ترغب في حذف صلاحيات الحالة؟';
export const DeleteEscalationMsg = 'هل انت متأكد من انك ترغب في حذف تصعيد الحالة؟';
export const AddRemainingDetailsOfStatus = 'هل ترغب في اضافة تفاصيل الحالة المتبقية؟';
export const DeleteReasonMsg = 'هل انت متأكد من انك ترغب في حذف اسباب الاجراء؟';
export const AddRemainingDetailsOfService = 'هل ترغب في اضافة تفاصيل الخدمة المتبقية؟';
export const TemplateTextin = 'نص النموذج باللغة ';
export const IntroductoryTextin = 'نص المقدمة باللغة ';
export const TOATextin = 'نص شروط الموافقة باللغة';
export const NameInLocalLanguage = 'الاسم باللغة المحلية';
export const Code = 'الرمز';
export const Abbreviation = 'الاختصار';


//Library
export const DefineLibrary = 'تعريف المكتبة';
export const LstLibrary = 'قائمة المكتبات';
export const AddNewLibrary = 'اضافة مكتبة جديدة';
export const LibraryName = 'اسم المكتبة';
export const LibraryName_LL = 'اسم المكتبة باللغة المحلية';
export const Createdby = 'تم الانشاء بواسطة';
export const CreatedDate = 'تاريخ الانشاء';
export const Lastupdatedby = 'اخر تحديث بواسطة';
export const LastUpdatedDate = 'تاريخ اخر تحديث';
export const Remarks_LL = 'الملاحظات باللغة المحلية';
export const IsPersonalLibrary = 'مكتبة شخصية';
export const LibraryHeriarchy = 'هيكلية المكتبة';
export const DefineLibraryHeriarchy = 'تعريف هيكلية المكتبة';
export const hierarchyCode = 'رمز الهيكلية';
export const hierarchyName = 'اسم الهيكلية';
export const hierarchy_LL = 'اسم الهيكلية باللغة المحلية';
export const ParentHeirarchyName = 'اسم الهيكلية الرئيسية';
export const Refersh = 'تحديث';
export const LibraryItem = 'عنصر المكتبة';
export const UploadFileToArchive = 'تحميل ملف لأرشيف المكتبة';
export const LockType = 'نوع القفل';
export const DocumentTemplate = 'نموذج الوثيقة';
export const ViewDocument = 'عرض الوثيقة';
export const AddDocument = 'اضافة وثيقة';
export const ViewFolder = 'عرض الملف';
export const AddFolder = 'اضافة ملف جديد';
export const FolderDetails = 'تفاصيل الملف';
export const LibraryItemName = 'اسم عنصر المكتبة';
export const LibraryItemName_LL = 'اسم عنصر المكتبة باللغة المحلية';
export const BeforeSchedule = "قبل الجدول"
export const AfterSchedule = "بعد الجدول"
export const BrowseLibrary = "تصفح المكتبة"
// Document

export const Preview = 'عرض';
export const GeneralInfo = 'معلومات عامة';
export const PreviousVersion = 'النسخة السابقة';
export const Submit = 'تقديم';

export const CorrespondenceText = 'نص المراسلة';
export const SecurityAndPrivilliges = 'الحماية و الصلاحيات';
export const ServiceArchivePolicy = 'سياسة ارشفة الخدمة';


// Audit Log
export const AuditLog = 'سجل التدقيق';
export const ListOfPreviousActions = 'قائمة الاجراءات السابقة';
export const actionDate = 'تاريخ الاجراء';
export const actionUserName = 'اسم مستخدم الاجراء';
export const currentStatusName = 'اسم الحالة الحالية';
export const previousStatusName = 'اسم الحالة السابقة';

//Reports
//Creaete Report Page
export const AddReport = 'اضافة تقرير';
export const Report = 'التقرير';
export const ReportsCreation = 'انشاء التقارير';
export const SelectReport = 'اختيار تقرير';
export const ViewInPage = 'عرض في الصفحة';
export const ExporttoPDF = 'عرض بصيغة PDF';
export const ExporttoWord = 'عرض بصيغة MS-Word';
export const ListofReports = "قائمة التقارير";
export const ReportCategory = 'فئة التقرير';
export const ReportName = 'اسم التقرير';
export const ReportNameLL = 'اسم التقرير باللغة المحلية';
export const ProcedureName = 'اسم الاجراء(Stored Procedure)';
export const ReportType = 'نوع التقرير';
export const Data = 'بيانات';
export const Chart = 'لوحة قياس';
export const Layout = 'التصميم';
export const Portrait = 'طولي';
export const Landscape = 'افقي';
export const DocumentsChart = 'لوحة قياس الوثائق';
//Report Parameters
export const ReportParameters = 'المتغيرات'
export const ParameterCaption = 'اسم عرض المتغير';
export const ParameterCaptionLL = 'اسم عرض المتغير باللغة المحلية';
export const DefaultValue = 'القيمة الافتراضية';
export const Required = 'مطلوب';
//Report Columns
export const ReportColumns = 'الاعمدة';
export const ControlType = 'نوع الحقل';
export const ColumnDisplay = 'عنوان عرض الحقل';
export const ColumnDisplayLL = 'عنوان عرض الحقل باللغة المحلية';
export const ColumnWidth = 'عرض العمود';
export const Visible = 'مرئي';
export const CopyDocument = 'نسخ الوثيقة';
export const CurrentlyAssigned = 'المعين حالياً';
export const ApplicationTimeLine = 'السجل الزمني للطلب';
export const ApplicationDetails = 'تفاصيل الطلب';
export const Agreement = 'اوافق على الشروط و الاحكام للطلب';
export const ApplicationUser = 'مستخدم الطلب';
export const AppliedBy = 'تمالتقديم بواسطة';
export const FilterByCategory = 'الفلترة حسب تصنيف الخدمة';
export const Document = 'الوثيقة';
export const LibraryPrivilleges = 'صلاحيات المكتبة';

export const AllowDelete = 'السماح بالحذف';
export const AllowNewVersionUpload = 'السماح بتحميل نسخة جديدة';
export const AllowSignature = 'السماح بالتوقيع';
export const AllowDownload = 'السماح بالتنزيل';
export const AllowBarcode = 'السماح بالباركود';
export const AllowShare = 'السماح بالمشاركة';
export const AllowView = 'السماح بالعرض';
export const IsManualArchive = 'ارشفة يدوية';
export const LibraryFoArchive = 'مكتبة للارشيف';
export const ArchiveToHierarchy = 'ارشيف للهيكل';
export const Date = 'التاريخ';
export const InternalContactType = 'نوع جهة الاتصال الداخلي';
export const InternalContactEntity = 'جهة اتصال وحدة عمل المؤسسة';
export const Summary = 'الملخص';
export const Return = 'رجوع';
export const AssignManual = 'تعيين يدوي';
export const Internal = 'داخلي';
export const External = 'خارجي';
export const AllowAttachmentUpload = 'السماح بتحميل الوثائق';
export const AllowAttachmentDownload = 'السماح بتنزيل الوثائق';
export const ListofTemplate = 'قائمة نماذج الوثائق'
export const AddNewItem = 'اضافة بند جديد';
export const AssignManually = 'تعيين يدوي';
export const AssignWorkflow = 'تعيين حسب سير العمل';
export const MoveToLibrary = 'نقل الى المكتبة';
export const MoveAppToLibrary = 'نقل وثائق الطلب الى المكتبة';
export const Version = 'النسخة';
export const Minor = 'ثانوي';
export const Major = 'اساسي';

// Holiday
export const HolidayTitle = "العطل الرسمية و الاعياد"
export const HolidaySearch = "بحث العطل الرسمية و الاعياد"
export const HolidayName = 'اسم العطلة';
export const HolidayNameLL = 'اسم العطلة باللغة المحلية';
export const YearlyFixed = 'ثابتة سنوياً';
export const StartDate = "تاريخ البداية";
export const EndDate = "تاريخ الانتهاء";
export const AllCompanies = "جميع المؤسسات";
export const SpecificCompany = "مؤسسات محددة";
export const AllWorkLocation = "جميع مواقع العمل";
export const SpecificWorkLocation = "مواقع عمل محددة";
export const AllReligions = "جميع الديانات";
export const SpecificReligion = "ديانات محددة";
export const AllEmployeeTypes = "جميع انواع الموظفين";
export const SpecificEmployeeType = "انواع موظفين محددة";
export const AllWorkGroup = "جميع مجموعات العمل";
export const SpecificWorkGroup = "مجموعات عمل محددة";
export const StartDay = "يوم البداية";
export const EndDay = "يوم الانتهاء";
export const WorkLocations = "مواقع العمل";
export const Religions = "الديانات";
export const EmployeeTypes = "انواع الموظفين";
export const WorkGroups = "مجموعات العمل";
export const OrganizationUnit = "وحدة المؤسسة";
export const HolidayWorkLocation = "موقع عمل العطلة";
export const HolidayEmployeeTypes = "نوع موظف العطلة";
export const HolidayWorkGroup = "مجموعة عمل العطلة";
export const HolidayReligion = "ديانة العطلة";

export const Actions = "الاجراءات";
export const SelectAction = "ختيار اجراء";
export const AssignmentType = "نوع التعيين";
export const Content = "المحتوى";
export const LstMetadata = "قائمة البيانات";
export const Label = "Label";
export const Value = "القيمة";
export const Type = "النوع";
export const Cancel = "الغاء";
export const lstAssignedDocuments = "قائمة الوثائق التي تم تعيينها";
export const lstMyDocuments = "قائمة وثائق طلباتي";
export const TemplateName = "اسم النموذج";
export const DocumentStatus = "حالة الوثيقة";
export const DocumentDate = "تاريخ الوثيقة";
export const RecentUploadDocument = "وثائق تم تحميلها حديثاً";
export const RecentViewDocuments = "المستندات التي تم عرضها حديثاً";
export const AccessOptions = "خيارات الوصول";
export const ViewPermission = "عرض السماح";
export const LibraryPrivilliges = "صلاحيات المكتبة";
export const ConfirmDeleteLibraryPrivilliges = "يرجى التأكيد إذا كنت تريد حذف صلاحيات المكتبة";
export const DeleteSuccessLibraryPrivilliges = 'تم حذف صلاحيات المكتبة بنجاح';
export const DeleteErrorLibraryPrivilliges = 'خطأ اثناء حذف صلاحيات المكتبة';
export const SaveSuccessLibraryPrivilliges = 'تم حفظ صلاحيات المكتبة بنجاح';
export const SaveErrorLibraryPrivilliges = 'خطأ اثناء حفظ صلاحيات المكتبة';
export const LibrarySaveSuccess = 'تم حفظ تفاصيل المكتبة بنجاح';
export const LibrarySaveError = 'لم يتم حفظ صلاحيات المكتبة بنجاح';
export const Library = 'المكتبة';
export const ItemCode = 'رمز البند';
export const ItemName = 'اسم البند';
export const ItemName_LL = 'اسم البند باللغة المحلية';
export const ErrorLoadingLibrary = 'حدث خطأ ما أثناء تحميل هيكلة المكتبة';
export const WorkFlowStatus = 'حالة سير العمل';
export const AddWorkFlowStatus = 'اضافة حالة جديدة';
export const Close = 'اغلاق';
export const WorkflowDefinition = 'تعريف مخطط سير العمل';
export const IsAllVersions = 'كل النسخ';
export const IsTemporary = 'مؤقت';
export const ShareDocument = 'مشاركة الوثيقة';
export const ConfirmDeleteLibraryShare = "يرجى التأكيد إذا كنت تريد حذف مشاركة الوثيقة";
export const DeleteSuccessLibraryShare = 'تم حذف مشاركة الوثيقة بنجاح';
export const DeleteErrorLibraryShare = 'خطأ اثناء حذف مشاركة الوثيقة';
export const ShareSaveSuccess = 'تم حفظ مشاركة الوثيقة بنجاح.';
export const ShareSaveError = 'لا يمكن حفظ تفاصيل مشاركة الوثيقة بنجاح.';
export const RenameDocument = 'اعادة تسمية الوثيقة';
export const MoveDocument = 'نقل الوثيقة';
export const SearchMyApp = 'بحث طلباتي';
export const ReAssignApp = 'بحث واعادة تعيين الطلبات';

export const ReAssignApplication = 'اعادة تخصيص الطلبات';
export const ReAssign = 'اعادة تخصيص';
export const LstOfRemarkFiles = 'قائمة ملفات الملاحظات';
export const LstOfAppDoc = 'قائمة الوثائق السابقة';
export const documentFormat = 'صيغة الوثيقة';
export const documentVersion = 'نسخة الوثيقة';
export const documentFile = 'ملف الوثيقة';
export const PreviousDocuments = 'الوثائق السابقة';

export const ActionCheckList = 'لوائح مهام الاجراءات';
export const CheckListName = 'اسم لائحة المهام';
export const CheckListName_LL = 'اسم لائحة المهام باللغة المحلية';
export const CheckListOrder = 'ترتيب لائحة المهام';
export const DeleteCHeckListMsg = 'هل انت متأكد من انك تريد حذف لائحة المهام';
export const DocumentView = 'عرض الوثيقة';
export const ContentType = 'نوع المحتوى ';
export const Extension = 'الصيغة';
export const FileSize = 'حجم الملف';
export const ModifiedBy = 'تم التعديل بواسطة';
export const ModifiedDate = 'تاريخ التعديل';
export const LstPreviousVersions = 'قائم النسخ السابقة';
export const DocumentLog = 'سجل الوثيقة';
export const Actor = 'منفذ الاجراء';
export const Action = 'الاجراء';
export const Time = 'الوقت';


//Assign TA Policy
export const AssignTAPolicy = "تعيين سياسة الحضور و الانصراف";
export const OrganaizationUnit = "وحدة عمل المؤسسة";
export const TAPolicyOrgUnit = "سياسة حضور وحدة العمل";
export const TAPolicyAssignOrgUnit = "تعيين سياسة حضور وحدة العمل";
export const AssignTAPolicyOrgUnitSearch = "بحث سياسة حضور وحدة العمل";

export const TAPolicyEmployee = "سياسة حضور الموظف";
export const TAPolicyAssignEmployee = "تعيين سياسة حضور الموظف";
export const AssignTAPolicyEmployeeSearch = "بحث سياسة حضور الموظف";

export const TAPolicyWorkGroup = "سياسة حضور مجموعة العمل";
export const TAPolicyAssignWorkGroup = "تعيين سياسة حضور مجموعة العمل";
export const AssignTAPolicyWorkGroupSearch = "بحث سياسة حضور مجموعة العمل";

export const TAPolicyWorkLocation = "سياسة حضور موقع العمل";
export const TAPolicyAssignWorkLocation = "تعيين سياسة حضور موقع العمل";
export const AssignTAPolicyWorkLocationSearch = "بحث سياسة حضور موقع العمل";

export const TAPolicyAssignmentSearch = "بحث تعيين سياسة حضور";
export const EmployeeExistsInSameDate = "الموظف موجود في نفس التاريخ";
export const WorkGroupExistsInSameDate = "مجموعة العمل موجودة في نفس التاريخ";
export const WorkLocationExistsInSameDate = "موقع العمل موجود في نفس التاريخ";
export const OrgUnitExistsInSameDate = "وحدة العمل موجودة في نفس التاريخ";

export const assignmentValueName = "اسم التعيين";
export const assignmentValueName_LL = "اسم التعيين باللغة المحلية";
export const assignmentTypeName = "نوع التعيين";
export const assignmentTypeName_LL = "نوع التعيين باللغة المحلية"


//Assign Work Schedule
export const AssignWorkScheduleTitle = "تعيين جدول العمل";
export const AssignWorkScheduleSearch = "بحث تعيين جدول العمل";

export const WorkScheduleOrgUnit = "جدول عمل وحدات العمل";
export const WorkScheduleAssignOrgUnit = "تعيين جدول عمل وحدات العمل";
export const AssignWorkScheduleOrgUnitSearch = "بحث جدول عمل وحدات العمل";

export const WorkScheduleEmployee = "جدول عمل الموظفين";
export const WorkScheduleAssignEmployee = "تعيين جدول عمل الموظفين";
export const AssignWorkScheduleEmployeeSearch = "بحث جدول عمل الموظفين";

export const WorkScheduleWorkGroup = "جدول عمل مجموعات العمل";
export const WorkScheduleAssignWorkGroup = "تعيين جدول عمل مجموعات العمل";
export const AssignWorkScheduleWorkGroupSearch = "بحث جدول عمل مجموعات العمل";

export const WorkScheduleWorkLocation = "جدول عمل مواقع العمل";
export const WorkScheduleAssignWorkLocation = "تعيين جدول مواقع العمل";
export const AssignWorkScheduleWorkLocationSearch = "بحث جدول مواقع العمل";

//ViolationException 
export const ViolationExceptionTitle = "استثناء المخالفات"
export const ViolationException = "استثناء المخالفات";
export const ViolationExceptionSearch = "بحث استثناء المخالفات";
export const ViolationExceptionOrgUnit = "استثناء المخالفات وحدة عمل المؤسسة";
export const ViolationExceptionOrganaizationUnitSearch = "بحث استثناء المخالفات وحدة عمل المؤسسة";
export const ViolationExceptionEmployee = "استثناء المخالفات الموظف";
export const ViolationExceptionEmployeeSearch = "بحث استثناء المخالفات الموظف";
export const ViolationExceptionWorkGroup = "استثناء المخالفات مجموعة العمل";
export const ViolationExceptionWorkGroupSearch = "بحث استثناء المخالفات مجموعة العمل";
export const ViolationExceptionWorkLocation = "استثناء المخالفات موقع العمل";
export const ViolationExceptionWorkLocationSearch = "بحث استثناء المخالفات موقع العمل";

//TAException 
export const TAException = "استثناء الحضور و الانصراف";
export const TAExceptionSearch = "بحث استثناء الحضور و الانصراف";
export const TAExceptionOrgUnit = "استثناء الحضور و الانصراف وحدة العمل";
export const TAExceptionOrganaizationUnitSearch = "بحث استثناء الحضور و الانصراف وحدة العمل";
export const TAExceptionEmployee = "استثناء الحضور و الانصراف الموظف";
export const TAExceptionEmployeeSearch = "بحث استثناء الحضور و الانصراف الموظف";
export const TAExceptionWorkGroup = "استثناء الحضور و الانصراف مجموعة العمل";
export const TAExceptionWorkGroupSearch = "بحث استثناء الحضور و الانصراف مجموعة العمل";
export const TAExceptionWorkLocation = "استثناء الحضور و الانصراف موقع العمل";
export const TAExceptionWorkLocationSearch = "بحث استثناء الحضور و الانصراف موقع العمل";

//Ramadan Period
export const RamadanPeriod = "فترة رمضان";
export const RamadanPeriodSearch = "بحث فترة رمضان";

//Library Tree
export const NewDocument = "وثيقة جديدة";
export const NewItem = "بند جديد";
export const TotalDocuments = "مجموع الوثائق"
export const MyDocuments = "وثائقي"
export const PendingDocuments = "وثائق قيد الدراسة"
export const SharedDocuments = "الوثائق التي تم مشاركتها"
export const DocumentApplication = "وثيقة الطلب"
export const NewApplicationDocument = "وثيقة طلب جديدة"
export const AppliciationNumber = "رقم الطلب"
//Assign Managers
export const ManagerName = "اسم المدير"
export const ManagerNameLL = "اسم المدير باللغة المحلية"
export const AssignManagersTitle = "تعيين المدراء"
export const AssignManagerSearch = "بحث تعيين المدير";
export const ManagerOrgUnit = "مدير وحدة العمل";
export const AssignManagerOrgUnitSearch = "بحث مدير وحدة العمل";
export const ManagerEmployee = "مدير الموظف";
export const AssignManagerEmployeeSearch = "بحث مدير الموظف";
export const ManagerWorkGroup = "مدير مجموعة العمل";
export const AssignManagerWorkGroupSearch = "بحث مدير مجموعة العمل";
export const ManagerWorkLocation = "مدير موقع العمل";
export const AssignManagerWorkLocationSearch = "بحث مدير موقع العمل";


// Leave Types Attachment Type 
export const LeaveTypeAttachType = "نوع مرفق نوع الاجازة";
export const LeaveTypeAttachTypeSearch = "بحث نوع المرفق لنوع الاجازة";
export const attachmentTypeName = "اسم نوع المرفق";
export const attachmentTypeNameLL = "اسم نوع المرفق باللغة المحلية";

//home
export const BusinessProcessApplications = "طلبات اجراءات الاعمال";
export const CorrespondenceApplications = "طلبات المراسلات";
export const RecentlyViewedApplications = "تم العرض حديثاً";
export const PreviousDocumentVersion = "هذه نسخة قديمة من الوثيقة، لعرض احدث نسخة الرجاء اغلاق النافذة";
export const ApplicationsStatistics = "احصائيات الطلبات";
export const YouHave = "لديك";
export const Tasks = "مهمة";
export const OpenDashb = "مفتوحة";


export const New = "جديد";
export const Copy = "نسخ";
export const AddNewVersion = "إضافة نسخة جديدة";
export const Modify = " تعديل";
export const Digitalsign = "التوقيع الالكتروني";
export const Move = "نقل";
export const DeleteVersion = " حذف النسخة"
export const Rename = "إعادة تسمية";
export const Download = "تحميل";
export const DownloadAll = "تحميل جميع النسخ";
export const Share = "المشاركة";
export const SharewithworkGroup = "المشاركة مع مجموعة عمل";
export const Sharewithorgunit = "المشاركة مع وحدة عمل";
export const SharewithSecurityGroup = "المشاركة مع مجموعة ";
export const ShareWithuser = "المشاركة مع مستخدم";

export const FormAccess = "صلاحيات النموذج";
export const EditAccessStatus = "تعديل حالة الصلاحيات";
export const VisibleAccessStatus = "حالة الاطلاع";
export const HiddenAccessStatus = "حالة الاخفاء";
export const DisableAccessStatus = "حالة القراءة فقط";
export const Disable = "تعطيل";
export const None = "لايوجد";
export const ChangeEditAccess = "تغيير تعديل الصلاحيات";
export const ChangeVisibilityAccess = "تغيير صلاحية الاطلاع";
export const ChangeAccess = "تغيير الصلاحية";
export const AccessForIndividual = "صلاحيات للافراد";
export const AccessForCompany = "صلاحيات للمؤسسات";
export const AccessForInternal = "صلاحيات للمستخدم الداخلي";
export const ApplyApplication = "تقديم طلب";
export const MyApplications = "طلباتي";
export const ViewMyApplications = "عرض طلباتي";
export const ViewPendingApplication = "عرض الطلبات قيد الدراسة";
export const Individual = "افراد";
export const Personal = "شخصي";
export const Applicant = "مقدم الطلب";
export const ApplicantName = "اسم مقدم الطلب";
export const ApplicantName_LL = "اسم مقدم الطلب باللغة المحلية";
export const SelectCompanyOrIndividual = "الرجاء اختيار مؤسسة او فرد لتقديم الطلب";
export const ServiceCompanyListMsg = "هذه الخدمة متاحة للمؤسسات فقط، لايوجد لديك مؤسسة مسجلة، الرجاء التواصل مع خدمة العملاء";
export const IndividualMsg = "هذه الخدمة غير متاحة للافراد";
export const NoCompanyMsg = "لايوجد لديك مؤسسة مسجلة";

export const AllApplications = "جميع الطلبات";
export const ClosedApplications = "الطلبات المغلقة";
export const PendingApplications = "الطلبات قيد الدراسة";
export const InProgressApplications = "الطلبات قيد الاجراء";
export const ApplyApplications = "تقديم الطلبات";

export const ApprovalOption = "خيار الموافقة";
export const ManagerOnly = "مدير فقط";
export const AllMemebers = "جميع الاعضاء";
export const SpeccificNumberOfMemebers = "عدد محدد من الاعضاء";
export const AddNewWorkFlow = "اضافة مخطط عمل جديد";
export const AddActions = "اضافة اجراءات";
export const ChooseFile = "اختيار ملف";
//export const AddEscalation = "AddActions";
export const DocumentNumberFormula = "صيغة رقم الوثيقة";
export const DefineOutputTemplate = "تعريف نموذج الوثيقة المخرجة";
export const AllowedStatus = "الحالة المتاحة";
export const IsEmail = "بريد الكتروني";
export const IsDownload = "تنزيل";
export const AddStatus = "اضافة حالة";
export const AddStatusPrivileges = "اضافة حالة متاحة و صلاحيات";
export const OutputTemplate = "نموذج الوثيقة المخرجة";
export const LstOutputTemplatePrivileges = "قائمة صلاحيات نموذج الوثيقة المخرجة";
export const MetaDataFields = "حقول البيانات";
export const Parameters = "المتغيرات";
export const ApiName = "اسم ال API";
export const ApiDesc = "وصف ال API";
export const ApiUrl = "رابط ال API";
export const RequestType = "نوع الطلب";
export const IsBeforeStatus = "قبل الحالة";
export const IfErrorStopProcess = "التوقف اذا حدث خطأ";
export const AddInputParams = "المدخلات";
export const AddOutputParams = "المخرجات";
export const ParameterCategory = "تصنيف المتغيرات";
export const ParameterSource = "مصدر المتغيرات";
export const ParameterValue = "قيمة المتغيرات";
export const ParameterKey = "Parameter Key";
export const LstParameter = "قائمة المدخلات";
export const LstApi = "قائمة ال API";
export const ServiceApi = "خدمة ال API";
export const DeleteApiMsg = "هل انت متأكد من انك تريد حذف ال API التي قمت باختيارها؟";
export const VaidateDeleteApiMsg = "الرجاء اختيار API من الجدول في الاسفل للحذف";
export const LstOutPutParameter = "قائمة المخرجات";

export const IsServiceTemporary = "خدمة مؤقتة";
export const ServiceStartDate = "تاريخ بداية الخدمة";
export const ServiceStartTime = "وقت بداية الخدمة";
export const ServiceEndDate = "تاريخ انتهاء الخدمة";
export const ServiceEndTime = "وقت انتهاء الخدمة";
export const IndividualServicePrivilliges = "صلاحيات المجموعة الفردية";
export const showOnReviewOnly = "اظهار عند العرض فقط";
export const isMultiRecords = "اللوحة تحتوي على عد سجلات";
export const ShowInGrid = "اظهار في الجدول للسجلات المتعددة";
export const FieldList = "قائمة الحقول التي تظهر في الجدول";
export const FieldDataList = "قائمة بيانات الحقول";
export const ServiceDetails = "تفاصيل الخدمة";
export const CRMPrivilliges = "صلاحيات ادارة العملاء";
export const InternalPrivilliges = "الصلاحيات الداخلية";
export const DynamicServiceApi = "وسيلة اتصال الخدمة الديناميكية";
export const AddRemainingDetailsOfApi = "هل ترغب في اضافة تفاصيل ال API المتبقية؟";

export const FormTemplateRules = "قواعد النموذج";
export const RuleName = "اسم القاعدة";
export const ConditionField = "حقل الشرط";
export const Condition = "الشرط";
export const ConditionValue = "قيمة الشرط";
export const ResultField = "حقل النتيجة";
export const ResultFieldProperty = "خاصية حقل النتيجة";
export const ResultFieldValue = "قيمة حقل النتيجة";
export const ListOfRules = "قائمة القواعد";
export const Designer = "المصمم";
export const Rules = "القواعد";
export const DefineRules = "تعريف القواعد";
export const ServiceFormRules = "قواعد نموذج الخدمة";

//Contact
export const DefineContact = 'تعريف جهات الاتصال';
export const ContactInformation = 'معلومات جهة الاتصال';
export const ContactSearch = 'بحث جهة الاتصال';
export const ContactCompanies = 'جهة اتصال المؤسسة';
export const CreateAccount = 'انشاء حساب';
export const ContactName = 'اسم جهة الاتصال';
export const ContactName_LL = 'اسم جهة الاتصال باللغة المحلية';
export const DocUniqueNo = 'رقم الوثيقة';
export const DocUniqueNoType = 'نوع الوثيقة';
export const Privileges = 'الصلاحيات';
export const ParentContact = 'جهة الاتصال الرئيسية';
export const Main = 'رئيسي';
export const Account = 'الحساب';
export const RestPassword = 'اعادة ضبط كلمة السر';
export const ContactAssets = 'اصول جهة الاتصال';
export const ContactSubscriptions = 'اشتراكات جهة الاتصال';
export const ContactAssetsSearch = 'بحث اصول جهة الاتصال';
export const ChildContact = 'جهة اتصال فرعية';
export const ChildContacts = 'جهات اتصال فرعية';
export const ViewChildContacts = 'عرض جهات الاتصال فرعية';
export const ExternalContact = 'جهات اتصال خارجية';

//Contact Assets
export const AssetType = 'نوع الاصل';
export const AssetNo = 'رقم الاصل';
export const AssetName = 'اسم الاصل';
export const AssetNameLL = 'اسم الاصل باللغة المحلية';
export const IssueDate = 'تاريخ الاصدار';
export const ExpiryDate = 'تاريخ الانتهاء';

//Contact Subscription
export const SubscriptionType = 'نوع الاشتراك';
export const SubscriptionNo = 'رقم الاشتراك';
export const Permanent = 'دائم';
export const Renew = 'تجديد';
export const Paid = 'مدفوع';
export const ContactSubscriptionsTitle = 'اشتراكات جهة الاتصال';
export const SubscriptionsSearch = 'بحث الاشتراكات';

//External Privilege Group
export const External_privilegeGroupTitle = 'صلاحيات المجموعة الخارجية';
export const External_privilegeGroupSearch = 'بحث صلاحيات المجموعة الخارجية';
export const GroupTypeName = 'اسم نوع المجموعة';
export const GroupTypeName_LL = 'اسم المجموعة باللغة المحلية';

//Asset Types
export const AssetsTypesTitle = 'انواع الاصول';
export const AssetsTypesSearch = 'بحث';
export const assetTypeName = 'اسم النوع';
export const assetTypeName_LL = 'اسم النوع باللغة المحلية';
export const assetNoCaption = 'الاسم التوضيحي لرقم الاصل';
export const assetNoCaption_LL = 'الاسم التوضيحي باللغة المحلية لرقم الاصل';
export const HasIssueDate = 'يوجد تاريخ اصدار';
export const HasExpiryDate = 'يوجد تاريخ انتهاء';
export const HasWorkFlow = 'يوجد مخطط سير العمل';
export const WorkFlow = 'مخطط سير العمل';
export const HasTemplate = 'يوجد نموذج';

//Subscriptions Types
export const SubscriptionsTypeTitle = 'انواع الاشتراكات';
export const SubscriptionsTypeSearch = 'بحث انواع الاشتراكات';
export const subscriptionTypeCode = 'رمز نوع الاشتراك';
export const subscriptionTypeName = 'اسم نوع الاشتراك';
export const subscriptionTypeName_LL = 'اسم نوع الاشتراك باللغة المحلية';
export const SubscriptionNoFormula = 'صيغة رقم الاشتراك';
export const CardColor = 'لون البطاقة';
export const CardIcon = 'ايقونة البطاقة';

//Subscription Type Features
export const SubscriptionTypeFeaturesTitle = 'ميزات نوع الاشتراك';
export const SubscriptionsFeaturesTypeSearch = 'بحث ميزات نوع الاشتراك';
export const Feature = 'الميزات';
export const DurationType = 'نوع المدة';
export const Duration = 'المدة';
export const Included = 'شاملة';
export const Chargable = 'مدفوعة';
export const Currancy = 'العملة';
export const Fees = 'الرسوم';
export const Discount = 'الخصم';
export const FeatureName = 'اسم الميزة';
export const FeatureName_LL = 'اسم الميزة باللغة المحلية';

//Subscription Type Duration
export const SubscriptionTypeDurationTitle = 'مدة نوع الاشتراك';
export const SubscriptionsTypeDurationSearch = 'بحث مدة نوع الاشتراك';
export const DurationName = 'اسم المدة';
export const DurationName_LL = 'اسم المدة باللغة المحلية';
export const AdultSubscriberNo = 'عدد المشتركين البالغين';
export const ChildSubscriberNo = 'عدد المشتركين الاطفال';
export const AdditionalAdultFees = 'رسوم البالغ الاضافية';
export const AdditionalChildFees = 'رسوم الطفل الاضافية';


//New
export const NoActionForStatus = 'لايوجد اجراءات لهذه الحالة';
export const DeleteStatus = 'حذف الحالة';
export const DeleteAction = 'حذف الاجراء';
export const DeleteOutUptDocumentMsg = 'هل انت متأكد من انك ترغب في حذف الوثيقة المخرجة؟';
export const DeleteOutUptDocumentPrivMsg = 'هل أنت متأكد أنك تريد حذف صلاحيات حالة الوثيقة المخرجة؟ ';
export const DeleteStatusMsg = 'يمكن حذف الحالة فقط إذا لم يتم استخدامها في مخطط سير الطلب. هل أنت متأكد أنك تريد حذف الحالة؟. سيؤدي حذف الحالة إلى حذف جميع إجراءات الحالة ، والتصعيد ، والأسباب.';
//Form Template
export const Sort = 'ترتيب';
export const EditPanel = 'تعديل اللوحة';
export const Templates = 'النماذج';
export const FormVisibility = 'رؤية النموج';
export const FormRules = 'قواعد النموذج';
export const OutputDocuments = 'الوثائق المخرجة';
export const APIintegration = 'الربط الالكتروني';
export const FormCustomization = 'تعديلات النموذج';
export const Field = 'الحقل';
export const Control = 'العنصر';

export const EmailStatus = 'حالة البريد الالكتروني';
export const DownloadStatus = 'حالة التنزيل';
export const OutDocumentName = 'اسم الوثيقة المخرجة';
export const OutDocumentName_LL = 'اسم الوثيقة المخرجة باللغة المحلية';
export const ConditionFieldConditionValue = 'شرط حقل قيمة الشرط';
export const AddWorkFlow = 'اضافة مخطط سير العمل';
export const DeleteWorkFlowMsg = 'هل أنت متأكد أنك تريد حذف مخطط العمل؟ سيؤدي حذف سير العمل إلى حذف الحالة والإجراءات. لا يمكن حذف مخطط العمل في حالة استخدامه في طلب قيد الاجراء. ';
export const Publish = 'نشر';
export const EscalationRules = 'قواعد التصعيد';
export const StatusafterAction = 'الحالة بعد الاجراء';
export const ActionReasons = 'اسباب الاجراء';
export const Mid = 'الحالة بين';
export const OtherStatus = 'الحالة للاسباب الاخرى';
export const SearchWorkFlow = 'بحث مخطط سير العمل';
export const SearchService = 'بحث الخدمة';
export const PublishService = 'نشر الخدمة';
export const ServiceDocumentSecurity = 'حماية وثيقة الخدمة';
export const AutomaticArchive = 'ارشفة تلقائية';
export const ArchiveOption = 'خيار الارشفة';
export const PublishStatus = 'حالة النشر';
export const PublishDate = 'تاريخ النشر';
export const ServiceAllowed = 'الخدمة متاحة';
export const AllowedForExternalContacts = 'الخدمة متاحة للمؤسسات الخاريجة';
export const AllowedForIndividuals = 'متاحة للافراد';
export const AllowedForInternal = 'متاحة للفرد';
export const PublishedOnWeb = 'منشورة على الويب';
export const PublishedOnMobile = 'منشورة على المحمول';
export const ServiceMsg = 'يرجى اختيار تفاصيل الخدمة وحفظها لفتح علامة التبويب هذه. ';
export const ServiceWithFormAndWorkFlowMsg = 'يرجى تحديد تفاصيل الخدمة وحفظها باستخدام النموذج ومخطط العمل لفتح علامة التبويب هذه. ';
export const MandatoryAttachmentPending = 'المرفقات الاجبارية قيد الدراسة';
export const Applicationsneedaction = 'الطلبات بحاجة الى اجراء';
export const MyApp = ' طلباتي';
export const PaginationRecords = 'عرض {first} الى {last} من {totalRecords} السسجلات';
export const ListMyApplications = 'قائمة طلباتي';
export const ListAssignedApplications = 'قائمة الطلبات المنوطة';
export const PrivilegeType = 'نوع الصلاحية';
export const DownloadOutPutDocument = 'تنزيل الوثيقة';
export const servicecolor = 'لون الخدمة';
export const serviceicon = 'شعار الخدمة';
export const AddNewForm = 'اضافة نموذج جديد';
export const FieldControl = 'عنصر الحقل';
export const DeleteDefinition = 'تعريف الحذف';

//Portal Subscribe
export const PersonalInformation = 'المعلومات الشخصية';
export const SubContactInformation = 'معلومات الاتصال الفرعية ';
export const Features = 'الميزات';
export const Payment = 'الدفع';
export const Confirmation = 'التأكيد';
export const FinishSubscription = 'تم حفظ اشتراكك بنجاح ، رقم اشتراكك هو: ';
export const MembershipDuration = 'مدة العضوية ';

//Sub Contacts
export const SubContacts = 'جهات الاتصال الفرعية '

//Additional Features

export const AdditionalFeatures = "الميزات الاضافية"
export const SubscriptionTypeFeature = "ميزة نوع الاشتراك"
export const Price = "السعر"
export const TotalPayment = "مجموع الدفع"
export const CardNumber = "رقم البطاقة"
export const CardCIV = "رمز تعريف البطاقة (CIV)"


//Subscription Info

export const SubscriptionInfo = "معلومات الاشتراك"
export const Subscribers = "المشتركين"
export const Subscription = "الاشتراك"



export const Maximize = "تكبير"
export const Minimize = "تصغير"
export const SignOut = "تسجيل خروج"
export const Portal = "Portal"
export const NotPublishedOnWeb = 'غير منشور على الويب';
export const NotPublishedOnMobile = 'غير منشور على تطبيق الهاتف';
export const AllowedFor = 'مسموح ل';
export const InternalUsers = 'مستخدم داخلي';
export const PortalUsers = 'Portal Users';
export const ShowAll = 'عرض الكل';
export const Introduction = 'المقدمة';
export const ApplicationInformation = 'معلومات الطلب';
export const Attachment = 'المرفق';
export const Finalize = 'انهاء';
export const Service = 'الخدمة';
export const Status = 'الحالة';
export const Details = 'التفاصيل';
export const DeleteServiceMsg = 'يمكن حذف الخدمة فقط إذا لم يتم استخدامها في مخطط سير الطلب أو إذا تمت إضافة أي معلومات متعلقة بمكونات الخدمة. هل أنت متأكد أنك تريد حذف الخدمة؟ ';
export const PublishForm = 'نشر نموذج الطلب';
export const UnPublish = 'غير منشور';
export const Assignment = "تخصيص";
export const EditSecurity = "تعديل الحمابة";
export const AddIntroduction = "اضافة مقدمة";
export const EditIntroduction = "تعديل المقدمة";

export const AddTOA = "اضافة الشروط والاحكام";
export const EditTOA = "تعديل الشروط والاحكام";

export const DeleteMsgItnro = "هل انت متأكد من حذف المقدمة";
export const DeleteMsgTOA = "هل انت متأكد من حذف الشروط والاحكام";
export const TemplatesAndAttachments = "النماذج و المرفقات";
export const SaveAsDraft = "حفظ المسودة";

///VMS
export const CheckInVisitor = "تسجيل دخول الزائر";
export const CheckOutVisitor = "تسجيل خروج الزائر";
export const IsOneTimeVisit = "زيارة لمرة واحدة";
export const RepeatingVisits = "زيارات متكررة";
export const VisitStartDate = "تاريخ بداية الزيارة";
export const VisitEndDate = "تاريخ انتهاء الزيارة";
export const VisitStartTime = "وقت بداية الزيارة";
export const VisitEndTime = "وقت انتهاء الزيارة";
export const VisitDays = "ايام الزيارة";
export const MeetingRoom = "غرفة الاجتماع";
export const CateringType = "نوع خدمة الطعام";
export const CateringPlace = "مكان خدمة الطعام";
export const NoOfPeople = "عدد الاشخاص";
export const VehicleNo = "رقم لوحة المركبة";
export const VehicleCategory = "فئة المركبة";
export const VehicleSource = "مصدر اللوحة";
export const VehicleColor = "لون المركبة";
export const AssestDetails = "تفاصيل الاصول";
export const AssestNo = "رقم الاصل";
export const AssestAllowedDirection = "اتجاه الاصل المسموح";
export const LstAssets = "قائمة الاصول";
export const LstVehicle = "قائمة المركبات";
export const SearchAssets = "بحث الاصول";
export const SearchVehicle = "بحث المركبات";
export const SearchParticipants = "بحث المشاركين";
export const LstParticipants = "قائمة المشاركين";
export const VisitInfo = "معلومات الزيارة";
export const VisitParticipants = "مشاركين الزيارة";
export const VisitAssets = "اصول الزيارة";
export const VisitVehicles = "مركبات الزيارة";
export const CheckIn = "تسجيل دخول الزوار";
export const LstCheckIn = "قائمة الزوار/ الطلبات لتسجيل الدحول";
export const VisitorCardNo = "رقم بطاقة الزائر";
export const ExpectedVisitStartDate = "تاريخ بداية الزيارة المتوقع";
export const ExpectedVisitEndDate = "تاريخ انتهاء الزيارة المتوقع";
export const CheckOutVisitorsMsg = "هل انت متأكد من تسجيل خروج الزوار الذين قمت بإختيارهم";
export const CheckOut = "تسجيل خروج الزوار";
export const LstCheckout = "قائمة الزوار/ الطلبات لتسجيل الخروج";
export const Redirect = "اعادة توجيه";
export const AddAssets = "اضافة اصول";
export const EditAssets = "تعديل الاصول";
export const AddVehicles = "اضافة مركبات";
export const EditVehicles = "تعديل المركبات";
export const performactionmsg = 'هل انت متأكد من القيام بذلك؟';
export const AddParticipants = "اضافة مشاركين";
export const AddShiftsConfirmation = "هل تود اضافة نوبات؟";
// Service Members
export const PictureDescription = "وصف الصورة";
export const PictureDescription_LL = "وصف الصورة باللغة المحلية";
export const UploadPicture = "تحميل الصورة";
export const isFeaturePicture = "صورة مميزة";
export const LstPictures = "قائمة صور الخدمة";
export const PictureFormat = "نوع الصورة";
export const ServicePictures = "صور الخدمة";
export const allowedSlots = "اجمالي الاماكن المتاحة";
export const earnedPoints = "النقاط المكتسبة";
export const requiredPoints = "النقاط المطلوبة";
export const ServiceLocation = "موقع الخدمة";
export const isChargeable = "يوجد رسوم";
export const serviceFee = "رسوم الخدمة";
export const Currency = "العملة";
export const ServiceDuration = "مدة الخدمة";
export const DurationUnit = "وحدة المدة";
export const ServiceBooking = "حجز الخدمة";
export const ServiceSchedule = "جدول المدة";
export const MemberShipInfo = "معلومات العضوية";
export const totalSessionNo = "اجمالي الجلسات";
export const requiredSessionNo = "رقم الجلسة المطلوبة";
export const isRepeated = "جلسات متعددة";
export const ScheduleTime = "تاريح/وقت الجدول";
export const Day = "اليوم";
export const TimeDuration = "المدة الزمنية";
export const lstScheduleTime = "قائمة اوقات الجدول";
export const CheckInTime = "وقت تسجيل الدخول";
export const CheckOutTime = "وقت تسجيل الخروج";
export const AllowSequenceSlots = "السماح بالجلسات المتتالية";
export const IsfullDay = "يوم كامل";
export const IsFlexible = "مرن";
export const Hours = "الساعات";
export const StartTime = "وقت البداية";
export const EndTime = "وقت النهاية";
export const TimeSlots = "المدة الزمنية";

// Applications Invoice
export const Applications_InvoiceTitle = "فواتير الطبات"
export const Applications_InvoiceSearch = "بحث فواتير الطلبات"
export const InvoiceNumber = "رقم الفاتورة"
export const InvoiceDate = "تاريخ الفاتورة"
export const Approved = "تمت الموافقة"
export const Amount = "القيمة"
export const Print = "طباعة"
export const ApplicantInfo = "معلومات مقم الطلب"
export const InvoiceDetails = "تفاصيل الفاتورة"

//Manual Transactions
export const ManualTransactionTitle = "حركات الحضور"
export const ManualTransactionSearch = "بحث حركات الحضور"
export const MoveDate = "تاريخ الحركة"
export const MoveTime = "وقت الحركة"
export const ClearFilter = "مسح البحث"
export const SearchTransaction = "بحث الحركة"
export const SingleEmployee = "موظف واحد"
export const MultipleEmployees = "عدة موظفين"
export const AllowedNumberofManualEntryPerDay = "لايمكن اضافة السجل، العدد المسموح للحركات اليدوية في اليوم هو: ";
export const AllowedNumberofManualEntryPerMonth = "لايمكن اضافة السجل، العدد المسموح للحركات اليدوية في الشهر هو: ";
export const Times = " مرة.";

//Manual Transactions Request
export const ManualTransactionRequestTitle = "طلب حركات الحضور";
export const ManualTransactionRequestSearch = "بحث طلبات حركات الحضور";

// Application
export const ServiceInfo = "معلومات الخدمة"
export const ApplicationInfo = "معلومات الطلب"
export const Attended = "حضور"
export const FullDayService = "الخدمة ليوم كامل ، ووقت تسجيل الخروج ووقت تسجيل الدخول مذكوران أدناه. الرجاء اختيار التواريخ الخاصة بك. "

export const FlexibleService = "الخدمة ذات وقت مرن ، يرجى تحديد التاريخ والوقت. الحد الأقصى لساعات هذه الخدمة  "

export const TimeSlotService = "الخدمة لها فترات زمنية ، يرجى تحديد الفترة المناسبة لك. "
export const RequiredNoOfSlots = "العدد المطلوب"
export const StartDateTime = "تاريخ ووقت البداية";
export const EndDateTime = "تاريخ ووقت الانتهاء";
export const TimeDurationMins = "المدة الزمنية (بالدقائق)";
export const TotalDays = "مجموع الايام";

// Schedule Attendence
export const SearchAttendence = "البحث و تعيين الحضور";
export const SessionDate = "تاريخ الجلسة";
export const AttendenceStatus = "حالة الحضور";
export const SearchApplication = "بحث الطلب";
export const MarkPresent = "تحديد حضور";
export const MarkAbsent = "تحديد غياب";
export const CheckInApplicant = "تسجيل دخول مقدم الطلب";
export const CheckOutApplicant = "تسجيل خروج مقدم الطلب";
export const SubControlType = "نوع التحكم الفرعي ";

//Panel Access
export const PanelHiddenStatus = "حالة اللوحة المخفية ";
export const PanelDisableStatus = "حالة اللوحة المعطلة";
export const Draft = "مسودة";
export const LstDraftApplications = "قائمة الطلبات المحفوظة / المسودة ";
export const DraftDurationApplications = "مدة الطلبات المحفوظة/ المسودة";
export const DraftBookingApplications = "الطلبات المحجوزة المحفوظة/ المسودة";
export const DraftProcessApplications = "طلبات العملية الرقمية المحفوظة/ المسودة";
export const DraftSessionApplications = "طلبات الجلسات المحفوظة/ المسودة";

export const DefineSecurityGroup = "تعريف مجموع الحماية";
export const DefineUsers = "تعريف المستخدمين";
export const LstEmployeeWorkGroup = 'قائمة مجموعات عمل الموظفين'

export const ContactDocumentDelete = 'Are you sure you want to delete contact document.'

//Employee Leaves
export const EmployeeLeaves = "اجازات الموظفين";
export const EmployeeLeavesSearch = "بحث اجازات الموظفين";
export const FullDay = "يوم كامل";
export const Remaining = "متبقي";
export const Characters = "حروف";
export const Hourly = "ساعية";
export const Daily = "يومية";
export const LeaveAttachments = "مرفقات الاجازة";
export const FromDateValidation = "الى تاريخ يجب ان يكون اكبر من او يساوي من تاريخ";
export const FromTimeValidation = "الى وقت يجب ان يكون اكبر من او يساوي من وقت";
export const DaySelectValidation = "الرجاء اختيار يوم واحد على الاقل من القائمة";

export const LeaveTypenotAllowedInRamadan = "نوع الاجازة غير مسموح في شهر رمضان";
export const LeaveExistsSameTypeandPeriod = "الاجازة موجودة مسبقاً بنفس النوع و نفس الفترة";
export const LeaveExistsDifferentTypeandPeriod = "الاجازة موجودة مسبقاً بنوع مختلف و نفس الفترة";

export const LeaveRequestExistsSameTypeandPeriod = "طلب الاجازة موجودة مسبقاً بنفس النوع و نفس الفترة";
export const LeaveRequestExistsDifferentTypeandPeriod = "طلب الاجازة موجودة مسبقاً بنوع مختلف و نفس الفترة";

export const LeaveExistsSameTypeandToDateOverlap = "الاجازة موجودة مسبقاً بنفس النوع و تداخل في الى تاريخ";
export const LeaveExistsDifferentTypeandToDateOverlap = "الاجازة موجودة مسبقاً بنوع مختلف و تداخل في الى تاريخ";
export const LeaveExistsSameTypeandFromDateOverlap = "الاجازة موجودة مسبقاً بنفس النوع و تداخل في من تاريخ";
export const LeaveExistsDifferentTypeandFromDateOverlap = "الاجازة موجودة مسبقاً بنوع مختلف و تداخل في من تاريخ";

export const LeaveRequestExistsSameTypeandToDateOverlap = "طلب الاجازة موجودة مسبقاً بنفس النوع و تداخل في الى تاريخ";
export const LeaveRequestExistsDifferentTypeandToDateOverlap = "طلب الاجازة موجودة مسبقاً بنوع مختلف و تداخل في الى تاريخ";
export const LeaveRequestExistsSameTypeandFromDateOverlap = "طلب الاجازة موجودة مسبقاً بنفس النوع و تداخل في من تاريخ";
export const LeaveRequestExistsDifferentTypeandFromDateOverlap = "طلب الاجازة موجودة مسبقاً بنوع مختلف و تداخل في من تاريخ";

export const LeaveExistsSameTypeBetweenSelectedPeriod = "الاجازة موجودة مسبقاً بنفس النوع و خلال الفترة التي قمت بإختيارها";
export const LeaveExistsDifferentTypeBetweenSelectedPeriod = "الاجازة موجودة مسبقاً بنوع مختلف و خلال الفترة التي قمت بإختيارها";

export const LeaveRequestExistsSameTypeBetweenSelectedPeriod = "طلب الاجازة موجودة مسبقاً بنفس النوع و خلال الفترة التي قمت بإختيارها";
export const LeaveRequestExistsDifferentTypeBetweenSelectedPeriod = "طلب الاجازة موجودة مسبقاً بنوع مختلف و خلال الفترة التي قمت بإختيارها";

export const LeaveExceededDailyDuration = "الاجازة تجاوزت المدة اليومية المسموح بها";
export const LeaveExceededWeeklyDuration = "الاجازة تجاوزت المدة الاسبوعية المسموح بها";
export const LeaveExceededMonthlyDuration = "الاجازة تجاوزت المدة الشهرية المسموح بها";
export const LeaveExceededYearlyDuration = "الاجازة تجاوزت المدة السنوية المسموح بها";
export const LeaveExceededAllServiceTimeDuration = "الاجازة تجاوزت مدة كل فنرة الخدمة المسموح بها";
export const LeaveExceededDailyOccurrence = "الاجازة تجاوزت المرات اليومية المسموح بها";
export const LeaveExceededWeeklyOccurrence = "الاجازة تجاوزت المرات الاسبوعية المسموح بها";
export const LeaveExceededMonthlyOccurrence = "الاجازة تجاوزت المرات الشهرية المسموح بها";
export const LeaveExceededYearlyOccurrence = "الاجازة تجاوزت المرات السنوية المسموح بها";
export const LeaveExceededAllServiceTimeOccurrence = "الاجازة تجاوزت مرات كل فترة الخدمة المسموح بها";
export const LeaveAllowedAfter = "الاجازة مسموح بها بعد ";
export const LeaveAllowedBefore = "الاجازة مسموح بها قبل ";
export const AllowedFlexibleDurationIs = "المدة المرنة المسموح بها هي ";

export const NoOrgLevelsFound = "No Orgnization Level(s) Found. Please add level(s) first to add organization units.";
export const LookUp = ' LookUp';

//Employee Leaves Request
export const EmployeeLeavesRequest = "طلب اجازة";
export const LeaveRequest = "طلب اجازة";
export const LeaveRequestAttachments = "مرفقات طلب الاجازة";


//Plan
export const BusinessPlan = 'خطة العمل';
export const PlanName = 'اسم الخطة';
export const PlanDescription = 'وصف الخطة';
export const MonthDuration = 'مدة الاشهر';
export const CurrentlyAvailable = 'متاح حالياً';

export const RegistrationDate = 'تاريخ التسجيل';
export const RegistrationStatus = 'حالة لتسجيل';
export const RegisteredCompanies = 'المؤسسات المسجلة';
export const CompanyRegistration = 'تسجيل المؤسسة';
export const IsFinalApproval = 'موافقة نهائية';


// HCM Summary Page
export const MyRequests = 'طلباتي';
export const HistoryRequests = 'طلباتي السابقة';
export const SearchMyRequests = 'بحث طلباتي';
export const MySummary = 'الملخص';
export const ScheduleTimeDetails = 'وقت الجدول';
export const FirstIn = 'اول دخول';
export const LastOut = 'اخر خروج';
export const ExpectedOut = 'الخروج المتوقع';
export const WorkDuration = 'مدة العمل';
export const WorkProgress = 'انجاز العمل';
export const AbsentCount = 'غياب';
export const DailyLeaveCount = 'الاجازات اليومية';
export const HourlyLeaveCount = 'الاجازات الساعية';
export const ViolationCount = 'المخالفات';
export const RequestLeave = 'طلب اجازة';
export const AttendanceTransactionRequest = 'طلب حركة يدوية';
export const In = 'دخول';
export const Out = 'خروج';
export const PreviousTransaction = 'الحركة السابقة';
export const ViolationTitle = 'المخالفات';
export const ViolationSearch = 'بحث المخالفات';
export const ViolationType = 'نوع المخالفة';
export const ViolationDate = 'تاريخ المخالفة';
export const ViolationDuration = 'مدة المخالفة';
export const ViolationStatus = 'حالة المخالفة';
export const ViolationDetails = 'تفاصيل المخالفة';
export const EmployeeViolations = 'مخالفات الموظف';
export const Delay = 'تأخير';
export const EarlyOut = 'خروج مبكر';
export const OutDuration = 'مدة الخروج';
export const MissingIn = 'عدم تسجيل دخول';
export const MissingOut = 'عدم تسجيل خروج';
export const Justify = 'تبرير';
export const Calendar = 'الاجندة';
export const Transactions = 'الحركات';
export const TransactionTitle = 'قائمة الحركات';
export const TransactionSearch = 'بحث الحركات';
export const today = 'اليوم';
export const calbuttontoday = 'اليوم';
export const calbuttonmonth = 'الشهر';
export const calbuttonweek = 'الاسبوع';
export const calbuttonday = 'يوم';
export const calbuttonlist = 'أجندة';
export const EmployeeRequests = 'طلبات الموظف';
export const EmployeeLastTransaction = 'اخر حركة للموظف';
export const Attendance = 'الحضور';
export const EmployeeScheuleDetails = 'تفاصيل جدول عمل الموظف';
export const EmployeeTAPolicyDetails = 'تفاصيل سياسة حضور الموظف';
export const EmployeeLogicalGroupDetails = 'تفاصيل مجموعة الموظف';
export const EmployeeViolationExceptionsDetails = 'تفاصيل استثناء مخالفات الموظف';
export const EmployeeTAExceptionsDetails = 'تفاصيل استثناء سياسة حضور الموظف';
export const EmployeeCalendar = 'رزنامة الموظف';
export const EmployeeDashboard = 'لوحة قياس الموظف';
export const SummaryPage = 'صفحة الملخص';
export const EmployeeTransactions = 'حركات الموظف';
export const DateRange = 'حدود التاريخ';
export const MonthSelection = 'اختيار الشهر';
export const RequestDailyLeave = 'طلب اجازة يومية';
export const RequestHourlyLeave = 'طلب اجازة ساعية';

// HCM Manager Summary Page
export const ContactDetails = 'تفاصيل جهة الاتصال';
export const AssignedApplications = 'الطلبات المعينة';
export const HistoryApplications = 'الطلبات السابقة';
export const JobTitle = 'المسمى الوظيفي';
export const SearchName = 'بحث الاسم';
export const SearchNo = 'بحث الرقم';
export const MyTeam = 'فريقي';
export const List = 'القائمة';
export const Applications = 'الطلبات';
export const Violations = 'المخالفات';

export const DeleteGroupConfirmMsg = 'المجموعة لاينبغي ان تكون مستخدمة في النظام حتى تتمكن من الحذف، هل انت متأكد من انك تريد حذف المجموعة';
export const DeleteGroupSucess = 'تم حذف مجموعة الحماية بنجاح';
export const DeleteGroupError = 'لايمكن حذف مجموعة الحماية، الرجاء التأكد من عدم استخدام المجموعة في النظام';

export const NewPassword = 'كلمة مرور جديدة';
export const ChangePassword = 'تغيير كلمة المرور';
export const EditDocumentTemplate = 'تعديل نموذج الوثيقة';
export const ApprovedApps = "الطلبات الموافقة"
export const RejectedApps = "الطلبات المرفوضة"
export const MyApps = "طلباتي"
export const InProgressApps = "طلبات قيد الدراسة"
export const AssignedApps = "الطلبات المخصصة"
export const WorkedApps = "الطلبات المعمولة سابقاً"
export const loadingapp = "Loading Application Details"
export const SubmitNow = "Submit Now"
export const Next = "Next"
export const Back = "Back"
export const ReturnHome = "Return Home"
export const Previous = "Previous"
export const HomePage = "Home Page"
export const PreviewUpload = "Preview Uploaded Document";
export const EditDocument = "Edit Document"
export const DocumentMetaData = 'Document Data';


//Employee Leaves Balance
export const EmployeeLeavesBalance = 'رصيد الاجازة';
export const EmployeeLeaveBalanceSearch = 'بحث رصيد الاجازة';
export const totalBalance = 'مجموع الرصيد';
export const currentBalance = 'الرصيد الحالي';
export const newBalance = 'الرصيد الجديد';
export const Filter = 'فلترة';
export const FilterBalance = 'فلترة الرصيد';


export const ReportHeader = 'ترويسة التقرير';
export const ReportFooter = 'تذييل التقرير';
export const PublishSettings = "اعدادات النشر";
export const AdvanceFeatures = "الميزات المتقدمة";
export const EditNotificationTemplate = 'تعديل نموذج الاشعار';
export const RulesFormTemplateMsg = 'النموذج مطلوب لاضافة قاعدة';
export const ChooseColors = 'اختيار الالوان';
export const ChooseImage = 'اختيار الصورة';
export const FinalizeService = 'انهاء الخدمة';
export const ServiceHome = 'الصفحة الرئيسية للخدمة';

//Define Lookups
export const DefineLookUpTitle = 'تعريف التعريفات';
export const LookUpName = 'اسم التعريف';
export const LookUpNameLL = 'اسم التعريف باللغة المحلية';
export const LookUpSearch = 'بحث التعريفات';
export const LookUpList = 'قائمة التعريفات';
export const LookUpValuesExists = 'قيم التعريفات موجودة مسبقاً، الرجاء حذف قيم التعريفات حتى تتمكن من حذف التعريف';

// Assign Shift Schedule
export const AssignShiftsTitle = 'تعيين المناوبات';
export const Years = 'السنة';
export const Months = 'الشهر';
export const Schedule = 'الجدول';


export const HCMNotificationType = 'HCM Notification Type';

export const HRDesk = 'HR Desk';
