export class WeekDays {
    dayId: number;
    dayName: string;
    dayName_LL: string;
    dayOrder: number;
    Selected:boolean;

    constructor() {
        this.dayId = 0;
        this.dayName = null;
        this.dayName_LL = null;
        this.dayOrder = 0;
        this.Selected = false;
    }
}

export class WeekDays_ServiceUrls {
	GetAllWeekDays:string;

    constructor() {
    this.GetAllWeekDays = "WeekDays/GetAllWeekDays";
    }
}