import { Component, OnInit } from '@angular/core';
import { LanguageTranslateService } from 'src/app/Shared/language-translate.service';
import { BaseComponent } from 'src/app/Shared/base-component';
import { SharedService } from 'src/app/Shared/shared.service';
import { GlobalVariablesService } from 'src/app/Shared/global-variables.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from "primeng/api";
import { Languages, Sys_users } from 'src/app/Classes/login';
import { DefinitionService } from 'src/app/definitions/services/definition.services';
import { httpService } from 'src/app/Shared/http.service';
import { Plan_ServiceUrls } from 'src/app/Classes/Plan';
@Component({
  selector: 'app-activateaccount',
  templateUrl: './activateaccount.component.html',
  styleUrls: ['./activateaccount.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ActivateaccountComponent extends BaseComponent implements OnInit {

  Sys_Users: Sys_users;
  objLanguage: Languages;
  lstLanguages: Languages[];
  selectedLanguage: number = 0;
  objPlan_ServiceUrls: Plan_ServiceUrls;

  constructor(public languageTranslateService: LanguageTranslateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private _svc: httpService, public global: GlobalVariablesService, private router: Router,
    private arouter: ActivatedRoute, public _defservice: DefinitionService) {
    super(languageTranslateService);
    this.languageTranslateService.setLang("en");

    this.objPlan_ServiceUrls = new Plan_ServiceUrls();


  }


  ngOnInit(): void {

    this.Sys_Users = new Sys_users();
    this.lstLanguages = [];
    this.GetLanguages();

    let EncryptUserId = '';
    this.arouter.paramMap.subscribe((params: ParamMap) => {
      EncryptUserId = params.get('id');
      this.Activate(EncryptUserId);
    });
  }
  language: language[] = [
    { value: 'English', viewValue: 'English' },
    { value: 'Arabic', viewValue: 'Arabic' }
  ];

  GetLanguages() {
    this._defservice.GetAllLanguages().subscribe(
      data => {
        this.lstLanguages = data;
        this.global.Languages = this.langObject;
      }, (err) => {
        alert("error occured ")
      });

  }
  showMessageService(msgsummary: string, msgdetail: string, smsgeverity: string = 'warn') {
    this.messageService.add({ severity: smsgeverity, summary: msgsummary, detail: msgdetail });
  }
  Activate(UserId) {
    debugger;
    // if(this.Sys_Users.userPwd === ""){
    //   this.showMessageService("Registration","Please Enter Password.", "error");
    //   return;
    //          }
    //          if(this.Sys_Users.ConfirmPWD === ""){
    //           this.showMessageService("Registration","Please Enter Confirm Password.", "error");
    //           return;
    //                  }
    //          if(this.Sys_Users.ConfirmPWD !== this.Sys_Users.userPwd){
    //           this.showMessageService("Registration","Password Does Not Match Confirm Password.", "error");
    //           return;
    //                  }



    this.Sys_Users.userName = UserId;
    this._svc.post(this.Sys_Users, this.objPlan_ServiceUrls.ActivatePlan, 'Security').subscribe(
      data => {
        debugger;
        if (data.errorMessage == "Success") {

          this.Sys_Users = data;

          //this.GetModulesAndForms();
        }
        else
          alert("invalid Credential");
      }, (err) => {
        alert("Error in Connecting API");
      });





  }

  Redirect() {


    this.objLanguage = this.lstLanguages.filter(lang => lang.langId === 2)[0];
    this.selectedLanguage = this.objLanguage.langId;

    sessionStorage.setItem('objSys_users', JSON.stringify(this.Sys_Users));
    sessionStorage.setItem('lang', this.selectedLanguage.toString());
    sessionStorage.setItem('languageObj', JSON.stringify(this.objLanguage));
    sessionStorage.setItem('token', this.Sys_Users.token);
    this.languageTranslateService.setLang(this.objLanguage.langCode.toLowerCase());
    this.router.navigateByUrl('/homepage');


  }
}
interface language {
  value: string;
  viewValue: string;
}