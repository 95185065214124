
export class Company {
  companyId: number;
  fK_OrgCompanyId: number;
  fK_ParentCompanyId: number;
  fK_lkpCompanyTypeId: number;
  fK_lkpCompanyIndustryId: number;
  companyNameEn: string;
  companyName_LL: string;
  companyLogo: string;
  fK_lkpCountryId: number;
  fK_lkpCityId: number;
  employeesNo: number;
  remarks: string;
  isPublic: number;
  cREATED_BY: string;
  cREATED_DATE: Date;
  lAST_UPDATE_BY: string;
  lAST_UPDATE_DATE: Date;
  isActive: number;
  lstCompanyContactInfo: Company_ContactInfo[];
  lstCompanyPapers: CompanyPapers[];
  constructor() {
    this.companyId = 0;
    this.fK_OrgCompanyId = 0;
    this.fK_ParentCompanyId = 0;
    this.fK_lkpCompanyTypeId = 0;
    this.fK_lkpCompanyIndustryId = 0;
    this.companyNameEn = "";
    this.companyName_LL = "";
    this.companyLogo = "";
    this.fK_lkpCountryId = 0;
    this.fK_lkpCityId = 0;
    this.employeesNo = 0;
    this.remarks = "";
    this.isPublic = 0;
    this.cREATED_BY = "";
    this.cREATED_DATE = new Date();
    this.lAST_UPDATE_BY = "";
    this.lAST_UPDATE_DATE = new Date();
    this.isActive = 0;
    this.lstCompanyContactInfo = [];
    this.lstCompanyPapers = [];
  }
}
export class Company_ContactInfo {
  contactInfoId: number;
  fK_CompanyId: number;
  fK_ContactId: number;
  fK_lkpContactChannelid: number;
  contactInfo: string;
  isActive: number;
}
export class ListContactChannel {
  contactInfo: string;
  LKP_contactchannelid: number;
  contactchannelname: string;
  contactInfoId: number;
}
export class Contact_Companies {
  fK_CompanyId: number;
  fK_ContactId: number;
  isMainContact: boolean;
  orgUnit: string;
  position: string;
}
export class CompanyPapers {
  documentsId: number;
  fK_CompanyId: number;
  fK_lkpCompanyDocTypeId: number;
  fileName: string;
  fileExtension: string;
  remarks: string;
  cREATED_BY: string;
  cREATED_DATE: Date;
  lAST_UPDATED_BY: string;
  lAST_UPDATED_DATE: Date;
  isActive: boolean;
  uploadedFile: string;
  companyDocType: string;
}
export class Contact {
  contactId: number;
  fK_ParentContactId: number;
  fK_OrgCompanyId: number;
  isIndividual: boolean;
  fK_LKPContactNoType: number;
  uniqueId: string;
  fK_lkpSalutationId: number;
  contactName: string;
  contactName_LL: string;
  dob: Date;
  fK_GenderId: number;
  fK_lkpNationalityId: number;
  fK_lkpContactSourceId: number;
  description: string;
  remarks: string;
  isPublic: boolean;
  cREATED_BY: string;
  cREATED_DATE: Date;
  lAST_UPDATE_BY: string;
  lAST_UPDATE_DATE: Date;
  isActive: boolean;
  lstCompanyContactInfo: Company_ContactInfo[];
  lstContactPapers: ContactPapers[];
  userName: string;
  Selected: boolean;
  subscriptionDurationType: number;
  fK_lkpContactTypeId : number;
  constructor() {
    this.fK_lkpContactTypeId = 0;
    this.contactId = 0;
    this.fK_ParentContactId = null;
    this.fK_OrgCompanyId = 0;
    this.isIndividual = false;
    this.fK_LKPContactNoType = 0;
    this.uniqueId = "";
    this.fK_lkpSalutationId = 0;
    this.contactName = "";
    this.contactName_LL = "";
    this.dob = new Date();
    this.fK_GenderId = 0;
    this.fK_lkpNationalityId = 0;
    this.fK_lkpContactSourceId = 0;
    this.description = "";
    this.remarks = "";
    this.isPublic = false;
    this.cREATED_BY = "";
    this.cREATED_DATE = new Date();
    this.lAST_UPDATE_BY = "";
    this.lAST_UPDATE_DATE = new Date();
    this.isActive = false;
    this.lstCompanyContactInfo = [];
    this.lstContactPapers = [];
    this.userName = "";
    this.Selected = false;
    this.subscriptionDurationType = null;
  }
}
export class ContactPapers {
  documentsId: number;
  fK_ContactId: number;
  fK_lkpContactDocTypeId: number;
  fileName: string;
  fileExtension: string;
  remarks: string;
  cREATED_BY: string;
  cREATED_DATE: Date;
  lAST_UPDATED_BY: string;
  lAST_UPDATED_DATE: Date;
  isActive: boolean;
  uploadedFile: string;
  contactDocType: string;
}
export class ContactCompany_ServiceUrls {
  GetCRMCompanyByOrg: string;
  GetCRMCompany: string;
  SaveCRMCompany: string;
  DeleteCRMCompany: string;
  DeleteCompanyPapers: string;
  GetContactListByCompany: string;
  constructor() {
    this.GetCRMCompanyByOrg = "Company/GetCRMCompanyByOrg";
    this.GetCRMCompany = "Company/GetCRMCompany";
    this.SaveCRMCompany = "Company/SaveCRMCompany";
    this.DeleteCRMCompany = "Company/DeleteCRMCompany";
    this.DeleteCompanyPapers = "Company/DeleteCompanyPapers";
    this.GetContactListByCompany = "Contact/GetContactListByCompany";
  }
}
export class Contact_ServiceUrls {
  GetCRMContact: string;
  GetCRMContactByOrg: string;
  GetCompanyContactByOrg: string;
  GetIndividualContactByOrg: string;
  GetParentIndividualContactByOrg: string;
  GetChildIndividualContactByFK_ParentContactId: string;
  SaveCRMContact: string;
  GetContactsForMetaDataList: string;
  constructor() {
    this.GetContactsForMetaDataList = "Contact/GetContactsForMetaDataList";
    this.GetCRMContact = "Contact/GetCRMContact";
    this.GetCRMContactByOrg = "Contact/GetCRMContactByOrg";
    this.GetCompanyContactByOrg = "Contact/GetCompanyContactByOrg";
    this.GetIndividualContactByOrg = "Contact/GetIndividualContactByOrg";
    this.GetParentIndividualContactByOrg =
      "Contact/GetParentIndividualContactByOrg";
    this.GetChildIndividualContactByFK_ParentContactId =
      "Contact/GetChildIndividualContactByFK_ParentContactId";
    this.SaveCRMContact = "Contact/SaveCRMContact";
  }
}
export class Contact_Accounts {
  accountId: number;
  fK_OrgCompanyId: number;
  fK_ContactId: number;
  userName: string;
  password: string;
  isVerified: boolean;
  verificationMethod: number;
  isActive: boolean;
  cREATED_BY: string;
  lAST_UPDATE_BY: string;
  Selected: boolean;

  constructor() {
    this.accountId = 0;
    this.fK_OrgCompanyId = 0;
    this.fK_ContactId = 0;
    this.userName = null;
    this.password = null;
    this.isVerified = false;
    this.verificationMethod = 0;
    this.isActive = false;
    this.cREATED_BY = null;
    this.lAST_UPDATE_BY = null;
    this.Selected = false;
  }
}
export class Contact_Types {
  fK_ContactId: number;
  fK_lkpContactTypeId: number;

  constructor() {
    this.fK_ContactId = 0;
    this.fK_lkpContactTypeId = 0;
  }
}
export class External_privilegeGroup {
  privilegeGroupId: number;
  groupTypeName: string;
  groupTypeName_LL: string;
  Selected: boolean;

  constructor() {
    this.privilegeGroupId = 0;
    this.groupTypeName = null;
    this.groupTypeName_LL = null;
    this.Selected = false;
  }
}
export class Contact_External_PrivilegeGroup {
  fK_ContactId: number;
  fK_PrivilegeGroupId: number;
  Selected: boolean;

  constructor() {
    this.fK_ContactId = 0;
    this.fK_PrivilegeGroupId = 0;
    this.Selected = false;
  }
}
export class AssetTypes {
  assetTypeId: number;
  fK_OrgCompanyId: number;
  typeName: string;
  typeName_LL: string;
  assetNoCaption: string;
  assetNoCaption_LL: string;
  hasIssueDate: boolean;
  hasExpiryDate: boolean;
  hasWorkflow: boolean;
  fK_WorkflowId: number;
  hasFormTemplate: boolean;
  fK_FormTemplateId: number;
  cREATED_BY: string;
  lAST_UPDATE_BY: string;
  Selected: boolean;

  constructor() {
    this.assetTypeId = 0;
    this.fK_OrgCompanyId = 0;
    this.typeName = null;
    this.typeName_LL = null;
    this.assetNoCaption = null;
    this.assetNoCaption_LL = null;
    this.hasIssueDate = false;
    this.hasExpiryDate = false;
    this.hasWorkflow = false;
    this.fK_WorkflowId = null;
    this.hasFormTemplate = false;
    this.fK_FormTemplateId = null;
    this.cREATED_BY = null;
    this.lAST_UPDATE_BY = null;
    this.Selected = false;
  }
}
export class ContactAssets {
  assetId: number;
  fK_AssetTypeId: number;
  fK_ContactId: number;
  assetName: string;
  assetName_LL: string;
  assetNo: string;
  issueDate: Date;
  expiryDate: Date;
  cREATED_BY: string;
  lAST_UPDATE_BY: string;
  Selected: boolean;

  constructor() {
    this.assetId = 0;
    this.fK_AssetTypeId = 0;
    this.fK_ContactId = 0;
    this.assetName = null;
    this.assetName_LL = null;
    this.assetNo = null;
    this.issueDate = null;
    this.expiryDate = null;
    this.cREATED_BY = null;
    this.lAST_UPDATE_BY = null;
    this.Selected = false;
  }
}
export class SubscriptionType {
  subscriptionTypeId: number;
  fK_OrgCompanyId: number;
  fK_ParentTypeId: number;
  typeCode: string;
  typeName: string;
  typeName_LL: string;
  description: string;
  description_LL: string;
  subscriptionNoFormula: string;
  hasExpiryDate: boolean;
  hasWorkflow: boolean;
  fK_WorkflowId: number;
  hasFormTemplate: boolean;
  fK_FormTemplateId: number;
  cardColor: string;
  cardIcon: string;
  defaultContactTypeId: number;
  defaultPrivilegeId: number;
  cREATED_BY: string;
  lAST_UPDATE_BY: string;
  Selected: boolean;
  iconFileBase64: string;

  constructor() {
    this.subscriptionTypeId = 0;
    this.fK_OrgCompanyId = 0;
    this.fK_ParentTypeId = null;
    this.typeCode = null;
    this.typeName = null;
    this.typeName_LL = null;
    this.description = null;
    this.description_LL = null;
    this.subscriptionNoFormula = null;
    this.hasExpiryDate = false;
    this.hasWorkflow = false;
    this.fK_WorkflowId = null;
    this.hasFormTemplate = false;
    this.fK_FormTemplateId = null;
    this.cardColor = null;
    this.cardIcon = null;
    this.defaultContactTypeId = null;
    this.defaultPrivilegeId = null;
    this.cREATED_BY = null;
    this.lAST_UPDATE_BY = null;
    this.Selected = false;
    this.iconFileBase64 = null;
  }
}
export class Subscription {
  subscriptionId: number;
  fK_SubscriptionTypeId: number;
  fK_CompanyId: number;
  fK_ContactId: number;
  fK_DurationId: number;
  subscriptionNo: string;
  issueDate: Date;
  isPermanent: boolean;
  expiryDate: Date;
  isPaid: boolean;
  isRenwal: boolean;
  prevSubscriptionId: number;
  fK_WorkflowStatus: number;
  isApproved: boolean;
  cREATED_BY: string;
  lAST_UPDATE_BY: string;
  Selected: boolean;
  subType: SubscriptionType[];
  subType_Duration: SubscriptionType_Duration[];
  sub_Feature: Subscription_Feature[];
  sub_Contacts: Subscription_SubContacts[];
  typeCode: string;
  typeName: string;
  typeName_LL: string;
  durationName: string;
  durationName_LL: string;

  constructor() {
    this.subscriptionId = 0;
    this.fK_SubscriptionTypeId = 0;
    this.fK_CompanyId = 0;
    this.fK_ContactId = 0;
    this.fK_DurationId = 0;
    this.subscriptionNo = null;
    this.issueDate = null;
    this.isPermanent = false;
    this.expiryDate = null;
    this.isPaid = false;
    this.isRenwal = false;
    this.prevSubscriptionId = null;
    this.fK_WorkflowStatus = null;
    this.isApproved = false;
    this.cREATED_BY = null;
    this.lAST_UPDATE_BY = null;
    this.Selected = false;
    this.subType = [];
    this.subType_Duration = [];
    this.sub_Feature = [];
    this.sub_Contacts = [];
    this.typeCode = null;
    this.typeName = null;
    this.typeName_LL = null;
    this.durationName = null;
    this.durationName_LL = null;
  }
}
export class Subscriptiontype_feature {

  featureId: number;
  fK_SubscriptionTypeId: number;
  fK_LKPFeatureId: number;
  isIncluded: boolean;
  isChargable: boolean;
  hasExpiryDate: boolean;
  duration: number;
  fK_LKPDurationType: number;
  subscriptionFees: number;
  fK_LKPCurrancy: number;
  discount: number;
  cREATED_BY: string;
  lAST_UPDATE_BY: string;
  Selected: boolean;
  featureName: string;
  featureName_LL: string;
  currencyCode: string;
  currencyName: string;
  currencyName_LL: string;

  constructor() {
    this.featureId = 0;
    this.fK_SubscriptionTypeId = 0;
    this.fK_LKPFeatureId = 0;
    this.isIncluded = false;
    this.isChargable = false;
    this.hasExpiryDate = false;
    this.duration = null;
    this.fK_LKPDurationType = null;
    this.subscriptionFees = null;
    this.fK_LKPCurrancy = null;
    this.discount = null;
    this.cREATED_BY = null;
    this.lAST_UPDATE_BY = null;
    this.Selected = false;
    this.featureName = null;
    this.featureName_LL = null;
    this.currencyCode = null;
    this.currencyName = null;
    this.currencyName_LL = null;
  }
}
export class Subscription_Feature {

  fK_SubscriptionId: number;
  fK_FeatureId: number;
  fK_ContactId: number;
  isIncluded: boolean;
  paidFees: number;
  issueDate: Date;
  expiryDate: Date;
  featureName: string;
  featureName_LL: string;

  constructor() {
    this.fK_SubscriptionId = 0;
    this.fK_FeatureId = 0;
    this.fK_ContactId = 0;
    this.isIncluded = false;
    this.paidFees = 0;
    this.issueDate = null;
    this.expiryDate = null;
    this.featureName = null;
    this.featureName_LL = null;
  }
}
export class SubscriptionType_Duration {

  durationId: number;
  fK_SubscriptionTypeId: number;
  durationName: string;
  durationName_LL: string;
  duration: number;
  fK_LKPDurationType: number;
  adultSubscriberNo: number;
  childSubscriberNo: number;
  subscriptionFees: number;
  additionalAdultFees: number;
  additionalChildFees: number;
  fK_LKPCurrancy: number;
  Selected: boolean;
  durationTypeCode: string;
  durationTypeName: string;
  durationTypeName_LL: string;
  currancyCode: string;
  currancyName: string;
  currancyName_LL: string;

  constructor() {
    this.durationId = 0;
    this.fK_SubscriptionTypeId = 0;
    this.durationName = null;
    this.durationName_LL = null;
    this.duration = null;
    this.fK_LKPDurationType = null;
    this.adultSubscriberNo = null;
    this.childSubscriberNo = null;
    this.subscriptionFees = null;
    this.additionalAdultFees = null;
    this.additionalChildFees = null;
    this.fK_LKPCurrancy = null;
    this.Selected = false;
    this.durationTypeCode = null;
    this.durationTypeName = null;
    this.durationTypeName_LL = null;
    this.currancyCode = null;
    this.currancyName = null;
    this.currancyName_LL = null;
  }
}
export class Subscription_SubContacts {
  fK_SubscriptionId: number;
  fK_ContactId: number;
  contactName: string;
  contactName_LL: string;

  constructor() {
    this.fK_SubscriptionId = 0;
    this.fK_ContactId = 0;
    this.contactName = null;
    this.contactName_LL = null;
  }
}
export class SubscriptionType_SubContact_Privileges {
  fK_SubscriptionTypeId: number;
  fK_PrivilegeGroupId: number;
  groupTypeName: string;
  groupTypeName_LL: string;
  fK_OrgCompanyId: number;
  
  constructor() {
    this.fK_SubscriptionTypeId = 0;
    this.fK_PrivilegeGroupId = 0;
    this.groupTypeName = null;
    this.groupTypeName_LL = null;
    this.fK_OrgCompanyId = 0;
  }
}