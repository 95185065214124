import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Login, UserInput, Sys_users } from '../Classes/login';
import { LookUpTableValues_languages, LookUpTable, LookUpTableValues, LookUpTableValuesCommon } from 'src/app/definitions/models/lookup';
import { OrgCompany, OrgLevel, OrgUnit, Org_WorkLocation, Org_logicalGroup, OrgCompany_Languages } from 'src/app/organization/models/org';
import { Employee_Status, Employee_Type, Employee } from 'src/app/organization/models/employee';
import { Sys_Groups } from 'src/app/Classes/groups';
import { GlobalVariablesService } from 'src/app/Shared/global-variables.service';
import { FormTemplate, FormTemplate_Panels, FormTemplate_Fields, FormTemplate_FieldListItems } from '../Forms/models/FormTemplateClass';
import { Contact_Accounts,Contact_Companies, Contact_Types,Contact_External_PrivilegeGroup, External_privilegeGroup,AssetTypes, SubscriptionType, ContactAssets, Subscription, Subscriptiontype_feature, SubscriptionType_Duration, Subscription_Feature, Subscription_SubContacts, SubscriptionType_SubContact_Privileges } from '../Classes/CRMClass';
import { LanguageTranslateService } from './language-translate.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
export class ParamValue { 
param : string;
value : any;
constructor(){
this.param = "";
this.value = '';
}
}



@Injectable({
  providedIn: 'root'
})
export class SharedService {

  token: string = "";
  headers: HttpHeaders;


// Online UAT server
// serviceIP_Security: string = 'https://security.uat.performasuite.com/api/';

// serviceIP_Core: string = 'https://core.uat.performasuite.com/api/';

// serviceIP_Org: string = 'https://org.uat.performasuite.com/api/';

  
 //Development on local Server
  serviceIP: string = 'https://localhost:44309/api/';
  
  serviceIP_TA: string = 'https://localhost:44346/api/';

//Ismail Change
  serviceIP_Security: string = 'https://localhost:44301/api/';

  serviceIP_Core: string = 'https://localhost:44302/api/';

  serviceIP_Org: string = 'https://localhost:44303/api/';

  serviceIP_Old: string = 'https://localhost:44309/api/';
//- end ismail change

  virtualpath : string = 'http://localhost/PerformaService/';
  virtualpath_serviceicons : string = 'http://localhost/PerformaService/ServiceIcons/';
  virtualpath_servicepics : string = 'http://localhost/PerformaService/ServicePics';
  virtualpath_attachment : string = 'http://localhost/PerformaService/Attachment';
url : string = 'http://localhost:4200/';
PdfUrl : string = 'https://localhost:44347/pdfviewer';
excelOpen: string = 'https://localhost:44381/api/SpreadSheet/open';
excelSave: string = 'https://localhost:44381/api/SpreadSheet/Save';
    //UAT -- For Deployment on UAT Server

//      serviceIP: string = 'http://uatapi.performasuite.com/api/';
//     serviceIP_TA: string = 'http://uatapi.performasuite.com/api/';
// virtualpath : string = 'http://uatapi.performasuite.com/';
// virtualpath_serviceicons : string = 'http://uatapi.performasuite.com/ServiceIcons/';
// virtualpath_servicepics : string = 'http://uatapi.performasuite.com/ServicePics';
// virtualpath_attachment : string = 'http://uatapi.performasuite.com/Attachment';

  constructor(private httpclient: HttpClient, private router: Router,public global: GlobalVariablesService,public languageTranslateService: LanguageTranslateService) { }
  /////////////// Ammar Changes//////


  PostObject(Object: any, APIName: string = ''): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = this.serviceIP_Security + APIName;
    return this.httpclient.post(url, Object, { headers }).pipe(
     
      catchError((err) => {
        if(err.status === 401){
          console.log('401 error caught in service');
          this.SignOut();
        }else{
          console.log('error caught in service')
        }

        return throwError(err);
      }));
  }

  GetObject(paramName: string, Object: any, UrlName: string): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = this.serviceIP + UrlName;
    const param1 = new HttpParams().set(paramName, Object);
    return this.httpclient.get(url, { params: param1, headers }).pipe(
     
      catchError((err) => {
        
        if(err.status === 401){
          console.log('401 error caught in service');
          this.SignOut();
        }else{
          console.log('error caught in service')
        }

        //Handle the error here

        return throwError(err);
      }));
  }

  GetObject_MultipleValues(lstparam : ParamValue[], UrlName: string): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = this.serviceIP + UrlName;
    let param1 = new HttpParams();
  //  let para = '1=1';
    lstparam.forEach(element => {
     // param1 = new HttpParams().set();
     param1 =  param1.append(element.param, element.value.toString());
     // para = para +'&'+element.param + '=' + element.value.toString();
     // params.set('para2', "two");
    });    

    return this.httpclient.get(url , { params: param1, headers }).pipe(
     
      catchError((err) => {
        if(err.status === 401){
          console.log('401 error caught in service');
          this.SignOut();
        }else{
          console.log('error caught in service')
        }

        //Handle the error here

        return throwError(err);
      }));
  }
  // async GetDocumentBase64(Object: any, APIName: string = ''): Promise<string> {
  //   try {
  //     const headers = this.CreateHeaderWithLangAndCompany();
  //     const url = APIName;
  //     let response = await  this.httpclient.post(url, Object, { headers }).toPromise(); 
  //     return response as string;
  //   } catch (error) {
  //   return "";
  //   }
  // }

  EncryptUrl(Id : string): string
  {
    let CryptoJS = require("crypto-js");
    let encriptionData =  CryptoJS.AES.encrypt(Id, 'HSM@2020').toString();
  return encodeURIComponent(encriptionData);
  }
  DecryptUrl(url : string): string
  {
    let CryptoJS = require("crypto-js");
     let enc_url = decodeURIComponent(url);
    let bytes   =  CryptoJS.AES.decrypt(enc_url, 'HSM@2020');
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
  }
    GetDocumentBase64(Object: any, APIName: string = ''): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = APIName;
    return this.httpclient.post(url, Object, { headers });
  }
  CreateHeaderWithLangAndCompany() {
    this.token = sessionStorage.getItem('token');
    const companyId = this.global.FK_CompanyId();
    const contactid = this.global.GetContactId();
    const langid = this.global.GetLanguage();
    let headers = new HttpHeaders({
      OrgCompanyId: companyId.toString(),
      ContactId: contactid.toString(),
    Lang: langid,//this.languageTranslateService.currentlang === 'en'? '2' : '1',
      UserId: this.global.getUserID(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    console.log('====Header====');
    console.log("companyid="+ companyId.toString() + " contactid=" + contactid.toString()+ " userid=" +this.global.getUserID());
    console.log('====Header====');
    return headers;
  }
  /////// Ammar Chnages End ////////
  CreateHeader() {
    let headers = this.CreateHeaderWithLangAndCompany();

    return headers;
  
  }

SignOut(){
  sessionStorage.removeItem('objSys_users');
  sessionStorage.removeItem('lang');
  sessionStorage.removeItem('languageObj');
  sessionStorage.removeItem('isRTL');
  sessionStorage.removeItem('menu');
  sessionStorage.removeItem('languagelist');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('menuMode');
  this.router.navigateByUrl('/login');
}

serviceUrl(ServiceName, actionName)
{
  let actionUrl = "";
    switch ( ServiceName) {
    case "Security":  
    actionUrl = this.serviceIP_Security + actionName;
      break;
    case "Core":  
    actionUrl = this.serviceIP_Core + actionName;
      break;
    case "Org":  
    actionUrl = this.serviceIP_Org + actionName;
      break;
      case "Old":  
      actionUrl = this.serviceIP_Core + actionName;
        break;
    default:
      actionUrl = this.serviceIP + actionName;
      break;
    }
    return actionUrl;
  }


  getGenericParmas(value: any, paramName: string, UrlName: string, APIService: string = ""): Observable<any> {

    let headers = this.CreateHeader();
    let url = this.serviceUrl(APIService, UrlName);   

    let param1 = new HttpParams().set(paramName, value)
    return this.httpclient.get(url, { params: param1, headers: headers }).pipe(
     
      catchError((err) => {
        
        if(err.status === 401){
          console.log('401 error caught in service');
          this.SignOut();
        }else{
          console.log('error caught in service')
        }
       
     

        return throwError(err);
      }));
  }



  getWithoutParmas(UrlName: string, APIService: string = ""): Observable<any> {

    let headers = this.CreateHeader();
    let url = this.serviceUrl(APIService, UrlName);
  
    return this.httpclient.get(url, {headers: headers }).pipe(
     
      catchError((err) => {
        
        if(err.status === 401){
          console.log('401 error caught in service');
          this.SignOut();
        }else{
          console.log('error caught in service')
        }
       
     

        return throwError(err);
      }));
  }

  postGenericParmas(Object: any, UrlName: string, APIService: string = ""): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    let url = this.serviceUrl(APIService, UrlName);
 
    return this.httpclient.post(url, Object, { headers }).pipe(
     
      catchError((err) => {
        if(err.status === 401){
          console.log('401 error caught in service');
          this.SignOut();
        }else{
          console.log('error caught in service')
        }

        return throwError(err);
      }));
  }

  DeleteGenericParmas(value: any, paramName: string, UrlName: string, APIService: string = ""): Observable<any> {
    let headers = this.CreateHeader();
     let url = this.serviceUrl(APIService, UrlName);
    console.log(url);
    let param1 = new HttpParams().set(paramName, value)
    return this.httpclient.delete(url, { params: param1, headers: headers })
  }


  getGeneric_MultipleValues(lstparam : ParamValue[], UrlName: string, APIService: string = ""): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    let url = this.serviceUrl(APIService, UrlName);
    let param1 = new HttpParams();
  //  let para = '1=1';
    lstparam.forEach(element => {
     // param1 = new HttpParams().set();
     param1 =  param1.append(element.param, element.value.toString());
     // para = para +'&'+element.param + '=' + element.value.toString();
     // params.set('para2', "two");
    });    
    return this.httpclient.get(url , { params: param1, headers }).pipe(
     
      catchError((err) => {
        if(err.status === 401){
          console.log('401 error caught in service');
          this.SignOut();
        }else{
          console.log('error caught in service')
        }

        //Handle the error here

        return throwError(err);
      }));
  }

  DeleteGenericParmas_MultipleValues(lstparam : ParamValue[], UrlName: string): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = this.serviceIP + UrlName;
    let param1 = new HttpParams();
    lstparam.forEach(element => {
     param1 =  param1.append(element.param, element.value.toString());
    });  
    return this.httpclient.delete(url, { params: param1, headers: headers })
  }



  LoginPortal(Object: Login, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();

    let url = this.serviceIP_Security + APIName;
    console.log(Object);
    // return this.httpclient.post(url, Object , {headers: headers})
    return this.httpclient.post(url, Object)
  }
  GetModulesAndForms(Object: UserInput, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

 
  SaveLKPValues(Object: LookUpTableValues, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveLookUpTableValuesCommon(Object: LookUpTableValuesCommon, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveLKPTranslations(Object: LookUpTableValues_languages, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveOrgCompany(Object: OrgCompany, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  GetOrgLevel(Object: OrgLevel, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  GetLookUpValuesByCompany(Object: LookUpTable, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.put(url, Object, { headers: headers })
  }
  SaveOrgUnit(Object: OrgUnit, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveWorkLocation(Object: Org_WorkLocation, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveLogicalGroup(Object: Org_logicalGroup, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveEmployeeStatus(Object: Employee_Status, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveEmployeeType(Object: Employee_Type, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SearchEmployeeByOrgUnit_Group_Location(Object: Employee, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  CreateApplicationUser(Object: Sys_users, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }


  SaveGroup(Object: Sys_Groups, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveOrgLanguages(Object: OrgCompany_Languages, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  ///////////TAAPI
  TA_getGenericParmas(value: any, paramName: string, UrlName: string): Observable<any> {

    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + UrlName;
    let param1 = new HttpParams().set(paramName, value)
    return this.httpclient.get(url, { params: param1, headers: headers })
  }

  SaveExternal_privilegeGroup(Object: External_privilegeGroup, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  SaveSubscriptionType(Object: SubscriptionType, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  SaveSubscriptionType_SubContact_Privileges(Object: SubscriptionType_SubContact_Privileges, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  
  SaveAssetTypes(Object: AssetTypes, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  
  SaveContact_Accounts(Object: Contact_Accounts, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  UpdateContact_AccountPassword(Object: Contact_Accounts, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveContact_Companies(Object: Contact_Companies, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveContact_Types(Object: Contact_Types, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveContact_External_PrivilegeGroup(Object: Contact_External_PrivilegeGroup, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveContactAssets(Object: ContactAssets, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveSubscription(Object: Subscription, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveSubscriptionType_Feature(Object: Subscriptiontype_feature, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveSubscriptionType_Duration(Object: SubscriptionType_Duration, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveSubscription_Feature(Object: Subscription_Feature, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
  SaveSubscription_SubContacts(Object: Subscription_SubContacts, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }
 
  SaveObject(Object: any, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  SaveFormTemplate(Object: FormTemplate, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  SaveFormTemplate_Panels(Object: FormTemplate_Panels, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  SaveFormTemplate_Fields(Object: FormTemplate_Fields, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  SaveFormTemplate_FieldListItems(Object: FormTemplate_FieldListItems, APIName: string = ''): Observable<any> {
    let headers = this.CreateHeader();
    let url = this.serviceIP_TA + APIName;
    return this.httpclient.post(url, Object, { headers: headers })
  }

  detelewithTwo(UrlName: string, Region: string = ""): Observable<any> {
    let headers = this.CreateHeader();
    let url = "";
    if (Region == "") {
      url = this.serviceIP + UrlName;
    }
    else if (Region == "TA") {
      url = this.serviceIP_TA + UrlName;
    }
    return this.httpclient.delete(url)
  }

}
