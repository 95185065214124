import { EventEmitter } from '@angular/core';
import * as EnglishStrings from 'src/app/resources/english';
import * as ArabicStrings from 'src/app/resources/arabic';
import * as GermanStrings from 'src/app/resources/german';
import * as SpanishStrings from 'src/app/resources/spanish';
import * as ItalianStrings from 'src/app/resources/italian';
import * as FrenchStrings from 'src/app/resources/french';

export class LanguageTranslateService {
  public currentLang: EventEmitter<string> = new EventEmitter();
  currentlang = 'en';
  setLang(val) {
    this.currentLang.emit(val);
  }
  lang = 'en';
  
  getLangObject(lang) {
    this.currentlang = lang;
    switch(lang){
      case 'ar':
        return ArabicStrings;
      case 'it':
        return ItalianStrings;
      case 'fr':
        return FrenchStrings;
      case 'es':
        return SpanishStrings;
        case 'de':
          return GermanStrings;
      case 'en':
      default:
        return EnglishStrings;
    }
  }
}