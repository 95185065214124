import { Injectable } from '@angular/core';
import { Languages, Sys_users, Menu } from 'src/app/Classes/login';
import { Company, Contact, Contact_Companies } from '../Classes/CRMClass';
//import { CommonDialogModel, PopupModalComponent }from 'src/app/popup-modal/popup-modal.component';
//import { MatDialog, MatTableDataSource } from '@angular/material';
//import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {
  FormName: string = "";
  Languages: Languages[];
  objSys_users: Sys_users;
  objcontact: Contact;
  objcompanylist: Company[];
  objcompany: Company;
  glbMenu: Menu[];
  objContact_Companieslist: Contact_Companies[];
  objContact_Companies: Contact_Companies;
  //constructor(public dialog: MatDialog) {
  constructor() {
    this.Languages = [];
    this.glbMenu = [];
    this.objcompanylist = [];
    this.objSys_users = new Sys_users();
    this.objcontact = new Contact();
    this.objcompany = new Company();
    this.objContact_Companies = new Contact_Companies();
  }

  isStringNullOrEmplty(val: string) {
    if (val == null || val == "" || val == undefined)
      return true;
    else
      return false;
  }
  isNumberNullOrEmplty(val: number) {
    if (val == null || val == undefined)
      return true;
    else
      return false;
  }
  getList(val: any) {
    debugger
    return JSON.parse(sessionStorage.getItem(val));

  }
  hasZeroLength(list: any) {
    if (list == null) {
      return true;
    }
    else if (list.length == 0) {
      return true;
    }
    else {
      return false;
    }
  }
  // getUserID(){
  //   this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
  //   return this.objSys_users.user_FullName;
  // }

GetLanguageList(){
 return sessionStorage.getItem('languagelist')
}
GetLanguage(){
  try {
    let id = sessionStorage.getItem('lang');
    return id === null? '2': id;
  } catch (ex) {
    return '2';
  }

 }
GetMenu(){
  return sessionStorage.getItem('menu')
 }
 getAppUserId() {
  try {
    this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
    return this.objSys_users.userId.toString();
  } catch (ex) {
    return '0';
  }


}

  GetOrgCompanyLogo() {
    try {
      this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      return this.objSys_users.logo.toString();
    } catch (ex) {
      return '';
    }


  }
  getUserID() {
    try {
      this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      return this.objSys_users.userId.toString();
    } catch (ex) {
      return '0';
    }


  }
  GetContactId() {
    try {
      this.objcontact = JSON.parse(sessionStorage.getItem('objcontact'));
      return this.objcontact.contactId.toString();
    } catch (ex) {
      return "0";
    }
  }

  GetIsIndividualContact() {
    try {
      this.objcontact = JSON.parse(sessionStorage.getItem('objcontact'));
      return this.objcontact.isIndividual;
    } catch (ex) {
      return false;
    }
  }

  GetIsMainContact() {

    try {
      this.objContact_Companieslist = JSON.parse(sessionStorage.getItem('objcontact_companies'));
      if (this.objContact_Companieslist != null) {
        for (let i = 0; i < this.objContact_Companieslist.length; i++) {
          if (this.objContact_Companieslist[i].isMainContact == true) {
            return true;
          }
          else {
            return false;
          }
        }
      }

    } catch (ex) {
      return false;
    }
  }

  GetIsParentContact() {
    try {
      this.objcontact = JSON.parse(sessionStorage.getItem('objcontact'));
      if (this.objcontact.fK_ParentContactId != null) {
        return true
      } else {
        return false
      }

    } catch (ex) {
      return "0";
    }
  }

  GetCompanylist() {
    try {
      this.objcompanylist = JSON.parse(sessionStorage.getItem('objcompanylist'));
      return this.objcompanylist;
    } catch (ex) {
      this.objcompanylist = [];
      return this.objcompanylist;
    }


  }
  GetCompanyId() {
    try {
      this.objcompany.companyId = JSON.parse(sessionStorage.getItem('objcompany'));
      return this.objcompany.companyId;
    } catch (ex) {
      return 0;
    }


  }
  getUserFullName() {
    try {
      this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      return this.objSys_users.user_FullName.toString();
    } catch (ex) {
      this.objcontact = JSON.parse(sessionStorage.getItem('objcontact'));
      return this.objcontact.contactName.toString();
    }


  }
  GetEmployeeId() {
    try {
      this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      return this.objSys_users.fK_EmployeeId.toString();
    } catch (ex) {
      return '0';
    }

  }
  GetExternalUserFullName() {
    try {
      this.objcontact = JSON.parse(sessionStorage.getItem('objcontact'));
      return this.objcontact.contactName.toString();
    } catch (ex) {
      return '';
    }


  }
  FK_CompanyId() {
    try {
      this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      return this.objSys_users.companyId;
    } catch (ex) {
      try {
       this.objcontact = JSON.parse(sessionStorage.getItem('objcontact'));
      return this.objcontact.fK_OrgCompanyId;
    } catch (ex) {
      return 0;
    }
  }

  }

  FK_EmployeeId() {
    debugger;
    try {
      this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
      return this.objSys_users.fK_EmployeeId;
    } catch (ex) {
      return '0';
    }

  }

  companyName() {
    this.objSys_users = JSON.parse(sessionStorage.getItem('objSys_users'));
    return this.objSys_users.companyName;
  }
  convertNumbre(num: any) {
    if (num == undefined) {
      return 0;
    }
    else {
      try {
        let x = Number(num);
        return x;
      }
      catch {
        return 0;
      }
    }
  }

  openDialog(title: string, Msg: string): void {

    //this.service.add({ key: 'tst', severity: 'info', summary: title, detail: 'PrimeNG rocks' });
    // const message = Msg;
    // const dialogData = new CommonDialogModel(title, message);

    // const dialogRef = this.dialog.open(PopupModalComponent, {
    //   width: '640px', disableClose: true,
    //   data: dialogData
    // });

    // dialogRef.afterClosed().subscribe(dialogResult => {


    // });

  }

  ConvertTimetoNumber(timeValues: Date) {
    let Numtime: number;
    Numtime = (timeValues.getHours() * 60) + timeValues.getMinutes();
    return Numtime;
  }

  ConvertNumbertoTime(numValues: number) {
    let min = numValues % 60;
    let Hour = (numValues - min) / 60;
    let formatedDate: Date;
    formatedDate = new Date("13:00");
    if (Hour < 10 && min < 10) {
      return new Date("1900/01/01 " + "0" + Hour.toFixed(0) + ":" + "0" + min.toFixed(0));
    }
    else if (Hour < 10 && min > 9) {
      return new Date("1900/01/01 " + "0" + Hour + ":" + min);
    }
    else if (Hour > 9 && min < 10) {
      return new Date("1900/01/01 " + "0" + Hour + ":" + "0" + min);
    }
    else if (Hour > 9 && min > 9) {
      return new Date("1900/01/01 " + "0" + Hour + ":" + min);
    }
  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  public base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}
  convertIntoTime(intTime: number) {

    let strTime
    if (intTime != 0) {
      let strHour;
      let strMin;
      strHour = intTime / 60;
      strHour = parseInt(strHour);
      strMin = (intTime % 60).toString();
      if (strHour.toString().length == 1) {
        strHour = "0" + strHour;
      }
      if (strMin.toString().length == 1) {
        strMin = "0" + strMin;
      }
      strTime = (strHour + ":" + strMin).toString()
    }
    else {
      strTime = "00:00"
    }
    return strTime
  }

  convertTimetoInt(strTime: string) {

    let intHour;
    let intMin
    let strTimeVal;
    intHour = parseInt(strTime.substr(0, 2)) * 60;
    intMin = parseInt(strTime.substr(3, 2));
    strTimeVal = parseInt(intHour + intMin);
    return parseInt(strTimeVal)
  }

  convertIntoDateTime(intTime: number, Ddate:Date) {

    let strTime
    if (intTime != 0) {
      let strHour;
      let strMin;
      strHour = intTime / 60;
      strHour = parseInt(strHour);
      strMin = (intTime % 60).toString();
      if (strHour.toString().length == 1) {
        strHour = "0" + strHour;
      }
      if (strMin.toString().length == 1) {
        strMin = "0" + strMin;
      }

      strTime = new Date(Ddate.getFullYear(), Ddate.getMonth(), Ddate.getDate(), strHour, strMin);
    }
    else {
      strTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0);
    }
    return strTime
  }

  RemarksValueChange(value, MaxValue) {
    debugger;
    let remainingRemarksText;
    if(value != null){
      remainingRemarksText = MaxValue - value.toString().length;
    }
    else{
      remainingRemarksText = MaxValue - 0;
    }
    return parseInt(remainingRemarksText);
  }

  convertDateTimetoInt(dateTimeVal: Date) {

    let intHour;
    let intMin
    let strTimeVal;
    intHour = dateTimeVal.getHours() * 60;
    intMin = dateTimeVal.getMinutes();
    strTimeVal = parseInt(intHour + intMin);
    return parseInt(strTimeVal)
  }

  convertDatetoNum(dateTimeVal: Date) {

    let strdateVal: string;
    let strMonth: string;
    let strDay: string;
    let datetoconvert: Date = null;

    //datetoconvert = new Date(dateTimeVal.getDate(), dateTimeVal.getMonth(), dateTimeVal.getFullYear())

    if (dateTimeVal.getMonth().toString().length == 1) {
      strMonth = "0" + (dateTimeVal.getMonth() + 1).toString();
    }
    else {
      strMonth = (dateTimeVal.getMonth() + 1).toString();
    }
    if (dateTimeVal.getDate().toString().length == 1) {
      strDay = "0" + (dateTimeVal.getDate()).toString();
    }
    else {
      strDay = (dateTimeVal.getDate()).toString();
    }
    strdateVal = dateTimeVal.getFullYear().toString() + strMonth + strDay;
    return parseInt(strdateVal)
  }

  calculateScheduleDuration(fromtime: number, totime: number) {
    let intDuration = totime - fromtime
    if (intDuration < 1) {
      intDuration = intDuration * -1
    }
    return intDuration;
  }

  FillEmployeeAvatar(EmployeeName: string) {
    debugger;
    let EmployeeAvatar: string;
    EmployeeAvatar = EmployeeName.substring(0, 2).toUpperCase();
    return EmployeeAvatar;
  }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  FileIcons(filename :string,dir :boolean ){
    let icon = '';
  if(dir){
   icon = 'assets/filetypes/24/folder.png';
   return icon;
  }
  
  if(filename.toLowerCase().includes('.png')){
    icon = 'assets/filetypes/24/png.png';
   } else if(filename.toLowerCase().includes('.jpg')){
    icon = 'assets/filetypes/24/jpg.png';
   } else if(filename.toLowerCase().includes('.jpeg')){
    icon = 'assets/filetypes/24/jpg.png';
   }else if(filename.toLowerCase().includes('.doc')){
    icon = 'assets/filetypes/24/doc.png';
   }else if(filename.toLowerCase().includes('.docx')){
    icon = 'assets/filetypes/24/doc.png';
   }else if(filename.toLowerCase().includes('.dll')){
    icon = 'assets/filetypes/24/dll.png';
   }else if(filename.toLowerCase().includes('.pdf')){
    icon = 'assets/filetypes/24/pdf.png';
   }else if(filename.toLowerCase().includes('.txt')){
    icon = 'assets/filetypes/24/txt.png';
   } else if(filename.toLowerCase().includes('.html')){
    icon = 'assets/filetypes/24/html.png';
   }else if(filename.toLowerCase().includes('.gif')){
    icon = 'assets/filetypes/24/gif.png';
   }else if(filename.toLowerCase().includes('.xml')){
    icon = 'assets/filetypes/24/xml.png';
   }else if(filename.toLowerCase().includes('.rtf')){
    icon = 'assets/filetypes/24/rtf.png';
   }else if(filename.toLowerCase().includes('.xlsx')){
    icon = 'assets/filetypes/24/xls.png';
   }else if(filename.toLowerCase().includes('.xls')){
    icon = 'assets/filetypes/24/xls.png';
   }else if(filename.toLowerCase().includes('.zip')){
    icon = 'assets/filetypes/24/zip.png';
   }else if(filename.toLowerCase().includes('.tif')){
    icon = 'assets/filetypes/24/tif.png';
   }else if(filename.toLowerCase().includes('.svg')){
    icon = 'assets/filetypes/24/svg.png';
   }else if(filename.toLowerCase().includes('.wmv')){
    icon = 'assets/filetypes/24/wmv.png';
   }else if(filename.toLowerCase().includes('.mpg')){
    icon = 'assets/filetypes/24/mpg.png';
   }else if(filename.toLowerCase().includes('.mp3')){
    icon = 'assets/filetypes/24/mp3.png';
   }else if(filename.toLowerCase().includes('.sql')){
    icon = 'assets/filetypes/24/sql.png';
   }else if(filename.toLowerCase().includes('.js')){
    icon = 'assets/filetypes/24/js.png';
   }else if(filename.toLowerCase().includes('.ai')){
    icon = 'assets/filetypes/24/ai.png';
   }else if(filename.toLowerCase().includes('.cdr')){
    icon = 'assets/filetypes/24/cdr.png';
   }else if(filename.toLowerCase().includes('.css')){
    icon = 'assets/filetypes/24/css.png';
   }else if(filename.toLowerCase().includes('.dmg')){
    icon = 'assets/filetypes/24/dmg.png';
   }else if(filename.toLowerCase().includes('.bmp')){
    icon = 'assets/filetypes/24/bmp.png';
   }else if(filename.toLowerCase().includes('.cad')){
    icon = 'assets/filetypes/24/cad.png';
   }else if(filename.toLowerCase().includes('.avi')){
    icon = 'assets/filetypes/24/avi.png';
   }else if(filename.toLowerCase().includes('.dat')){
    icon = 'assets/filetypes/24/dat.png';
   }else if(filename.toLowerCase().includes('.flv')){
    icon = 'assets/filetypes/24/flv.png';
   }else if(filename.toLowerCase().includes('.eps')){
    icon = 'assets/filetypes/24/eps.png';
   } else if(filename.toLowerCase().includes('.mp4')){
    icon = 'assets/filetypes/24/mp4.png';
   }else if(filename.toLowerCase().includes('.ppt')){
    icon = 'assets/filetypes/24/ppt.png';
   }else if(filename.toLowerCase().includes('.pptx')){
    icon = 'assets/filetypes/24/ppt.png';
   }else {
    icon = 'assets/filetypes/24/raw.png';
   }
  
   return icon;
  }
  
  EncryptUrl(Id : string): string
  {
    let CryptoJS = require("crypto-js");
    let encriptionData =  CryptoJS.AES.encrypt(Id, 'HSM@2020').toString();
  return encodeURIComponent(encriptionData);
  }
  DecryptUrl(url : string): string
  {
    let CryptoJS = require("crypto-js");
     let enc_url = decodeURIComponent(url);
    let bytes   =  CryptoJS.AES.decrypt(enc_url, 'HSM@2020');
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
  }

}
// export enum enumMsg{
//   Success  =  "Record has been saved.",
//   NameExists  =  "Record has been saved.",
//   Name_LLExists = "",
//   msgid =""
// }

