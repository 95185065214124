import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { TabViewModule } from 'primeng/tabview';
// import { TagModule } from "primeng/tag";
// import { BadgeModule } from "primeng/badge";


import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCodeModule } from './app.code.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuitemComponent } from './layout/app.menuitem.component';
import { AppRightMenuComponent } from './layout/app.right-menu.component';
import { AppTopBarComponent } from './layout/app.topbar.component';
import { AppFooterComponent } from './layout/app.footer.component';

import { ConfirmationService } from 'primeng//api';

import { MenuService } from './layout/app.menu.service';


//Performa Suite 
import { LanguageTranslateService } from './Shared/language-translate.service';
import { SharedService } from 'src/app/Shared/shared.service';
import { GlobalVariablesService } from 'src/app/Shared/global-variables.service';

import { AppMenuComponent } from './layout/app.menu.component';


import { TooltipModule } from 'primeng/tooltip';
import { ActivateaccountComponent } from './Plan/components/activateaccount/activateaccount.component';

import { DatePipe } from '@angular/common';



@NgModule({
    imports: [

        AppCodeModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CheckboxModule,
        CodeHighlighterModule,
        ColorPickerModule,
        ContextMenuModule,
        InplaceModule,
        InputMaskModule,
        InputNumberModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        MessageModule,
        MessagesModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        ProgressBarModule,
        RadioButtonModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        ToastModule,
        ToggleButtonModule,
        VirtualScrollerModule,
        SidebarModule,
        TooltipModule,
        TabViewModule,

        // DocumentEditorContainerModule,
        // DocumentEditorModule ,
        BrowserModule,
        // NgxQueryBuilderModule,
        // DynamicFormsPrimeNGUIModule,
        //  FilePickerModule,
        AppRoutingModule,
        AppCodeModule,
        HttpClientModule,
        BrowserAnimationsModule,
        // RadioButtonModule,
        // TagModule,
        // BadgeModule, 

        // LoginModule,
        // HomeModule,
        // SecurityModule,
        // DPModule,
        // TAModule,
        // LookUpModule,
        // DocModule,
        // ArchiveModule,
        // PortalloginModule,
        // PortalhomeModule,
        // PortalappsModule,
        // TemplateModule,
        // CrmModule,
        // OrgModule,
        // ReportModule
    ],
    declarations: [
        AppComponent,

        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,

        AppRightMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        ActivateaccountComponent,

    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        MenuService, LanguageTranslateService,
        SharedService, GlobalVariablesService, ConfirmationService, DatePipe
    ],
    exports: [
        HttpClientModule,
        //  DocumentEditorContainerModule,
        // DocumentEditorModule

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
