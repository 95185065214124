// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,



  //  serviceIP_Security: 'https://localhost:44301/api/',
  //  serviceIP_Core: 'https://localhost:44302/api/',
  //  serviceIP_Org: 'https://localhost:44303/api/',
  //  serviceIP_TABase: 'https://localhost:44304/api/',
  //  serviceIP_TAAssignment: 'https://localhost:44305/api/',
  //  serviceIP_Transactions: 'https://localhost:44306/api/',
  //  serviceIP_Workflow: 'https://localhost:44337/api/',
  //  serviceIP_Forms: 'https://localhost:44308/api/',
  //  serviceIP_Services: 'https://localhost:44309/api/',
  //  serviceIP_AppsApi: 'https://localhost:44310/api/',
  //  serviceIP_Reports: 'https://localhost:44313/api/', 
  //  serviceIP_DynamicApi: 'https://localhost:44310/api/',  
   


   serviceIP_Security: 'https://security.uat.performasuite.com/api/',
   serviceIP_Core: 'https://core.uat.performasuite.com/api/',
   serviceIP_Org: 'https://org.uat.performasuite.com/api/',
   serviceIP_TABase: 'https://tabase.uat.performasuite.com/api/',
   serviceIP_TAAssignment: 'https://taassignment.uat.performasuite.com/api/', 
   serviceIP_Transactions: 'https://transactions.uat.performasuite.com/api/',
   serviceIP_Workflow: 'https://workflow.uat.performasuite.com/api/',
   serviceIP_Forms: 'https://forms.uat.performasuite.com/api/',
   serviceIP_Services: 'https://service.uat.performasuite.com/api/',
   serviceIP_AppsApi: 'https://apps.uat.performasuite.com/api/',
   serviceIP_DynamicApi: 'https://dynamicapi.uat.performasuite.com/api/',
   serviceIP_Reports: 'https://reports.uat.performasuite.com/api/',
   
   serviceIP_Old: 'https://localhost:44309/api/',
   PdfUrl: 'https://core.uat.performasuite.com/api/',





};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
