import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent }from 'src/app/Shared/base-component';
import { LanguageTranslateService }from 'src/app/Shared/language-translate.service';
import { PrimeNGConfig } from 'primeng/api';
import { MenuService } from './layout/app.menu.service';
import { AppMenuComponent } from './layout/app.menu.component'
import { Router } from "@angular/router";
@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
})

export class AppMainComponent extends BaseComponent implements OnInit {
    @ViewChild('menu', {static: false}) menu: AppMenuComponent;

    layoutMode = 'slim';

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    layoutMenuScroller: HTMLDivElement;

    lightMenu = true;

    topbarColor = 'layout-topbar-light';
    themeColor = 'blue';
    menuClick: boolean;

    userMenuClick: boolean;

    notificationMenuClick: boolean;

    rightMenuClick: boolean;

    resetMenu: boolean;

    menuHoverActive: boolean;

    topbarUserMenuActive: boolean;

    topbarNotificationMenuActive: boolean;

    rightPanelMenuActive: boolean;

    inlineUser: boolean = false;

    isRTL: boolean = false;

    configActive: boolean;

    configClick: boolean;

    profileClick: boolean;

    inlineUserMenuActive = false;

    inputStyle: string = "outlined";

    ripple: boolean = true;

    //constructor(private menuService: MenuService, private primengConfig: PrimeNGConfig) { }
    constructor(private router :Router ,public languageTranslateService: LanguageTranslateService,private menuService: MenuService, private primengConfig: PrimeNGConfig) {
        super(languageTranslateService);
        this.setMenuFromSession();
        
       
     }
   loadMenu()
    {
    try
    {
         this.menu.GetMenu();
    }
    catch{}
    }
     setMenuFromSession()
     {
if(sessionStorage.getItem('objSys_users') == null){
    this.router.navigateByUrl('/login');
}

        if (sessionStorage.getItem('menuMode') == null)
        {
           
            sessionStorage.setItem('menuMode',this.layoutMode);
        }
        else{
           this.layoutMode= sessionStorage.getItem('menuMode').toString();
        }
        if (sessionStorage.getItem('lightMenu') == null)
        {
           
            sessionStorage.setItem('lightMenu',String(this.lightMenu));
        }
        else{
           this.lightMenu= sessionStorage.getItem('lightMenu').toString()=='true';
        }
        if (sessionStorage.getItem('isRTL') == null)
        {
           
            sessionStorage.setItem('isRTL',String(this.isRTL));
        }
        else{
           this.isRTL= sessionStorage.getItem('isRTL').toString()=='true';
        }
      
        if (sessionStorage.getItem('topbarColor') == null)
        {
           
            sessionStorage.setItem('topbarColor',this.topbarColor);
        }
        else{
           this.topbarColor= sessionStorage.getItem('topbarColor').toString();
          
        }

        if (sessionStorage.getItem('themeColor') == null)
        {
           
            sessionStorage.setItem('themeColor','blue');
        }
        else{
           this.themeColor= sessionStorage.getItem('themeColor').toString();
           this.changeStyleSheetsColor('layout-css', 'layout-' + this.themeColor + '.css');
           this.changeStyleSheetsColor('theme-css', 'theme-' + this.themeColor + '.css');
             
        }

     
        
     }
     changeStyleSheetsColor(id, value) {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = value;

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }
    replaceLink(linkElement, href) {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }
    ngOnInit() {
        this.primengConfig.ripple = true;
    }

    onLayoutClick() {
        if (!this.userMenuClick) {
            this.topbarUserMenuActive = false;
        }

        if (!this.notificationMenuClick) {
            this.topbarNotificationMenuActive = false;
        }

        if (!this.rightMenuClick) {
            this.rightPanelMenuActive = false;
        }

        if (!this.profileClick && this.isSlim()) {
            this.inlineUserMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.menuService.reset();
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
            this.unblockBodyScroll();
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.configClick = false;
        this.userMenuClick = false;
        this.rightMenuClick = false;
        this.notificationMenuClick = false;
        this.menuClick = false;
        this.profileClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarUserMenuActive = false;
        this.topbarNotificationMenuActive = false;
        this.rightPanelMenuActive = false;

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
            if (this.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onMenuClick(event) {
        this.menuClick = true;
        this.resetMenu = false;
    }

    onTopbarUserMenuButtonClick(event) {
        this.userMenuClick = true;
        this.topbarUserMenuActive = !this.topbarUserMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarNotificationMenuButtonClick(event) {
        this.notificationMenuClick = true;
        this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onRightMenuClick(event) {
        this.rightMenuClick = true;
        this.rightPanelMenuActive = !this.rightPanelMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onProfileClick(event) {
        this.profileClick = true;
        this.inlineUserMenuActive = !this.inlineUserMenuActive;
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onRippleChange(event) {
        this.ripple = event.checked;
    }

    isHorizontal() {
        return this.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.layoutMode === 'slim';
    }

    isOverlay() {
        return this.layoutMode === 'overlay';
    }

    isStatic() {
        return this.layoutMode === 'static';
    }

    isMobile() {
        return window.innerWidth < 1025;
    }

    isDesktop() {
        return window.innerWidth > 896;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
}
