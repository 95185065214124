import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";


@NgModule({
  imports: [
    RouterModule.forRoot(
      [
 
        {
          path: '',
          loadChildren: () => import('./Modules/general/login/login.module')
            .then(mod => mod.LoginModule)
        },{
          path: 'home',
          loadChildren: () => import('./Modules/general/home/home.module')
            .then(mod => mod.HomeModule)
        },
        {
          path: 'login',
          loadChildren: () => import('./Modules/general/login/login.module')
            .then(mod => mod.LoginModule)
        },
        {
          path: "security",
          loadChildren: () => import('./security/security.module')
          .then(mod => mod.SecurityModule)
        },
        {
          path: "def",
          loadChildren: () => import('./definitions/definition.module')
          .then(mod => mod.DefinitionModule)
        },
        {
          path: "app",
          loadChildren: () => import('./Administration/AppSettings/appsettings.module')
          .then(mod => mod.AppSettingModule)
        },
        {
          path: "notifications",
          loadChildren: () => import('./Notification/modules/notification.module')
          .then(mod => mod.NotificationModule)
        },  {
          path: "dp",
          loadChildren: () => import('./Apps/modules/dp.module')
          .then(mod => mod.DPModule)
        },
        // {
        //   path: "crm",
        //   loadChildren: () => import('./modules/general/crm/crm.module')
        //   .then(mod => mod.CrmModule)
        // },

        // {
        //   path: "template",
        //   loadChildren: () => import('./modules/general/template/template.module')
        //   .then(mod => mod.TemplateModule)
        // },
        {
          path: "forms",
          loadChildren: () => import('./Forms/modules/forms.module')
          .then(mod => mod.FormsModule)
        },
        {
          path: "workflow",
          loadChildren: () => import('./Workflow/modules/workflow.module')
          .then(mod => mod.WorkflowModule)
        },
        {
          path: "service",
          loadChildren: () => import('./Services/modules/service/service.module')
          .then(mod => mod.ServiceModule)
        },
        {
          path: "org",
          loadChildren: () => import('./organization/org.module')
          .then(mod => mod.OrgModule)
        },
        {
          path: "report",
          loadChildren: () => import('./Reports/report.module')
          .then(mod => mod.ReportModule)
        },  
        // {
        //   path: "doc",
        //   loadChildren: () => import('./Document/doc.module')
        //   .then(mod => mod.DocModule)
        // },
        // {
        //   path: "portal",
        //   loadChildren: () => import('./modules/portal/portalapps/portalapps.module')
        //   .then(mod => mod.PortalappsModule)
        // },
        {
          path: "tabase",
          loadChildren: () => import('./TABase/tabase.module')
          .then(mod => mod.TABaseModule)
        }, 
        {
          path: "taassignment",
          loadChildren: () => import('./TAAssignment/taassignment.module')
          .then(mod => mod.TAAssignmentModule)
        }, 
        {
          path: "homepage",
          loadChildren: () => import('./Modules/general/home/home.module')
          .then(mod => mod.HomeModule)
         
        }, {
          path: "home",
          loadChildren: () => import('./Modules/general/home/home.module')
          .then(mod => mod.HomeModule)
         
        },{
          path: "plan",
          loadChildren: () => import('src/app/Plan/plan.module')
          .then(mod => mod.PlanModule)
         
        },
        { path: "**", redirectTo: "/404" },
      ],
      { scrollPositionRestoration: "enabled", relativeLinkResolution: "legacy" }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }


