export class LookUpTableValues {
    fK_langId: number;
    fK_CompanyId: number;
    lookUpValueId: number;
    fK_LookUpId: number;
    lookUpValueCode: string;
    lookUpValue: string;
    lookUpValue_LL: string;
    remarks: string;
    cREATED_BY: string;
    lAST_UPDATE_BY: string;
    isActive: boolean;
    lkpTrans: LookUpTableValues_languages[];
    select: boolean;
    /**
     *
     */
    constructor() {

        this.fK_langId = 0;
        this.lookUpValueId = 0;
        this.fK_CompanyId = 0;
        this.fK_LookUpId = 0;
        this.lookUpValueCode = "";
        this.lookUpValue = "";
        this.lookUpValue_LL = "";
        this.remarks = "";
        this.cREATED_BY = "";
        this.lAST_UPDATE_BY = "";
        this.isActive = false;
        this.lkpTrans = [];
        this.select = false;
    }
}

export class LookUpTableValuesCommon {
    lookUpValueId: number;
    fK_LookUpId: number;
    lookUpValueCode: string;
    lookUpValue: string;
    lookUpValue_LL: string;
    remarks: string;
    isActive: boolean;
    cREATED_BY: string;
    lAST_UPDATE_BY: string;
    objLookUpTableValuesCommon_languages: LookUpTableValuesCommon_languages[];
    select: boolean;
    constructor() {
        this.lookUpValueId = 0;
        this.fK_LookUpId = 0;
        this.lookUpValueCode = null;
        this.lookUpValue = null;
        this.lookUpValue_LL = null;
        this.remarks = null;
        this.isActive = false;
        this.cREATED_BY = null;
        this.lAST_UPDATE_BY = null;
        this.objLookUpTableValuesCommon_languages = [];
        this.select = false;
    }
}

export class LookUpTableValues_languages {
    FK_LookUpValueId: number;
    fK_langId: number;
    fK_LookUpId: number;
    fK_CompanyId: number;
    lookUpValue_lang: string;
    LanguageName: string;

    constructor() {
        this.FK_LookUpValueId = 0;
        this.fK_langId = 0;
        this.fK_LookUpId = 0;
        this.fK_CompanyId = 0;
        this.lookUpValue_lang = "";
        this.LanguageName = "";

    }
}

export class LookUpTableValuesCommon_languages {
    fK_langId: number;
    fK_LookUpId: number;
    fK_LookUpValueId: number;
    lookUpValue_lang: string;
    constructor() {
        this.fK_langId=0;
        this.fK_LookUpId=0;
        this.fK_LookUpValueId=0;
        this.lookUpValue_lang=null;
    }
}

export class LKPTranslations {
    fK_langId: number;
    lookUpValue_lang: string;
    /**
     *
     */
    constructor() {
        this.fK_langId = 0;
        this.lookUpValue_lang = "";
    }
}

export class LookUpTable {
    lookUpId:number;
    isCommon:boolean;
    fK_CompanyId:number;
    lookUp_Name:string;
    remarks:string;
    isActive:boolean;
    fK_langId:number;
    cREATED_BY:string;
    lAST_UPDATE_BY:string;
    Selected: boolean;
 
    constructor() {
        this.lookUpId = 0;
        this.isCommon = false;
        this.fK_CompanyId = 0;
        this.lookUp_Name = "";
        this.remarks = "";
        this.isActive = false;
        this.fK_langId = 0;
        this.cREATED_BY = "";
        this.lAST_UPDATE_BY = "";
        this.Selected = false;
    }
}

export class Country {
    countryId:number;
    countryName:string;
    dialCode:string;
    code:string;
    flag:string;
    constructor() {
        this.countryId = 0;
        this.countryName = "";
        this.dialCode = "";
        this.code = "";
        this.flag = "";
    }
}

export class lookup_ServiceUrls {

    GetAllLookUpValues: string;
    GetLookUpValuesByCompanyForDropDown: string;

    SaveLookUpTable: string;
    GetAllLookUpTables_ByCompany: string;
    GetLookUpTables_ByCompany: string;
    GetLookUpTableDetails: string;
    DeleteLookUpTable: string;
    GetCountryList: string;

    constructor() {
        this.GetAllLookUpValues = "LKP/GetAllLookUpValues";
        this.GetCountryList = "LKP/GetCountryList";
        this.GetLookUpValuesByCompanyForDropDown = "LKP/GetLookUpValuesByCompanyForDropDown";
        
        this.SaveLookUpTable = "LookUpTables/SaveLookUpTable";
        this.GetAllLookUpTables_ByCompany = "LookUpTables/GetAllLookUpTables_ByCompany";
        this.GetLookUpTables_ByCompany = "LookUpTables/GetLookUpTables_ByCompany";
        this.GetLookUpTableDetails = "LookUpTables/GetLookUpTableDetails";
        this.DeleteLookUpTable = "LookUpTables/DeleteLookUpTable";
    }
}


export class lookupTablesIds {

    Country: string;
    City: string;
    Nationality: string;
    Religion: string;
    MaritalStatus: string;
    Grade: string;
    Designation: string;
    CompanyType: string;
    CompanyIndustry: string;
    CompanyDocumentType: string;
    ContactInfoChannel: string;
    Salutation: string;
    ContactType: string;
    ContactSource: string;
    ContactDocType: string;
    Priority: string;
    CorrespondenceClassification: string;
    CorrespondenceRelation: string;
    PrivilligeOptions: string;
    AttachmentType: string;
    ContactNoType: string;
    Parameters: string;
    RequestType: string;
    ParamCategory: string;
    ParamType: string;

    FormTemplateRuleConditions: string;
    FieldProperty: string;
    DocumentProperty: string;
    SubscriptionFeatures: string;
    SubscriptionDuration: string;
    Currency: string;
    RegistrationStatus: string;
    constructor() {
        this.RegistrationStatus = "Registration Status"
        this.Country = "Country"
        this.City = "City"
        this.Nationality = "Nationality"
        this.Religion = "Religion"
        this.MaritalStatus = "Marital Status"
        this.Grade = "Grade"
        this.Designation = "Designation"
        this.CompanyType = "Company Type"
        this.CompanyIndustry = "Company Industry"
        this.CompanyDocumentType = "Company Document Type"
        this.ContactInfoChannel = "Contact Info Channel"
        this.Salutation = "Salutation"
        this.ContactType = "ContactType"
        this.ContactSource = "ContactSource"
        this.ContactDocType = "ContactDocType"
        this.Priority = "Priority";
        this.CorrespondenceClassification = "Correspondence Classification"
        this.CorrespondenceRelation = "Correspondence Relation";
        this.PrivilligeOptions = "PrivilligeOptions";
        this.AttachmentType = "Attachment Type";
        this.ContactNoType = "ContactNoType";
        this.Parameters = "Parameters";
        this.RequestType = "RequestType";
        this.ParamCategory = "ParamCategory";
        this.ParamType = "ParamType";

        this.FormTemplateRuleConditions = "Rule Conditions";
        this.FieldProperty = "Field Property";
        this.DocumentProperty = "Document Property";
        this.SubscriptionFeatures = "Subscription Features";
        this.SubscriptionDuration = "SubscriptionDuration";
        this.Currency = "Currency";

    }
}
