import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaderResponse,
  HttpParams,
} from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { GlobalVariablesService } from "./global-variables.service";

export interface ParamValue {
  param: string;
  value: any;
}

@Injectable({
  providedIn: "root",
})
export class httpService {
  token: string = "";
  headers: HttpHeaders;

  constructor(
    private httpclient: HttpClient,
    private router: Router,
    public global: GlobalVariablesService
  ) {}

  GetDocumentBase64(Object: any, APIName: string = ""): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = APIName;
    return this.httpclient.post(url, Object, { headers });
  }

  CreateHeaderWithLangAndCompany() {
    this.token = sessionStorage.getItem("token");
    const companyId = this.global.FK_CompanyId();
    //const contactid = this.global.GetContactId();
    const langid = this.global.GetLanguage();
    let headers = new HttpHeaders({
      OrgCompanyId: companyId.toString(),
      //ContactId: contactid.toString(),
      Lang: langid, //this.languageTranslateService.currentlang === 'en'? '2' : '1',
      UserId: this.global.getUserID(),
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("====Header====");
    console.log(
      "companyid=" + companyId.toString() + " userid=" + this.global.getUserID()
    );
    console.log("====Header====");
    return headers;
  }
  /////// Ammar Chnages End ////////
  CreateHeader() {
    let headers = this.CreateHeaderWithLangAndCompany();

    return headers;
  }

  SignOut() {
    sessionStorage.removeItem("objSys_users");
    sessionStorage.removeItem("lang");
    sessionStorage.removeItem("languageObj");
    sessionStorage.removeItem("isRTL");
    sessionStorage.removeItem("menu");
    sessionStorage.removeItem("languagelist");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("menuMode");
    this.router.navigateByUrl("/login");
  }

  serviceUrl(ServiceName, actionName) {
    let actionUrl = "";
    switch (ServiceName) {
      case "Security":
        actionUrl = environment.serviceIP_Security + actionName;
        break;
      case "Core":
        actionUrl = environment.serviceIP_Core + actionName;
        break;
      case "Org":
        actionUrl = environment.serviceIP_Org + actionName;
        break;
      case "Workflow":
        actionUrl = environment.serviceIP_Workflow + actionName;
        break;
      case "Forms":
        actionUrl = environment.serviceIP_Forms + actionName;
        break;
      case "Old":
        actionUrl = environment.serviceIP_Old + actionName;
      case "tabase":
        actionUrl = environment.serviceIP_TABase + actionName;
        break;
      case "taassignment":
        actionUrl = environment.serviceIP_TAAssignment + actionName;
        break;
      case "dynamicapi":
        actionUrl = environment.serviceIP_DynamicApi + actionName;
        break;
      case "apps":
        actionUrl = environment.serviceIP_AppsApi + actionName;
        break;

      case "transactions":
        actionUrl = environment.serviceIP_Transactions + actionName;
        break;
      case "services":
        actionUrl = environment.serviceIP_Services + actionName;
        break;
      case "reports":
        actionUrl = environment.serviceIP_Reports + actionName;
        break;
      default:
        actionUrl = environment.serviceIP_Old + actionName;
        break;
    }
    return actionUrl;
  }
  get(
    value: any,
    paramName: string,
    UrlName: string,
    APIService: string = ""
  ): Observable<any> {
    let headers = this.CreateHeader();
    const url = this.serviceUrl(APIService, UrlName);
    console.log(url);

    let param1 = new HttpParams().set(paramName, value);
    return this.httpclient.get(url, { params: param1, headers: headers }).pipe(
      catchError((err) => {
        debugger;
        if (err.status === 401) {
          console.log("401 error caught in service");
          //this.SignOut();
        } else {
          console.log("error caught in service");
        }

        return throwError(err);
      })
    );
  }
  getParamLst(
    lstparam: ParamValue[],
    UrlName: string,
    APIService: string = ""
  ): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = this.serviceUrl(APIService, UrlName);
    let param1 = new HttpParams();
    lstparam.forEach((element) => {
      param1 = param1.append(element.param, element.value.toString());
    });
    return this.httpclient.get(url, { params: param1, headers }).pipe(
      catchError((err) => {
        if (err.status === 401) {
          console.log("401 error caught in service");
          this.SignOut();
        } else {
          console.log("error caught in service");
        }

        //Handle the error here

        return throwError(err);
      })
    );
  }
  post(Object: any, UrlName: string, APIService: string = ""): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = this.serviceUrl(APIService, UrlName);
    console.log(url);
    console.log(Object);
    console.log(Object);
    return this.httpclient.post(url, Object, { headers }).pipe(
      catchError((err) => {
        if (err.status === 401) {
          console.log("401 error caught in service");
          this.SignOut();
        } else {
          console.log("error caught in service");
        }

        return throwError(err);
      })
    );
  }
  delete(
    value: any,
    paramName: string,
    UrlName: string,
    APIService: string = ""
  ): Observable<any> {
    let headers = this.CreateHeader();
    const url = this.serviceUrl(APIService, UrlName);
    console.log(url);
    let param1 = new HttpParams().set(paramName, value);
    return this.httpclient.delete(url, { params: param1, headers: headers });
  }
  DeleteParamLst(lstparam: ParamValue[], UrlName: string, APIService: string = ''): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    const url = this.serviceUrl(APIService, UrlName);
    let param1 = new HttpParams();
    lstparam.forEach((element) => {
      param1 = param1.append(element.param, element.value.toString());
    });
    return this.httpclient.delete(url, { params: param1, headers: headers });
  }
  postGenericParmas(
    Object: any,
    UrlName: string,
    APIService: string = ""
  ): Observable<any> {
    const headers = this.CreateHeaderWithLangAndCompany();
    var url = this.serviceUrl(APIService, UrlName);
    console.log(url);
    //alert(url);
    console.log(Object);
    console.log(Object);
    return this.httpclient.post(url, Object, { headers }).pipe(
      catchError((err) => {
        debugger;
        if (err.status === 401) {
          console.log("401 error caught in service");
          this.SignOut();
        } else {
          console.log("error caught in service");
        }

        return throwError(err);
      })
    );
  }
  DeleteGenericParmas(
    value: any,
    paramName: string,
    UrlName: string,
    APIService: string = ""
  ): Observable<any> {
    let headers = this.CreateHeader();
    var url = this.serviceUrl(APIService, UrlName);
    console.log(url);
    let param1 = new HttpParams().set(paramName, value);
    return this.httpclient.delete(url, { params: param1, headers: headers });
  }
}
