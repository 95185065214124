import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppMainComponent } from "src/app/app.main.component";

import { BaseComponent } from "src/app/Shared/base-component";
import { UserInput, Sys_users, Menu, Languages } from "src/app/Classes/login";
import { LanguageTranslateService } from "src/app/Shared/language-translate.service";
import { GlobalVariablesService } from "src/app/Shared/global-variables.service";
import { SharedService } from "src/app/Shared/shared.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DefinitionService } from "../definitions/services/definition.services";
@Component({
  selector: "app-topbar",
  templateUrl: "./app.topbar.component.html",
})
export class AppTopBarComponent extends BaseComponent implements OnInit {
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();
  UserName: string;
  ProfileUrl: string;
logo: string;
  lstLanguages: Languages[];
  langgObj: Languages;
  islogo: boolean;
  constructor(
    public app: AppMainComponent,
    public global: GlobalVariablesService,
    public languageTranslateService: LanguageTranslateService,
    private _svc: SharedService,private router: Router,private activeRouter:ActivatedRoute,
    private _def: DefinitionService
  ) {
    super(languageTranslateService);
    this.ProfileUrl = null;
  }
  //#/contactprofile
  ngOnInit() {
    this.islogo = false;
    this.logo = '';
    this.UserName = this.global.getUserFullName();
    this.langgObj = new Languages();
    //alert(sessionStorage.getItem('languageObj'));
    //if(this.global.hasZeroLength(this.global.Languages)){
    if (sessionStorage.getItem("languageObj") == null) {
      this.getlanguage();
      this.changeLanguage("EN");
      //alert('lang 2');
    } else {
      this.langgObj = JSON.parse(sessionStorage.getItem("languageObj"));
      this.getlanguage();
      this.changeLanguage(this.langgObj.langCode.toString());
    }
this.SetOrgCompanylogo();
 
  }
  SetOrgCompanylogo(){
    this.logo = this.global.GetOrgCompanyLogo();
    // const topbarLogoLink: HTMLImageElement = document.getElementById(
    //   "topbar-logo"
    // ) as HTMLImageElement;
    this.islogo = true;

    if (this.logo == null || this.logo.trim() === '') {
      this.logo = "assets/layout/images/logo.png";
      this.islogo = false;

      // topbarLogoLink.src =
      // "assets/layout/images/" +
      // sessionStorage.getItem("logo").toString() +
      // ".png";
    } 
  }
  getlanguage() {
    if (sessionStorage.getItem('languagelist') !== null)
    {
        this.lstLanguages = JSON.parse(sessionStorage.getItem('languagelist')); 
        return;
    }
    var companyId = this.global.FK_CompanyId();
    this._def.GetAllLanguagesByCompany().subscribe(
        (data) => {
          this.lstLanguages = data;
        //  this.global.Languages = this.langObject;
          if (sessionStorage.getItem('languagelist') == null)
          {
              sessionStorage.setItem('languagelist',JSON.stringify( this.lstLanguages));
          }
        },
        (err) => {
          // alert("error occured ")
        }
      );
  }

 
  selectLanguage(sellang: Languages) {
    debugger;
    var langCode: string;
    sessionStorage.removeItem('menu');
 
    langCode = sellang.langCode.toString().toLowerCase();
    this.langgObj = sellang;
    //alert(JSON.stringify(sellang))
    sessionStorage.setItem('lang', this.langgObj.langId .toString());
    sessionStorage.setItem("languageObj", JSON.stringify(sellang));

    this.changeLanguage(langCode);
    
    var _activeChild = this.activeRouter.children.length;

       let currentUrl = this.router.url;
    if(currentUrl.toString().includes('/(')){
      window.location.reload();
      //this.router.navigateByUrl(currentUrl, {skipLocationChange: false});
    }else{
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    }  
  
  }
  changeLanguage(lang: string) {
  
        this.languageTranslateService.setLang(lang.toLocaleLowerCase());
       if( this.langgObj.langId > 0){
        this.app.isRTL = this.langgObj.isRTL;
        this.setMenuSession("isRTL", this.langgObj.isRTL.valueOf().toString());
       }else{
        this.app.isRTL = false;
        this.setMenuSession("isRTL", "false");
       }
     

    // if (lang == "1") {
    //   this.languageTranslateService.setLang("ar");
    //   this.app.isRTL = true;
    //   this.setMenuSession("isRTL", "true");
    // } else {
    //   this.languageTranslateService.setLang("en");
    //   this.app.isRTL = false;
    //   this.setMenuSession("isRTL", "false");
    // }

    try {
      this.parentFun.emit();
    } catch (error) {}
  }
  setMenuSession(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }


  SignOut(){
            sessionStorage.removeItem('objSys_users');
            sessionStorage.removeItem('lang');
            sessionStorage.removeItem('languageObj');
            sessionStorage.removeItem('isRTL');
            sessionStorage.removeItem('menu');
            sessionStorage.removeItem('languagelist');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('menuMode');
             // this.router.navigateByUrl('/login');
  }
}
