export const DROPDOWN_LANGS = ['English', 'Arabic']
export const BUTTONS = ['Save', 'Delete', 'Clear', 'Cancel', 'Update']
export const ApplicationSetting = ['Application Settings', 'Application Settings', 'Customer English Name', 'Customer English Short Name', 'Customer Arabic Name', 'Customer Arabic Short Name']
export const Login = 'Log in'
export const Yes = "Yes"
export const No = "No"
export const Number = "Number"
export const NumberShortName = "No."
export const View = "View"
export const LookUpValue = ' Name'
export const LookUpValueCode = ' Code'
export const OrgUnit = "Org Unit";
export const Group = "Group";
export const EmployeeNo = 'Employee No.';
export const Search = 'Search';
export const AddNew = 'Add New';
export const Clear = 'Clear';
export const Delete = 'Delete';
export const Save = 'Save';
export const AddRamadan = 'Add Ramadan';
export const UpdateRamadan = 'Update Ramadan';
export const EditRamadan = 'Edit Ramadan';
export const UserName = 'User Name';
export const FullName = "Full Name"
export const HasParent = "Has Parent"
export const Edit = "Edit"
export const addLanguage = "Add Language"
export const ModalHeader = "Modal Header"
export const AddNewUser = "Add New User"
export const Organization = "Organization"
export const Name = "Name"
export const ShortName = "Short Name"
export const Address = "Address"
export const PhoneNo = "Phone No"
export const Fax = "Fax"
export const URL = "URL"
export const OganizationLevels = "Oganization Level(s)"
export const Level = "Level "
export const LevelName = "Level Name"
export const LevelNameLocal = "Level Name Local Language"
export const ArabicName = "Arabic Name"
export const OrganizationStructure = 'Organization Structure'
export const EnglishName = 'English Name'
export const EntityCode = 'Entity Code'
export const EntityInfo = 'Entity Info'
export const Definitions = 'Definitions'
export const EmployeeStatus = 'Employee Status'
export const EmployeeType = 'Employee Type'
export const Employee = 'Employee'
export const LstEmployee = 'List of Employee(s)'
export const DefineService = 'Services / Templates Definition'
export const ListofServices = 'List of Services'
export const DynamicFormsDefinition = 'Dynamic Forms Definition'
export const AddTemplate = 'Add Template'
export const Select = 'Select'
export const Showing = 'Showing'
export const of = 'of'
export const to = 'to'
export const Records = 'Record(s)'
export const UserID = "User ID";
export const Email = "Email";
export const FullNameInlocalLanguage = "Full Name in Local Language ";
export const UniqueID = "Unique ID";
export const ConfirmPassword = "Confirm Password";
export const Company = "Company";
export const PhoneNumber = "Phone Number";
export const OrganizationEntity = "Organization Entity"
export const JobDescription = "Job Description";
export const isActive = "Is Active"
export const Password = "Password"
export const FilterBy = "Filter By"
export const SavedSuccessfully = "Saved Successfully"
export const DeletedSuccessfully = "Deleted Successfully"
export const NoRecordFound = "No Record Found"
export const SomeErrorOccured = "Some Error Occured, Please Contact Support."
export const FillMentionedFields = "Please Fill Mentioned Fields : "

export const languageName = "Language"
export const LangId = "LangId"
export const LangCode = "Lang Code"
export const lookUpValue_lang = " Value language"
export const Country = "Country"
export const orgUnit = "Org Unit"
export const SaveDefinition = "Save Definition"

export const orgUnitName_LL = "Unit Name in Local Language";
export const orgUnitName = "Unit Name";
export const orgUnitCode = "Unit Code";
export const fK_LevelId = "fK_LevelId";
export const CompanyName = "Company Name";

export const OrgStructure = "Organization Structure";
export const WorkGroup = "Work Group";

export const id = "id"
export const HasChildren = "Has Children";
export const parentid = "parent id ";
export const UnitName = "Unit Name";
export const value = "value";
export const ChildName = "Child Name";
export const WorkLocation = "Work Location"


export const radius = "Radius";
export const workLocationId = "Work Location Id";
export const workLocationCode = "Work Location Code";
export const workLocationName = "Work Location Name";
export const workLocationName_LL = "Work Location Name";
export const gPSCoordinates = "GPS Coordinates";

export const logicalGroup = 'Work Group';
export const EmployeeWorkGroup = 'Employee Work Group';
export const GroupId = "Group Id"
export const GroupName = "Group Name";
export const GroupName_LL = "Group Name Local Language";
export const EmployeeWorkGroupPeriodSearch = "Employee Work Group Search";


export const statusCode = "Status Code"
export const StatusName = "Status Name"
export const StatusName_LL = "Status Name Local Language"
export const StatusDescription = "Status Description"
export const CosiderEmployeeActive = "Consider Employee Active"

export const TypeName = "Type Name"
export const TypeName_LL = "Type Name Local Language"
export const IsInternaltype = "Is Internal Type"
export const IsInternal = "Is Internal"
export const EmployeeNumberInitial = "Employee Number Initial"
export const EmployeeNumber = "Employee Number"

export const employeeName = "Employee Name"
export const employeeName_LL = "Employee Name Local Language"
export const joinDate = "Join Date"

export const FK_Status = "Status"
export const FK_EmployeeTypeId = "EmployeeTypeId"
export const FK_CompanyId = "Company Id"
export const FK_OrgUnitId = "Unit Id"
export const FK_WorkLocation = "Work Location"
export const FK_LogicalGroup = "Logical Group"
export const FK_lkpNationality = "Nationality"
export const FK_lkpReligion = "Religion"
export const FK_lkpMaritalStatus = "MaritalStatus"
export const FK_lkpGrade = "Grade"
export const FK_lkpDesignation = "Designation"
export const IsTerminated = "Is Terminated"
export const EmployeeActiveCard = "Employee Active Card"
export const ExternalPartyName = "External Party Name"
export const Gender = "Gender"
export const OrgUnitHierarchy = "Org Unit Hierarchy"
export const Remarks = "Remarks"
export const EmpImagePath = "Emp Image Path"
export const MobileNo = "Mobile No"
export const ContractEndDate = "Contract End Date"
export const DOB = "Date of Birth"
export const TerminateDate = "Terminate Date"
export const select = "select"
export const AddNewEmployee = "Add New Employee"
export const addUnit = 'Add Unit'


export const GroupID = 'GroupID';
export const DefaultPage = 'Default Page'
export const AddNewGroup = 'Add New Group'
export const Language = 'Language'

export const DefaultLanguage = 'Default Language'
export const LocalLanguage = 'Local Language'
export const LanguageList = 'Language List'

export const RamadanWorkSchedule = "Ramadan Schedule"
export const WorkScheduleTime = "Work Schedule Time"
export const WorkScheduleSearch = "Work Schedule Search"
export const FlexibleDuration = "Flexible Duration";
export const ScheduleName = "Schedule Name"
export const ScheduleNameLL = "Schedule Name Local Language"
export const Normal = "Normal"
export const Flexible = "Flexible"
export const Open = "Open"
export const GraceIn = "Grace In"
export const GraceOut = "Grace Out"
export const ConsiderDelayFromGrace = "Consider Delay From Grace"
export const ConsiderEarlyOutFromGrace = "Consider Early Out From Grace"
export const Default = "Default"
export const Active = "Active"
export const InActive = "InActive"
export const ScheduleTiming = "Schedule Timing"
export const WorkDays = "Work Days"
export const WorkHours = "Work Hours"

export const Terminated = "Terminated"
export const Male = "Male"
export const Female = "Female"
export const DelayIsFromGrace = "Delay Is From Grace"
export const EarlyOutIsFromGrace = "Early Out Is From Grace"
export const Selected = "Selected"
export const IsRamadanSch = "Is Ramadan"
export const IsDefault = "Is Default"
export const AddRamazanSchedule = "Add Ramadan Schedule"
export const SaveRamazanSchedule = "Save Ramadan Schedule";
export const scheduleType = "Schedule Type"
export const ViewRamazanSchedule = "View Ramadan Schedule"


export const flixibleDuration = "Flixible Duration";
export const fromTime = "From Time";
export const toTime = "To Time";
export const isFlixible = "Is Flixible";
export const toBeCompensated = "To Be Compensated";
export const noOfAllowedBreak = "No Of Allowed Break";

export const Add = "Add"
export const Remove = "Remove"
export const PleaseSelect = "--Please Select--"

export const fromDate = "From Date";
export const toDate = "To Date";

export const Absent = "Absent"
export const DelayEarlyOut = "Delay & Early Out"
export const MissingInorOut = "Missing In or Out"


export const Continuous = "Continuous"
export const Seperated = "Seperated"
export const Occuerence = "Occuerence"

export const Notice = "Notice"
export const Warning = "Warning"
export const DeductfromSalary = "Deduct from Salary"
export const Suspension = "Suspension"
export const Dismiss = "Dismiss"
export const Overtime = "Overtime"

export const WorkDay = "Work Day"
export const OffDay = "Off Day"
export const Holiday = "Holiday"
export const ReligionHoliday = "Religion Holiday"



//Leaves Types 

export const LeaveTypeTitle = "Leaves Types";
export const LeaveTypeSearch = "Leave Type Search";
export const LeaveTypeOccurrenceSearch = "Leave Type Occurrence Search";
export const LeaveTypeOccurrence = "Leave Type Occurrence";
export const AdditionalConfiguration = "Additional Configuration";
export const AdvancedSettings = "Advanced Settings";
export const LeaveType = "Leave Type";
export const LeaveTypeName = "Leave Type Name";
export const LeaveTypeNameLL = "Leave Type Name Local Language";
export const HourlyLeave = "Hourly Leave";
export const LeaveTypeCode = "Leave Type Code";
export const DailyLeave = "Daily Leave";
export const Balancing = "Balancing";
export const Monthly = "Monthly";
export const Yearly = "Yearly";
export const MinimumDuration = "Minimum Duration";
export const MaximumDuration = "Maximum Duration";
export const ServiceDays = "Service Days";
export const MaximumRoundBalance = "Maximum Round Balance";
export const balance = "Balance";
export const allowIfBalanceInsufficient = "Allow If Balance Insufficient";
export const generalGuide = "General Guide";
export const generalGuide_LL = "General Guide in Local Language";
export const ParentLeaveType = "Parent Leave Type";
export const allowedForSelfService = "Allowed For Self Service"
export const showRemainingBalance = "Show Remaining Balance"
export const NotificationException = "Notification Exception";
export const attachmentIsMandatory = "Attachment Is Mandatory"
export const remarksIsMandatory = "Remarks Is Mandatory";
export const AllGrades = "All Grades";
export const SpecificGrade = "Specific Grade(s)";
export const Grades = "Grade(s)";
export const GradeBalance = "Grade Balance";
export const AllOrganizationUnits = "All Organization Unit(s)";
export const SpecificOrganizationUnits = "Specific Organization Unit(s)";
export const OrganizationUnits = "Organization Unit(s)";
export const allowedGender = "Allowed Gender";
export const allowInRamadan = "Allowed In Ramadan";
export const ApprovalRequired = "Approval Required"
export const ApprovalWorkFlow = "Approval Work Flow"
export const isConsiderInWork = "Consider In Work";
export const allowedAfterSpecificTime = "Allowed After Specific Time";
export const allowedAfter = "Allowed After";
export const allowedBeforeSpecificTime = "Allowed Before Specific Time";
export const allowedBefore = "Allowed Before"
export const TransactionType = "Transaction Type"
export const TransactionType_LL = "Transaction Type Local Language"
export const notAllowedWithStudyNursing = "Not Allowed When Study & Nursing";
export const hasFullDay = "Has FullDay Hourly Leave";
export const hasHourlyLeaveForPeriod = "Has Hourly Leave For Period";
export const hasHourlyLeaveSpecificTime = "Has Hourly Leave Specific Time";
export const hasFlexibleHourlyLeave = "Has Hourly Leave Flexible Duration";
export const considerRequestWithinBalance = "Consider Request Within Balance";
export const convertToLeave_ExceedDuration = "Convert To Daily Leave When Exceed Duration";
export const mustHaveTransaction = "Must Have Transaction";
export const deductBalanceFromOvertime = "Deduct Balance From Overtime";
export const overtimeValidityDays = "Overtime Validity Days";
export const flexibleAllowedTime = "Flexible Allowed Time";
export const defaultNoOfDays = "Default No Of Days";
export const Annual = "Annual"
export const ExcludeOffDays = "Exclude Off Days"
export const ExcludeHolidays = "Exclude Holidays"
export const AllowedBeforeRestDays = "Allowed Before Rest Days"
export const LeaveTypeDurationSearch = "Leave Type Duration Search"
export const LeaveTypeDuration = "Leave Type Duration"
export const balanceConsideration_TC = "Balance Consideration TC";
export const leavestypes = "Leaves Types";
export const occuerrence = "Occuerrence";
export const duration = "Duration";
export const MaximumOccuerence = "Maximum Occuerence";
export const durationName = "Duration Name";
export const durationNameLL = "Duration Name Local Language";
export const maximumRamadanDuration = "Maximum Ramadan Duration";
export const LeaveTypesGrades = "Leave Types Grades";
export const LeaveTypesOrganizationUnits = "Leave Types Organization Units";
export const LeaveTypesEmployeeTypes = "Leave Types Employee Types";
export const LeaveTypeHourlyTransactionType = "Leave Type Hourly Transaction Type";
export const LeaveTypesDaily = "Leave Types Daily";
export const LeaveTypesHourly = "Leave Types Hourly";
export const WeekDays = "Week Days";
 

//Application Settings
export const GeneralSettings = "General Settings"
export const ConsequenceTransactions = "Consequence Transactions"
export const FirstInOut = "First In\Last Out"
export const LastInFirstOut = "Last In\First Out"
export const MinimumTimeBetweenTransactions = "Minimum Time Between Transactions (Minutes)"
export const TAPolicyGraceBy = "TA Policy Grace By";
export const WorkSchedule = "Work Schedule";
export const ConsiderAbsentAfter = "Consider Absent After (Minutes)";
export const ConsiderAbsentEvenIfAttend = "Consider Absent Even If Attend";
export const ShiftScheduleWithNoShifts = "Shift Schedule With No Shifts";
export const DefaultWorkSchedule = "Default Work Schedule";
export const DayMinutes = "Consider the Day (Minutes)";
export const ArchivingMonths = "Archive Data (Months)";
export const ViolationJustificationDays = "Violation Justification Allowed in (Days)";
export const ExcludeFromViolationJustificationDaysChecklist = "Exclude From Violation Justification Days";
export const Holidays = "Holidays";
export const DailyLeaves = "Daily Leaves";
export const RestDay = "Rest Day";
export const Leave = "Leave";
export const ManualAttendanceWorkFlow = "Manual Attendance Work Flow";
export const UpdateRequestWorkFlow = "Update Request Work Flow";
export const AutoHourlyLeaveType = "Auto Hourly Leave Type";

export const LeaveSettings = "Leave Settings";
export const AutoPersonalHourlyLeaveForDelay = "Auto Personal Hourly Leave For Delay";
export const AutoPersonalHourlyLeaveDuration = "Auto Personal Hourly Leave Duration";
export const AutoPersonalHourlyLeaveForEarlyOut = "Auto Personal Hourly Leave For Early Out";
export const ShowDailyLeaveRequestLinkInViolationCorrection = "Allow Daily Leave Request from Violations";
export const ShowHourlyLeaveRequestLinkInViolationCorrection = "Allow Hourly Leave Request from Violations";
export const ConsiderLeaveOnOffDays = "Show Leave On Off Days as";

export const ManualEntrySettings = "Manual Entry Settings"
export const AttendanceTransactionsSettings = "Attendance Transactions Settings"
export const Allowedforpastdays = "Allowed for Past (days)"
export const attachmentIsMandatoryForRequest = "Attachment Is Mandatory For Requests"
export const NoOfManualEntryPerDay = "No. Of Manual Entry Per Day"
export const AllowedRequestsPerDay = "Allowed Requests Per Day"
export const NoOfManualEntryPerMonth = "No. Of Manual Entry Per Month"
export const AllowedRequestsPerMonth = "Allowed Requests Per Month"
export const AutoApproveManualTrans = "Auto Approve Manual Transaction Request"
export const ManualTransServiceName = "Manual Transaction Request Service Name"
export const AutoApproveUpdateTrans = "Auto Approve Update Transaction Request"
export const UpdateTransServiceName = "Update Transaction Request Service Name"
export const LeaveApprovalWorkflow = "Default Leave Request Work Flow"
export const ApprovalWorkflow = "Approval Work flow"
export const AllowEditDate = "Allow Edit Date"
export const AllowEditTime = "Allow Edit Time"

export const TAReason = "TA Reason"
export const AttendanceReasons = "Attendance Reasons"
export const TAReasonTitle = "TA Reason"
export const AttendanceReasonsTitle = "Attendance Reasons"
export const TAReasonSearch = "TA Reason Search"
export const ReasonCode = "Reason Code"
export const ReasonName = "Reason Name"
export const ReasonNameLL = "Reason Name Local Language"
export const ConsiderInside = "Consider Inside"
export const DisplayType = "Display Type"


export const LeaveWorkFlow = "Leave WorkFlow"
export const ApplicationUsersType = "Application Users Type"
export const SystemUsersOnly = "System Users Only"
export const ActiveDirectoryOnly = "Active Directory Only"
export const Both = "Both"
export const ShowLoginPage = "Show Login Page"
export const WeekStartDay = "Week Start Day"
export const WorkDaysMinutes = "Work Days Minutes"

export const ShowDailyLeaveRequest = "Show Daily Leave Request"


export const AutoPersonalHourlyLeaveForDuration = "Auto Personal Hourly Leave Duration"
export const SpecificEntity = "Specific Entity"
export const All = "All"
export const employees = "Employee(s)"
export const Entity = "Entity"
export const LogicalGroup = "Work Group"
export const EmployeeList = "Employee List"
export const entityList = "Entity List"
export const WorklocationsList = "Work Locations"
export const Temporary = "Temporary"

export const SpecificGroup = 'Specific Group'
export const Worklocation = "Worklocation"
export const entity = "Entity"
export const Manager = "Manager"

export const ServiceDefinitionServiceCategoryTitle = "Service Definition - (Service Category:";
export const ServiceCode = "Service Code";
export const ServiceName = "Service Name";
export const ServiceName_LL = "Service Name in Local Language";
export const ServiceAbbreviation = "Service Abbreviation";
export const AppNoFormula = "Application Number Formula";
export const ServiceCategory = "Service Category";
export const HasWorkflow = "Has Workflow";
export const TemplateDocument = "Template Document";
export const Workflow = "Workflow";
export const HasFormTemplate = "Has Form Template";
export const FormTemplate = "Form Template";
export const FormTemplateName = "Form Template";
export const FormTemplateNameLL = "Form Template LL";
export const FilesSecurityOption = "Files Security Option";
export const SaveEncrypted = "Save Encrypted";
export const SubmittionType = "Submittion Type";
export const PublishOnWeb = "Publish On Web";
export const PublishOnMobile = "Publish On Mobile";
export const PublishOnKiosk = "Publish On Kiosk";
export const DocumentName = "Document Name";
export const TemplateText = "Template Text";
export const IsRequired = "Required";
export const IntroText = "Introduction Text";
export const TOA = "Terms Of Agreement";
export const PrivilegesType = "Assignment Type";
export const SecurityGroup = "Security Group";
export const SecurityGroups = "Security Groups";
export const SaveSecurityGroup = "Save Security Group";
export const Users = "Users";
export const User = "User";
export const IsManualAssign = "Has Manual Assign";
export const Definition = "Definition";
export const AddPanel = "Add Panel";
export const AddControl = "Add Control";
export const ServiceDocuments = "Service Documents";
export const PanelName = "Panel Name";
export const PanelNameLL = "Panel Name Local Language";
export const FieldCaption = "Field Caption";
export const FieldCaptionLL = "Field Caption Local Language";
export const Mandatory = "Mandatory";
export const Unique = "Unique";
export const Indexed = "Indexed";
export const Hidden = "Hidden";
export const MinimumValue = "Minimum Value";
export const MaximumValue = "Maximum Value";
export const AddListItems = "Add List Items";
export const FieldText = "Field Text";
export const FieldTextLL = "Field Text Local Language";
export const FieldValue = "Field Value";
export const FieldOrder = "Field Order";


//// WorkFlow //
export const WorkflowName = 'WorkFlow Name';
export const WorkflowName_LL = 'WorkFlow Name in Local Language';
export const IsStartPoint = 'Start Point';
export const IsEndPoint = 'End Point';

export const AllowEdit = 'Allow Edit';
export const AllowDocumentsUpload = 'Allow Documents Upload';
export const AllowDocumentsDownload = 'Allow Documents Download';
export const HasReasons = 'Has Reasons';
export const EditStatus = 'Edit Status';
export const DefineStatus = 'Define Status For WorkFlow';
export const Privilliges = 'Privilliges';
export const StatusActions = 'Status Actions';
export const ActionName = 'Action Name';
export const ActionName_LL = 'Action Name Local Language';
export const ActionStatus = 'Status after Action';
export const IsApprove = 'Status For Approval';
export const ReasonName_LL = 'Reason Name Local Language';
export const ReasonOrder = 'Reason Order';
export const ActionReason = 'Reasons For ';
export const StatusList = 'List Of Status For WorkFlow';
export const AddEscalation = 'Add Escalation Actions';
export const StatusDefinition = 'Status Definition';
export const FormList = 'List For Dynamic Forms';
export const FormName = 'Form Template Name';
export const Escalation = 'Status Escalation';
export const AddReason = 'Add Reason For Action';
export const IsDirectManager = 'Direct Manager';
export const EscalationName = 'Escalation Name';
export const EscalationName_LL = 'Escalation Name in Local Language';
export const EscalationAction = 'Escalation Actions';
export const NotificationGroup = 'Notification Groups';
export const NumberofDays = 'Number of Days';
export const ActionList = 'List Of Actions For Status';
export const SendNotification = 'Send Notification ';
export const ActionsOption = 'Select Action From Status Actions ';
export const MoveToNewStatus = 'Move Application To New Status ';
export const EscalationList = 'Escalation List';

export const DefineCompany = 'Define Contact Company';
export const ContactCompanyName = 'Contact Company Name';
export const ContactCompanyName_LL = 'Contact Company Name in Local Language';
export const lstCompany = 'List Of Companies';
export const CompanyInfo = 'Contact Company Information';
export const CompanyDocuments = 'Company Documents';
export const CompanyContactInfo = 'Company Contact Information';
export const EmployeesNo = 'Number Of Employee';
export const ParentCompany = 'Parent Company';
export const CompanyType = 'Company Type';
export const CompanyIndustry = 'Company Industry';
export const CompanyLogo = 'Company Logo';
export const IsPublic = 'Public';
export const IsActive = 'Active';
export const City = 'City';
export const DocumentType = 'Document Type';
export const UploadFile = 'Upload File';
export const ContactChannel = 'Contact Channel';
export const ContactInfo = 'Contact Info';
export const FileName = 'File Name';
export const FileExtension = 'File Extension';
export const lstCompanyContactInfo = 'List Of Company Contact Info';
export const lstCompanyPapers = 'List Of Company Documents';


export const Salutation = 'Salutation';
export const ListOfContacts = 'List Of Contacts';
export const Nationality = 'Nationality';
export const ContactType = 'Contact Type';
export const ContactSource = 'Contact Source';
export const Position = 'Position';
export const Description = 'Description';
export const Description_LL = 'Description Local Language';
export const ContactDocuments = 'Contact Documents';
export const UploadDocument = 'Upload Document';
export const CreateDocument = 'Create Document';
export const SavePDF = 'Save PDF';
export const AddRemarkPDF = 'Add Remark PDF';
export const AddAudioRemark = 'Add Audio Remark';
export const SignDocument = 'Sign Document';
export const ShowRemarkFile = 'Show Remark File';

export const LinkToCompany = 'Link To Company';
export const isMain = 'Main Company';
export const ContactCompanyList = 'Contact Company List';


export const SearchAllApplications = 'View All Applications';
export const SearchAssignedApplications = 'Assigned Applications';
export const ListAllApplications = 'List Of Applications';
export const ViewApplicationDetails = 'View Application Details';
export const SearchPendingApplications = 'Pending Applications';
export const ApplicationNo = 'Application No.';
export const ApplicationDate = 'Application Date';
export const ApplicationStatus = 'Application Status';
export const SystemUser = 'System User';
export const FromDate = 'From Date';
export const ToDate = 'To Date';
export const ApplicationIntroduction = 'Application Introductory Text';
export const ServiceIntroduction = 'Service Introduction';
export const ServiceFields = 'Service Fields';
export const ServiceAttachments = 'Service Attachments';
export const ServiceReview = 'Review Service Application';
export const ServiceTOA = 'Terms OF Agreement For Service Application';
export const isMainAttach = 'Main Attachment';
export const lstAttacment = 'List Of Application Attacments';
export const AppFieldName = 'Application Field Name';
export const AppFieldValue = 'Application Field Value';
export const lstFields = 'List Of Application Fields And Values';
export const ViewContactList = 'View Company Contacts';
export const Contact = 'Contact';
export const ViewDownload = 'Download To View';
export const UploadEmployeeImage = 'Upload Employee Image';
///// TA Policy Page////


export const TAPolicy = "Attendance Policy"
export const TAPolicyBreak = "Policy Break"
export const TAPolicyViolations = "Policy Violations"
export const TAPolicyViolationActions = "Policy Violation Actions"
export const TAOvertimeTypes = "Overtime Types"
export const TAPolicySearch = "Attendance Policy Search"
export const TAPolicyBreakSearch = "Attendance Policy Break Search"
export const TAPolicyViolationsSearch = "Attendance Policy Violations Search"

export const TAPolicyName = 'Attendance Policy Name';
export const TAPolicyName_LL = 'Attendance Policy Name LL';
export const taPolicyengName = 'Attendance Policy Name';
export const taPolicyNameLL = 'Attendance Policy Name LL';
export const taPolicygracein = 'Schedule Start Grace (Minutes)';
export const taPolicygraceout = 'Schedule End Grace (Minutes)';
export const taPolicyDelayfromGrace = 'Cosider Delay only After Grace';
export const taPolicyEarlyOutIsFromGrace = 'Early Out Early out Only Until Grace';
export const taPolicyconsider_FILO_Only = 'Consider First In Last Out Only';
export const taPolicyConsiderAbsentIfNotCompleteNoOfHours = 'Consider Absent If Not Complete Number of Hours';
export const taPolicyNoOfNotCompleteHours = 'Number of Not Complete Hours';
export const taPolicyActive = 'Active';
export const taPolicyDefault = 'Default Attendace Policy';
export const taPolicyviolationName = 'Violation Name';
export const taPolicyviolationName_LL = 'Violation Name LL';
export const taPolicyNoAbsentDays = 'No. Of Absent Days';
export const taPolicyScenarioMode = 'Scenario Mode';
export const taPolicyConsideredPeriod = 'Considered Period';
export const taPolicyMoreThan = 'More Than';
export const taPolicyUpTo = 'Up To';

export const taminovertime = 'Minimum Overtime';
export const tamaxovertime = 'Maximum Overtime';
export const taovertimerate = 'Overtime Rate';
export const taPolicyCompensateToLeave = 'Compensate To Leave';
export const taPolicyMustRequested = 'Must Be Requested';
export const taPolicyApprovalRequired = 'Approval Required';
export const taPolicyMinAutoApproveDuration = 'Minimum Auto Approve Duration';
export const taPolicyAllowEditOverTime = 'Allow Edit Overtime';
export const otworkingdays = 'Working Day(s)';
export const otoffdays = 'Off Day(s)';
export const otholidays = 'Holiday(s)';
export const otreligionholidays = 'Religion Holiday(s)';
export const otLeaveType = "Leave Type";

export const taPolicyBreakType = "Break Type";
export const taPolicyAttendanceReason = "Related Attendance Reason";
export const taPolicy_BreakFlixible = "Flexible";
export const taPolicyFlexibleDuration = "Allowed Duration (Day)";
export const FromTime = "From Time";
export const ToTime = "To Time";
export const ToBeCompensated = "Break Time must be Compensated";
export const NoOfAllowedBreak = "Number Of Allowed Breaks";
export const BreakType = "Break Type";
export const BreakType_LL = "Break Type LL";

export const violationRuleType = "Violation Rule Type";
export const considerAbsent = "Consider Absent";

export const violationId = "Violation Id";
export const actionSequence = "Action Sequence";
export const fK_lkpViolationActionId = "LKP Violation Action Id";
export const HighTimeExists = "High Time Exists";
export const WorkDayOverTimeExists = "Work Day Over Time Exists";
export const OffDayOverTimeExists = "Off Day Over Time Exists";
export const HolidayOverTimeExists = "Holiday Over Time Exists";
export const ReligionHolidayOverTimeExists = "Religion Holiday Over Time Exists";


///// Correspondence////
export const CreateCorrespondence = 'Create Correspondence';
export const CorrespondenceList = 'Correspondence List';
export const CorrespondenceInfo = 'Correspondence Information';
export const RelatedCorrespondence = 'Related Correspondence ';
export const RelatedDocuments = 'Related Documents';
export const Attachments = 'Attachments';
export const ExternalAttachments = 'External Attachments';
export const History = 'History';
export const MetaData = 'Application Data';
export const Comments = 'Comments';
export const CorrespondenceParties = 'Correspondence Related Parties';
export const InternalContacts = 'Internal Contacts';
export const ExternalContacts = 'External Contacts';
export const CorrespondenceType = 'Correspondence Type';
export const CorrespondenceCategory = 'Correspondence Category';
export const Subject = 'Subject';
export const Priority = 'Priority';
export const Classification = 'Classification';
export const DueDate = 'Due Date';
export const ReplyDate = 'Reply Date';
export const SearchRelatedCorrespondence = 'Search Related Correspondence';
export const ListRelatedCorrespondence = 'List Of Related Correspondence';
export const CorrespondenceNumber = 'Correspondence Number';
export const CorrespondenceSubject = 'Correspondence Subject';
export const ViewCorrespondenceDetails = 'View Correspondence Details';
//Document Approval
export const ViewDocumentDetails = 'View Document Details';
export const SearchRelatedDocument = 'Search Related Document';
export const ListRelatedDocument = 'List Of Related Document';
export const DocumentNumber = 'Document Number';
export const AttachDoc = 'Attach Document';
export const AttachmentType = 'Attachment Type';
export const AttachmentName = 'Attachment Name';
export const AttachmentFormat = 'Attachment Format';
export const ListOfAttachment = 'List Of Attachment';
export const ListOfExternalContacts = 'List Of External Contacts';
export const AttachCorrespondence = 'Select Correspondence';
export const ListOfComments = 'List Of Comments';
export const CommentedBy = 'Commented By';
export const CommentedTime = 'Commented Date Time';
export const Comment = 'Comment';
export const PrivilligeEntity = 'Privillige Entity';
export const ManagerRequiredLevel = 'Manager Required Level';

/// WorkFlow Messages
export const DeleteStatusActionsMsg = 'Are you sure you want to delete Status Actions?';
export const DeletePriviligesMsg = 'Are you sure you want to delete Status Privilliges?';
export const DeleteEscalationMsg = 'Are you sure you want to delete Status Escalation(s)?';
export const AddRemainingDetailsOfStatus = 'Do You Want To Add the Remaining Details of Status?';
export const DeleteReasonMsg = 'Are you sure you want to delete Action Reason(s)?';
export const AddRemainingDetailsOfService = 'Do You Want To Add the Remaining Details of Service?';
export const TemplateTextin = 'Template Text in';
export const IntroductoryTextin = 'Introductory Text in';
export const TOATextin = 'TOA Text in';
export const NameInLocalLanguage = 'Name In Local Language';
export const Code = 'Code';
export const Abbreviation = 'Abbreviation';


//Library
export const DefineLibrary = 'Define Library';
export const LstLibrary = 'List Of Library';
export const AddNewLibrary = 'Add New Library';
export const LibraryName = 'Library Name';
export const LibraryName_LL = 'Library Name in Local Language';
export const Createdby = 'Created by';
export const CreatedDate = 'Created Date';
export const Lastupdatedby = 'Last updated by';
export const LastUpdatedDate = 'Last Updated Date';

export const Remarks_LL = 'Remarks in Local Language';
export const IsPersonalLibrary = 'Personal Library';
export const LibraryHeriarchy = 'Library Heriarchy';
export const DefineLibraryHeriarchy = 'Define Library Folder';
export const hierarchyCode = 'Hierarchy Code';
export const hierarchyName = 'Hierarchy Name';
export const hierarchy_LL = 'Hierarchy Name In Local Language';
export const ParentHeirarchyName = 'Parent Heirarchy Name';
export const Refersh = 'Refersh';
export const LibraryItem = 'Library Folder';
export const UploadFileToArchive = 'Upload File To Library Archive';
export const LockType = 'Lock Type';
export const DocumentTemplate = 'Document Template';
export const ViewDocument = 'View Document';
export const AddDocument = 'Add Document';
export const ViewFolder = 'View Folder';
export const AddFolder = 'Add New Folder';
export const FolderDetails = 'Folder Details';
export const LibraryItemName = 'Library Folder Name';
export const LibraryItemName_LL = 'Library Folder Name in Local Language';
export const BeforeSchedule = "Before Schedule"
export const AfterSchedule = "After Schedule"
export const BrowseLibrary = "Browse Library"
// Document

export const Preview = 'Preview';
export const GeneralInfo = 'General Info';
export const PreviousVersion = 'Previous Version';
export const Submit = 'Submit';

export const CorrespondenceText = 'Correspondence Text';
export const SecurityAndPrivilliges = 'Security And Privilliges';
export const ServiceArchivePolicy = 'Service Archive Policy';


// Audit Log
export const AuditLog = 'Audit Log';
export const ListOfPreviousActions = 'List Of Previous Actions';
export const actionDate = 'Action Date';
export const actionUserName = 'Action UserName';
export const currentStatusName = 'Current Status Name';
export const previousStatusName = 'Previous Status Name';

//Reports
//Creaete Report Page
export const AddReport = 'Add Report';
export const Report = 'Report';
export const ReportsCreation = 'Reports Creation';
export const SelectReport = 'Select Report';
export const ViewInPage = 'View In Page';
export const ExporttoPDF = 'Export to PDF';
export const ExporttoWord = 'Export to MS-Word';
export const ListofReports = "List of Reports";
export const ReportCategory = 'Report Category';
export const ReportName = 'Report Name';
export const ReportNameLL = 'Report Name LL';
export const ProcedureName = 'Procedure Name';
export const ReportType = 'Report Type';
export const Data = 'Data';
export const Chart = 'Chart';
export const Layout = 'Layout';
export const Portrait = 'Portrait';
export const Landscape = 'Landscape';
export const DocumentsChart = 'Documents Chart';
//Report Parameters
export const ReportParameters = 'Parameters'
export const ParameterCaption = 'ParameterCaption';
export const ParameterCaptionLL = 'ParameterCaptionLL';
export const DefaultValue = 'Default Value';
export const Required = 'Required';
//Report Columns
export const ReportColumns = 'Columns';
export const ControlType = 'Control Type';
export const ColumnDisplay = 'Column Display';
export const ColumnDisplayLL = 'Column Display LL';
export const ColumnWidth = 'Column Width';
export const Visible = 'Visible';
export const CopyDocument = 'Copy Document';
export const CurrentlyAssigned = 'Currently Assigned';
export const ApplicationTimeLine = 'Application TimeLine';
export const ApplicationDetails = 'Application Details';
export const Agreement = 'I agree with Terms and Conditions of Application';
export const ApplicationUser = 'Application User';
export const AppliedBy = 'Applied By';
export const FilterByCategory = 'Filter By Service Category';
export const Document = 'Document';
export const LibraryPrivilleges = 'Library Privilleges';

export const AllowDelete = 'Allow Delete';
export const AllowNewVersionUpload = 'Allow New Version Upload';
export const AllowSignature = 'Allow Signature';
export const AllowDownload = 'Allow Download';
export const AllowBarcode = 'Allow Barcode';
export const AllowShare = 'Allow Share';
export const AllowView = 'Allow View';
export const IsManualArchive = 'Is Manual Archive';
export const LibraryFoArchive = 'Library For Archive';
export const ArchiveToHierarchy = 'Archive To Hierarchy';
export const Date = 'Date';
export const InternalContactType = 'Internal Contact Type';
export const InternalContactEntity = 'Internal Contact Entity';
export const Summary = 'Summary';
export const Return = 'Return';
export const AssignManual = 'Assign Manual';
export const Internal = 'Internal';
export const External = 'External';
export const AllowAttachmentUpload = 'Allow Attachment Upload';
export const AllowAttachmentDownload = 'Allow Attachment Download';
export const ListofTemplate = 'List of Document Templates'
export const AddNewItem = 'Add New Folder';
export const AssignManually = 'Assign Manually';
export const AssignWorkflow = 'Assign According to WorkFlow';
export const MoveToLibrary = 'Move To Library';
export const MoveAppToLibrary = 'Move Application Documents To Library';
export const Version = 'Version';
export const Minor = 'Minor';
export const Major = 'Major';

// Holiday
export const HolidayTitle = "Holiday"
export const HolidaySearch = "Holiday Search"
export const HolidayName = 'Holiday Name';
export const HolidayNameLL = 'Holiday Name Local Language';
export const YearlyFixed = 'Yearly Fixed';
export const StartDate = "Start Date";
export const EndDate = "End Date";
export const AllCompanies = "All Companies";
export const SpecificCompany = "Specific Company(s)";
export const AllWorkLocation = "All WorkLocations";
export const SpecificWorkLocation = "Specific Work Location(s)";
export const AllReligions = "All Religions";
export const SpecificReligion = "Specific Religion(s)";
export const AllEmployeeTypes = "All Employee Type(s)";
export const SpecificEmployeeType = "Specific Employee Type(s)";
export const AllWorkGroup = "All Work Groups(s)";
export const SpecificWorkGroup = "Specific Work Group(s)";
export const StartDay = "Start Day";
export const EndDay = "End Day";
export const WorkLocations = "Work Location(s)";
export const Religions = "Religion(s)";
export const EmployeeTypes = "Employee Type(s)";
export const WorkGroups = "Work Group(s)";
export const OrganizationUnit = "Organization Unit";
export const HolidayWorkLocation = "Holiday WorkLocation";
export const HolidayEmployeeTypes = "Holiday Employee Types";
export const HolidayWorkGroup = "Holiday Work Group";
export const HolidayReligion = "Holiday Religion";


export const Actions = "Actions";
export const SelectAction = "Select Action";
export const AssignmentType = "Assignment Type";
export const Content = "Content";
export const LstMetadata = "List Of Application Data Values";
export const Label = "Label";
export const Value = "Value";
export const Type = "Type";
export const Cancel = "Cancel";
export const lstAssignedDocuments = "Assigned Documents";
export const lstMyDocuments = "List Of My Document Applications";
export const TemplateName = "Template Name";
export const DocumentStatus = "Document Status";
export const DocumentDate = "Document Date";
export const RecentUploadDocument = "Recent Uploaded Documents";
export const RecentViewDocuments = "Recent Viewed Documents";
export const AccessOptions = "Access Options";
export const ViewPermission = "View Permission";
export const LibraryPrivilliges = "Library Privilliges";
export const ConfirmDeleteLibraryPrivilliges = "Please Confirm If you want to delete Library Privilliges";
export const DeleteSuccessLibraryPrivilliges = 'Library Privilliges have been deleted successfully.';
export const DeleteErrorLibraryPrivilliges = 'Error while deleting Library Privilliges.';
export const SaveSuccessLibraryPrivilliges = 'Library Privilliges Have Been Saved Successfully.';
export const SaveErrorLibraryPrivilliges = 'Error while saving Library Privilliges.';
export const LibrarySaveSuccess = 'Library Details Have Been Saved Successfully.';
export const LibrarySaveError = 'Library Details cannot be Saved Successfully.';
export const Library = 'Library';
export const ItemCode = 'Folder Code';
export const ItemName = 'Folder Name';
export const ItemName_LL = 'Folder Name in Local Language';
export const ErrorLoadingLibrary = 'Some Error Occurred in loading Library Structure';
export const WorkFlowStatus = 'WorkFlow Status';
export const AddWorkFlowStatus = 'Add New  Status';
export const Close = 'Close';
export const WorkflowDefinition = 'Workflow Definition';
export const IsAllVersions = 'Is All Versions';
export const IsTemporary = 'Is Temporary';
export const ShareDocument = 'Share Document';
export const ConfirmDeleteLibraryShare = "Please Confirm If you want to delete Document Share";
export const DeleteSuccessLibraryShare = 'Document Share have been deleted successfully.';
export const DeleteErrorLibraryShare = 'Error while deleting Document Share.';
export const ShareSaveSuccess = 'Document Share Have Been Saved Successfully.';
export const ShareSaveError = 'Document Share Details cannot be Saved Successfully.';
export const RenameDocument = 'Rename Document';
export const MoveDocument = 'Move Document';
export const SearchMyApp = 'My Applications';
export const ReAssignApp = 'ReAssign Applications';
export const ReAssignApplication = 'ReAssign Applications';
export const ReAssign = 'ReAssign';
export const LstOfRemarkFiles = 'List Of Remark Files';
export const LstOfAppDoc = 'List Of Previous Documents';
export const documentFormat = 'Document Format';
export const documentVersion = 'Document Version';
export const documentFile = 'Document File';
export const PreviousDocuments = 'Previous Documents';

export const ActionCheckList = 'Action Check Lists';
export const CheckListName = 'Check List Name';
export const CheckListName_LL = 'Check List Name in Local Language';
export const CheckListOrder = 'Check List Order';
export const DeleteCHeckListMsg = 'Are You Sure You want to Delete Check List(s)';
export const DocumentView = 'Document View';
export const ContentType = 'Content Type ';
export const Extension = 'Extension';
export const FileSize = 'File Size';
export const ModifiedBy = 'Modified By';
export const ModifiedDate = 'Modified Date';
export const LstPreviousVersions = 'List Of Previous Versions';
export const DocumentLog = 'Document Log';
export const Actor = 'Actor';
export const Action = 'Action';
export const Time = 'Time';

//Assign TA Policy
export const AssignTAPolicy = "Assign Attendance Policy";
export const OrganaizationUnit = "Organaization Unit";
export const TAPolicyOrgUnit = "Attendance Policy Organaization Unit";
export const TAPolicyAssignOrgUnit = "TA Policy Assignment Organization Unit";
export const AssignTAPolicyOrgUnitSearch = "Organization Unit Attendance Policy Search";

export const TAPolicyEmployee = "Attendance Policy Employee";
export const TAPolicyAssignEmployee = "TA Policy Assignment Employee";
export const AssignTAPolicyEmployeeSearch = "Attendance Policy Employee Search";

export const TAPolicyWorkGroup = "Attendance Policy Work Group";
export const TAPolicyAssignWorkGroup = "TA Policy Assignment Work Group";
export const AssignTAPolicyWorkGroupSearch = "Attendance Policy Group Search";

export const TAPolicyWorkLocation = "Attendance Policy Work Location";
export const TAPolicyAssignWorkLocation = "TA Policy Assignment Work Location";
export const AssignTAPolicyWorkLocationSearch = "Attendance Policy Work Location Search";

export const TAPolicyAssignmentSearch = "Attendance Policy Assignment Search";
export const EmployeeExistsInSameDate = "Employee Exists In the Same Date";
export const WorkGroupExistsInSameDate = "Work Group Exists In the Same Date";
export const WorkLocationExistsInSameDate = "Work Location Exists In the Same Date";
export const OrgUnitExistsInSameDate = "Organization Unit Exists In the Same Date";

export const assignmentValueName = "Assignment Name";
export const assignmentValueName_LL = "Assignment Name Local Language";
export const assignmentTypeName = "Assignment Type";
export const assignmentTypeName_LL = "Assignment Type Local Language";

//Assign Work Schedule
export const AssignWorkScheduleTitle = "Assign Work Schedule";
export const AssignWorkScheduleSearch = "Work Schedule Assignment Search";

export const WorkScheduleOrgUnit = "Work Schedule Organaization Unit";
export const WorkScheduleAssignOrgUnit = "Work Schedule Assignment Organization Unit";
export const AssignWorkScheduleOrgUnitSearch = "Organization Unit Work Schedule Search";

export const WorkScheduleEmployee = "Work Schedule Employee";
export const WorkScheduleAssignEmployee = "Work Schedule Assignment Employee";
export const AssignWorkScheduleEmployeeSearch = "Work Schedule Employee Search";

export const WorkScheduleWorkGroup = "Work Schedule Work Group";
export const WorkScheduleAssignWorkGroup = "Work Schedule Assignment Work Group";
export const AssignWorkScheduleWorkGroupSearch = "Work Schedule Group Search";

export const WorkScheduleWorkLocation = "Work Schedule Work Location";
export const WorkScheduleAssignWorkLocation = "Work Schedule Assignment Work Location";
export const AssignWorkScheduleWorkLocationSearch = "Work Schedule Work Location Search";


//ViolationException
export const ViolationExceptionTitle = "Violation Exception"
export const ViolationException = "Violation Exception";
export const ViolationExceptionSearch = "Violation Exception Search";

export const ViolationExceptionOrgUnit = "Violation Exception Organization Unit";
export const ViolationExceptionOrganaizationUnitSearch = "Violation Exception Organaization Unit Search";

export const ViolationExceptionEmployee = "Violation Exception Employee";
export const ViolationExceptionEmployeeSearch = "Violation Exception Employee Search";

export const ViolationExceptionWorkGroup = "Violation Exception Work Group";
export const ViolationExceptionWorkGroupSearch = "Violation Exception Work Group Search";

export const ViolationExceptionWorkLocation = "Violation Exception Work Location";
export const ViolationExceptionWorkLocationSearch = "Violation Exception Work Location Search";

//TAException 
export const TAException = "TA Exception";
export const TAExceptionSearch = "TA Exception Search";

export const TAExceptionOrgUnit = "TA Exception Organization Unit";
export const TAExceptionOrganaizationUnitSearch = "TA Exception Organaization Unit Search";

export const TAExceptionEmployee = "TA Exception Employee";
export const TAExceptionEmployeeSearch = "TA Exception Employee Search";

export const TAExceptionWorkGroup = "TA Exception Work Group";
export const TAExceptionWorkGroupSearch = "TA Exception Work Group Search";

export const TAExceptionWorkLocation = "TA Exception Work Location";
export const TAExceptionWorkLocationSearch = "TA Exception Work Location Search";

//Ramadan Period
export const RamadanPeriod = "Ramadan Period";
export const RamadanPeriodSearch = "Ramadan Period Search";

//Library Tree
export const NewDocument = "New Document";
export const NewItem = "New Folder";
export const TotalDocuments = "Total Documents"
export const MyDocuments = "My Documents"
export const PendingDocuments = "Pending Documents"
export const SharedDocuments = "Shared Documents"
export const DocumentApplication = "Document Application"
export const NewApplicationDocument = "New Application Document"
export const AppliciationNumber = "Appliciation Number"
//Assign Managers
export const ManagerName = "Manager Name"
export const ManagerNameLL = "Manager Name Local Language"
export const AssignManagersTitle = "Assign Managers"
export const AssignManagerSearch = "Manager Assignment Search";
export const ManagerOrgUnit = "Manager Organaization Unit";
export const AssignManagerOrgUnitSearch = "Organization Unit Manager Search";
export const ManagerEmployee = "Manager Employee";
export const AssignManagerEmployeeSearch = "Manager Employee Search";
export const ManagerWorkGroup = "Manager Work Group";
export const AssignManagerWorkGroupSearch = "Manager Group Search";
export const ManagerWorkLocation = "Manager Work Location";
export const AssignManagerWorkLocationSearch = "Manager Work Location Search";


// Leave Types Attachment Type 
export const LeaveTypeAttachType = "Leave Type Attachment Type";
export const LeaveTypeAttachTypeSearch = "Leave Type Attachment Type Search";
export const attachmentTypeName = "Attachment Type Name";
export const attachmentTypeNameLL = "Attachment Type Name Local Language";
//home
export const BusinessProcessApplications = "Business Process Applications";
export const CorrespondenceApplications = "Correspondence Applications";
export const RecentlyViewedApplications = "Recently Viewed";
export const ApplicationTime = "Application Time";
export const PreviousDocumentVersion = "This is Old Document Version, can view latest version by closing the window.";
export const ApplicationsStatistics = "Applications Statistics";
export const YouHave = "You Have";
export const Tasks = "Task(s)";
export const OpenDashb = "Open";

// application document
export const PreviewUpload = "Preview Uploaded Document";
export const New = "New";
export const Existing = "Existing";
export const Copy = "Copy";
export const AddNewVersion = "Add New Version";
export const Modify = "Modify";
export const Digitalsign = "Digital sign";
export const Move = "Move";
export const DeleteVersion = "Delete Version";
export const Rename = "Rename";
export const Download = "Download";
export const DownloadAll = "Download All versions";
export const Share = "Share";
export const SharewithworkGroup = "Share with work Group ";
export const Sharewithorgunit = "Share with org unit";
export const SharewithSecurityGroup = "Share with Security Group";
export const ShareWithuser = "Share With user";

export const FormAccess = "Form Access";
export const EditAccessStatus = "Edit Access Status";
export const VisibleAccessStatus = "Visibility  Status";
export const HiddenAccessStatus = "Hidden Status";
export const DisableAccessStatus = "Read Only Status";
export const Disable = "Disable";
export const None = "None";
export const ChangeEditAccess = "Change Edit Access";
export const ChangeVisibilityAccess = "Change Visibility Access";
export const ChangeAccess = "Change Access";
export const AccessForIndividual = "Access For Individual";
export const AccessForCompany = "Access For Company ";
export const AccessForInternal = "Access For Internal ";
export const ApplyApplication = "Apply Application";
export const MyApplications = "My Applications";
export const ViewMyApplications = "View My Applications";
export const ViewPendingApplication = "View Pending Applications";
export const Individual = "Individual";
export const Personal = "Personal";
export const Applicant = "Applicant";
export const ApplicantName = "Applicant Name";
export const ApplicantName_LL = "Applicant Name Local Language";
export const SelectCompanyOrIndividual = "Please Select Individual Or Company, for which you want to apply application";
export const ServiceCompanyListMsg = "This Service is only allowed for Business Company, you don't have any companies registered. Please Contact Help Desk.";
export const IndividualMsg = "This Service is Not Allowed For Individuals.";
export const NoCompanyMsg = "You Don't have any company resgitered.";

export const AllApplications = "All Applications";
export const ClosedApplications = "Closed Applications";
export const PendingApplications = "Pending Applications";
export const InProgressApplications = "In Progress Applications";
export const ApplyApplications = "Apply Applications";

export const ApprovalOption = "Approval Option";
export const ManagerOnly = "Manager Only";
export const AllMemebers = "All Memebers";
export const SpeccificNumberOfMemebers = "Specific Number Of Memebers";
export const AddNewWorkFlow = "Add New WorkFlow";
export const AddActions = "Add Actions";
export const ChooseFile = "Choose File";
//export const AddEscalation = "AddActions";
export const DocumentNumberFormula = "Document Number Formula";
export const DefineOutputTemplate = "Define Output Document Template";
export const AllowedStatus = "Allowed Status";
export const IsEmail = "Is Email";
export const IsDownload = "Is Download";
export const AddStatus = "Add Status";
export const AddStatusPrivileges = "Add Allowed Status And Privileges";
export const OutputTemplate = "Output Document Template";
export const LstOutputTemplatePrivileges = "List Of Output Document Template Privileges";
export const MetaDataFields = "MetaData Fields";
export const Parameters = "Parameters";
export const ApiName = "Api Name";
export const ApiDesc = "Api Description";
export const ApiUrl = "Api Url";
export const RequestType = "Request Type";
export const IsBeforeStatus = "Is Before Status";
export const IfErrorStopProcess = "If Error Stop Process";
export const AddInputParams = "Input Parameters";
export const AddOutputParams = "Output Parameters";
export const ParameterCategory = "Parameter Category";
export const ParameterSource = "Parameter Source";
export const ParameterValue = "Parameter Value";
export const ParameterKey = "Parameter Key";
export const LstParameter = "List Of Input Parameters";
export const LstApi = "List Of API";
export const ServiceApi = "Service API";
export const DeleteApiMsg = "Are you sure if you want to delete selected Api;";
export const VaidateDeleteApiMsg = "Please Selecct Api from table below to delete";
export const LstOutPutParameter = "List Of Output Parameters";

export const IsServiceTemporary = "Is Service Temporary";
export const ServiceStartDate = "Service Start Date";
export const ServiceStartTime = "Service Start Time";
export const ServiceEndDate = "Service End Date";
export const ServiceEndTime = "Service End Time";
export const IndividualServicePrivilliges = "Individual Group Privilliges";
export const showOnReviewOnly = "Show On Review Only";
export const isMultiRecords = "Multi Records Panel";
export const ShowInGrid = "Show control in Grid";
export const FieldList = "List Of Fields To be Shown in Grid";
export const FieldDataList = "List Of Fields Data";
export const ServiceDetails = "Service Details";
export const CRMPrivilliges = "CRM Privilliges";
export const InternalPrivilliges = "Internal Privilliges";
export const DynamicServiceApi = "Dynamic Service Api";
export const AddRemainingDetailsOfApi = "Do you want to Add Remaining Details Of Api";

export const FormTemplateRules = "Form Template Rules";
export const RuleName = "Rule Name";
export const ConditionField = "Condition Field";
export const Condition = "Condition";
export const ConditionValue = "Condition Value";
export const ResultField = "Result Field";
export const ResultFieldProperty = "Result Field Property";
export const ResultFieldValue = "Result Field Value";
export const ListOfRules = "List Of Rules";
export const Designer = "Designer";
export const Rules = "Rules";
export const DefineRules = "Define Rules";
export const ServiceFormRules = "Service Form Rules";

//Contact
export const DefineContact = 'Define Contact';
export const ContactInformation = 'Contact Information';
export const ContactSearch = 'Contact Search';
export const ContactCompanies = 'Contact Companies';
export const CreateAccount = 'Create Account';
export const ContactName = 'Contact Name';
export const ContactName_LL = 'Contact Name in Local Language';
export const DocUniqueNo = 'Document Unique No.';
export const DocUniqueNoType = 'Document Type';
export const Privileges = 'Privileges';
export const ParentContact = 'Parent Contact';
export const Main = 'Main';
export const Account = 'Account';
export const ResetPassword = 'Reset Password';
export const ContactAssets = 'Contact Assets';
export const ContactSubscriptions = 'Contact Subscriptions';
export const ContactAssetsSearch = 'Contact Assets Search';
export const ChildContact = 'Child Contact';
export const ChildContacts = 'Child Contact(s)';
export const ViewChildContacts = 'View Child Contact(s)';
export const ExternalContact = 'External Contact';

//Contact Assets
export const AssetType = 'Asset Type';
export const AssetNo = 'Asset No.';
export const AssetName = 'Asset Name';
export const AssetNameLL = 'Asset Name LL';
export const IssueDate = 'Issue Date';
export const ExpiryDate = 'Expiry Date';

//Contact Subscription
export const SubscriptionType = 'Subscription Type';
export const SubscriptionNo = 'Subscription No.';
export const Permanent = 'Permanent';
export const Renew = 'Renew';
export const Paid = 'Paid';
export const ContactSubscriptionsTitle = 'Contact Subscriptions';
export const SubscriptionsSearch = 'Subscriptions Search';

//External Privilege Group
export const External_privilegeGroupTitle = 'External Privilege Group';
export const External_privilegeGroupSearch = 'External Privilege Group Search';
export const GroupTypeName = 'Group Type Name';
export const GroupTypeName_LL = 'Group Type Name Local Language';

//Asset Types
export const AssetsTypesTitle = 'Assets Types';
export const AssetsTypesSearch = 'Assets Types Search';
export const assetTypeName = 'Type Name';
export const assetTypeName_LL = 'Type Name Local Language';
export const assetNoCaption = 'Asset No. Caption';
export const assetNoCaption_LL = 'Asset No. Caption Local Language';
export const HasIssueDate = 'Has Issue Date';
export const HasExpiryDate = 'Has Expiry Date';
export const HasWorkFlow = 'Has Work Flow';
export const WorkFlow = 'Work Flow';
export const HasTemplate = 'Has Template';

//Subscriptions Types
export const SubscriptionsTypeTitle = 'Subscriptions Types';
export const SubscriptionsTypeSearch = 'Subscriptions Types Search';
export const subscriptionTypeCode = 'Subscription Type Code';
export const subscriptionTypeName = 'Subscription Type Name';
export const subscriptionTypeName_LL = 'Subscription Type Local Language';
export const SubscriptionNoFormula = 'Subscription No. Formula';
export const CardColor = 'Card Color';
export const CardIcon = 'Card Icon';

//Subscription Type Features
export const SubscriptionTypeFeaturesTitle = 'Subscription Type Features';
export const SubscriptionsFeaturesTypeSearch = 'Subscription Type Features Search';
export const Feature = 'Feature';
export const DurationType = 'Duration Type';
export const Duration = 'Duration';
export const Included = 'Included';
export const Chargable = 'Chargable';
export const Currancy = 'Currancy';
export const Fees = 'Fees';
export const Discount = 'Discount';
export const FeatureName = 'Feature Name';
export const FeatureName_LL = 'Feature Name Local Language';

//Subscription Type Duration
export const SubscriptionTypeDurationTitle = 'Subscription Type Duration';
export const SubscriptionsTypeDurationSearch = 'Subscription Type Duration Search';
export const DurationName = 'Duration Name';
export const DurationName_LL = 'Duration Name Local Language';
export const AdultSubscriberNo = 'Adult Subscriber No.';
export const ChildSubscriberNo = 'Child Subscriber No.';
export const AdditionalAdultFees = 'Additional Adult Fees';
export const AdditionalChildFees = 'Additional Child Fees';


//New
export const NoActionForStatus = 'There are no Actions for this Status.';
export const DeleteStatus = 'Delete Status';
export const DeleteAction = 'Delete Action';
export const DeleteOutUptDocumentMsg = 'Are you sure you want to delete OutPut Document ?';
export const DeleteOutUptDocumentPrivMsg = 'Are you sure you want to delete OutPut Document Status Privileges?';
export const DeleteStatusMsg = 'Status can only be deleted is it is not used in Application Flow. Are you sure you want to delete Status ?. Deleting Status will delete all the Status Actions, Escalation, Reasons.';
//Form Template
export const Sort = 'Sort';
export const EditPanel = 'Edit Panel';
export const Templates = 'Templates';
export const FormVisibility = 'Form Visibility';
export const FormRules = ' Form Rules';
export const OutputDocuments = 'Output Documents';
export const APIintegration = 'API integration';
export const FormCustomization = 'Form Customization';
export const Field = 'Field';
export const Control = 'Control';

export const EmailStatus = 'Email Status';
export const DownloadStatus = 'Download Status';
export const OutDocumentName = 'Out Document Name';
export const OutDocumentName_LL = 'Out Document Name Local Language';
export const ConditionFieldConditionValue = 'Condition Field Condition Value';
export const AddWorkFlow = 'Add WorkFlow';
export const DeleteWorkFlowMsg = 'Are You Sure You Want to Delete Workflow? Deleting workflow will delete status and actions. WorkFlow cannot be deleted incase it is used in Application Process.';
export const Publish = 'Publish';
export const EscalationRules = 'Escalation Rules';
export const StatusafterAction = 'Status after Action';
export const ActionReasons = 'Action Reasons';
export const Mid = 'In Between Status';
export const OtherStatus = 'Status For Other Reason';
export const SearchWorkFlow = 'Search WorkFlow';
export const SearchService = 'Search Service';
export const PublishService = 'Publish Service';
export const ServiceDocumentSecurity = 'Service Document Security';
export const AutomaticArchive = 'Automatic Archive';
export const ArchiveOption = 'Archive Option';
export const PublishStatus = 'Publish Status';
export const PublishDate = 'Publish Date';
export const ServiceAllowed = 'Service Allowed';
export const AllowedForExternalContacts = 'Allowed For External Copmanies';
export const AllowedForIndividuals = 'Allowed For Individuals';
export const AllowedForInternal = 'Allowed For Internal';
export const PublishedOnWeb = 'Published On Web';
export const PublishedOnMobile = 'Published On Mobile';
export const ServiceMsg = 'Please Select and Save Service Details To Open this Tab.';
export const ServiceWithFormAndWorkFlowMsg = 'Please Select and Save Service Details with Form Template And WorkFlow To Open this Tab.';
export const MandatoryAttachmentPending = 'Mandatory Attachments Are Pending';
export const Applicationsneedaction = 'Applications need action';
export const MyApp = ' My Applications';
export const PaginationRecords = 'Showing {first} to {last} of {totalRecords} entries';
export const ListMyApplications = 'List Of My Applications';
export const ListAssignedApplications = 'List Of Assigned Applications';
export const PrivilegeType = 'Privilege Type';
export const DownloadOutPutDocument = 'Download Document';
export const servicecolor = 'Service Color';
export const serviceicon = 'Service Icon';
export const AddNewForm = 'Add New Form';
export const FieldControl = 'Field Control';
export const DeleteDefinition = 'Delete Definition';

//Portal Subscribe
export const PersonalInformation = 'Personal Information';
export const SubContactInformation = 'Sub Contact Information';
export const Features = 'Features';
export const Payment = 'Payment';
export const Confirmation = 'Confirmation';
export const FinishSubscription = 'Your Subscription Have Been Successfully Saved Your Subscription Number Is:';
export const MembershipDuration = 'Membership Duration';

//Sub Contacts
export const SubContacts = 'Sub Contacts'

//Additional Features

export const AdditionalFeatures = "Additional Features"
export const SubscriptionTypeFeature = "Subscription Type Feature"
export const Price = "Price"
export const TotalPayment = "Total Payment"
export const CardNumber = "Card Number"
export const CardCIV = "CIV"


//Subscription Info

export const SubscriptionInfo = "Subscription Info"
export const Subscribers = "Subscribers"
export const Subscription = "Subscription"
export const Subscribe = "Subscribe"

export const Maximize = "Maximize"
export const Minimize = "Minimize"
export const SignOut = "Sign Out"
export const Portal = "Portal"
export const NotPublishedOnWeb = 'Not Published On Web';
export const NotPublishedOnMobile = 'Not Published On Mobile';
export const AllowedFor = 'Allowed For';
export const InternalUsers = 'Internal Users';
export const PortalUsers = 'Portal Users';
export const ShowAll = 'Show All';
export const Introduction = 'Introduction';
export const ApplicationInformation = 'Application Information';
export const Attachment = 'Attachment';
export const Finalize = 'Finalize';
export const Service = 'Service';
export const Status = 'Status';
export const Details = 'Details';
export const DeleteServiceMsg = 'Service can only be deleted if it is not used in Application Flow or any information is added related to service componenents. Are you sure you want to delete Service ?';
export const PublishForm = 'Publish Form Template';
export const UnPublish = 'UnPublish';
export const Assignment = "Assignment";
export const EditSecurity = "Edit Security";
export const AddIntroduction = "Add Introduction";
export const EditIntroduction = "Edit Introduction";

export const AddTOA = "Add TOA";
export const EditTOA = "Edit TOA";

export const DeleteMsgItnro = "Are You Sure You want to delete Service Introduction";
export const DeleteMsgTOA = "Are You Sure You want to delete Service TOA";
export const TemplatesAndAttachments = "Templates And Attachments";
export const SaveAsDraft = "Save As Draft";

///VMS
export const CheckInVisitor = "Check In Visitor";
export const CheckOutVisitor = "Check In Visitor";
export const IsOneTimeVisit = "One Time Visit";
export const RepeatingVisits = "Repeating Visits";
export const VisitStartDate = "Visit Start Date";
export const VisitEndDate = "Visit End Date";
export const VisitStartTime = "Visit Start Time";
export const VisitEndTime = "Visit End Time";
export const VisitDays = "Visit Days";
export const MeetingRoom = "Meeting Room";
export const CateringType = "Catering Type";
export const CateringPlace = "Catering Place";
export const NoOfPeople = "Number Of People";
export const VehicleNo = "Vehicle Number";
export const VehicleCategory = "Vehicle Category";
export const VehicleSource = "Vehicle Source";
export const VehicleColor = "Vehicle Color";
export const AssestDetails = "Asset Details";
export const AssestNo = "Asset Number";
export const AssestAllowedDirection = "Assest Allowed Direction";
export const LstAssets = "List Of Assets";
export const LstVehicle = "List Of Vehicle";
export const SearchAssets = "Search Assets";
export const SearchVehicle = "Search Vehicle";
export const SearchParticipants = "Search Participants";
export const LstParticipants = "List Of Participants";
export const VisitInfo = "Visit Info";
export const VisitParticipants = "Visit Participants";
export const VisitAssets = "Visit Assets";
export const VisitVehicles = "Visit Vehicles";
export const CheckIn = "Check In Visitors";
export const LstCheckIn = "List Of Application to Check In";
export const VisitorCardNo = "Visitor Card No";
export const ExpectedVisitStartDate = "Expected Visit Start Date";
export const ExpectedVisitEndDate = "Expected Visit End Date";
export const CheckOutVisitorsMsg = "Are You Sure You want To Check Out Selected Visitors";
export const CheckOut = "Check Out Visitors";
export const LstCheckout = "List Of Visitors/Application to Check Out";
export const Redirect = "Redirect";
export const AddAssets = "Add Assets";
export const EditAssets = "Edit Assets";
export const AddVehicles = "Add Vehicles";
export const EditVehicles = "Edit Vehicles";
export const performactionmsg = 'Are you sure to perform this action?';
export const AddParticipants = "Add Participants";
// Service Members
export const PictureDescription = "Picture Description";
export const PictureDescription_LL = "Picture Description in Local Language";
export const UploadPicture = "Upload Picture";
export const isFeaturePicture = "Is Feature Picture";
export const LstPictures = "List OF Service Pictures.";
export const PictureFormat = "Picture Format";
export const ServicePictures = "Service Pictures";
export const allowedSlots = "Total Slots";
export const earnedPoints = "Earned Points";
export const requiredPoints = "RequiredPoints";
export const ServiceLocation = "Service Location";
export const isChargeable = "Is Chargeable";
export const serviceFee = "Service Fee";
export const Currency = "Currency";
export const ServiceDuration = "Service Duration";
export const DurationUnit = "Duration Unit";
export const ServiceBooking = "Service Booking";
export const ServiceSchedule = "Duration Schedule";
export const MemberShipInfo = "MemberShip Info";
export const totalSessionNo = "Total Session No";
export const requiredSessionNo = "Required Session No";
export const isRepeated = "Multi Sessions";
export const ScheduleTime = "Schedule Date /Time";
export const Day = "Day";
export const TimeDuration = "Time Duration";
export const lstScheduleTime = "List of Schedule Time";
export const CheckInTime = "Check In Time";
export const CheckOutTime = "Check Out Time";
export const AllowSequenceSlots = "Allow Sequence Slots";
export const IsfullDay = "Is full Day";
export const IsFlexible = "Is Flexible";
export const Hours = "Hours";
export const StartTime = "Start Time";
export const EndTime = "End Time";
export const TimeSlots = "Time Slots";

// Applications Invoice
export const Applications_InvoiceTitle = "Applications Invoice";
export const Applications_InvoiceSearch = "Applications Invoice Search";
export const InvoiceNumber = "Invoice Number";
export const InvoiceDate = "Invoice Date";
export const Approved = "Approved";
export const Amount = "Amount";
export const Print = "Print";
export const ApplicantInfo = "Applicant Information";
export const InvoiceDetails = "Invoice Details";

//Manual Transactions
export const ManualTransactionTitle = "Attendance Transactions";
export const ManualTransactionSearch = "Attendance Transactions Search";
export const MoveDate = "Move Date";
export const MoveTime = "Move Time";
export const ClearFilter = "Clear Filter";
export const SearchTransaction = "Search Transaction";
export const SingleEmployee = "Single Employee";
export const MultipleEmployees = "Multiple Employee(s)";
export const AllowedNumberofManualEntryPerDay = "Record Couldn`t Be Added, The Allowed Number of Manual Entry(s) Per Day Is: ";
export const AllowedNumberofManualEntryPerMonth = "Record Couldn`t Be Added, The Allowed Number of Manual Entry(s) Per Month Is: ";
export const Times = " Time(s).";

//Manual Transactions Request
export const ManualTransactionRequestTitle = "Attendance Transaction Request";
export const ManualTransactionRequestSearch = "Attendance Transactions Request Search";

// Application
export const ServiceInfo = "Service Info"
export const ApplicationInfo = "Application Info"
export const Attended = "Attended"
export const FullDayService = "Service is for Full Day, Check Out time and Check In time are mentioned below. Please select your dates."

export const FlexibleService = "Service is has Flexible time, please select your Date and Time. Max hours For this service are "

export const TimeSlotService = "Service Has Time Slots, please select suitable slot for you."
export const RequiredNoOfSlots = "Required Number"
export const StartDateTime = "Start Date Time";
export const EndDateTime = "End Date Time";
export const TimeDurationMins = "Time Duration (Minutes)";
export const TotalDays = "Total Days";

// Schedule Attendence
export const SearchAttendence = "Search and Mark Attendence";
export const SessionDate = "Session Date";
export const AttendenceStatus = "Attendence Status";
export const SearchApplication = "Search Application";
export const MarkPresent = "Mark Present";
export const MarkAbsent = "Mark Absent";
export const CheckInApplicant = "Check In Applicant";
export const CheckOutApplicant = "Check Out Applicant";
export const SubControlType = "Control Sub Type";

//Panel Access
export const PanelHiddenStatus = "Panel Hidden Status";
export const PanelDisableStatus = "Panel Disable Status";
export const Draft = "Draft";
export const LstDraftApplications = "Saved/Draft Applications";
export const DraftDurationApplications = "Saved/Draft Duration Applications";
export const DraftBookingApplications = "Saved/Draft Duration Applications";
export const DraftProcessApplications = "Saved/Draft Digital Process Applications";
export const DraftSessionApplications = "Saved/Draft Session Applications";

export const DefineSecurityGroup = "Define Security Group";
export const DefineUsers = "Define Users";
export const LstEmployeeWorkGroup = 'List Of Employee Work Group'

export const ContactDocumentDelete = 'Are you sure you want to delete contact document.'

//Employee Leaves
export const EmployeeLeaves = "Employee Leaves";
export const EmployeeLeavesSearch = "Employee Leaves Search";
export const FullDay = "Full Day";
export const Remaining = "Remaining";
export const Characters = "Character(s)";
export const Hourly = "Hourly";
export const Daily = "Daily";
export const LeaveAttachments = "Leave Attachment(s)";
export const FromDateValidation = "To Date Should be Greater Than or Equal From Date";
export const FromTimeValidation = "To Time Should be Greater Than or Equal From Time";
export const DaySelectValidation = "Please Select At Least One Day From the List";

export const LeaveTypenotAllowedInRamadan = "Leave Type Is not Allowed In Ramadan";
export const LeaveExistsSameTypeandPeriod = "Leave Exists With Same Type and Period";
export const LeaveExistsDifferentTypeandPeriod = "Leave Exists With Different Type and Period";

export const LeaveRequestExistsSameTypeandPeriod = "Leave Request Exists With Same Type and Period";
export const LeaveRequestExistsDifferentTypeandPeriod = "Leave Request Exists With Different Type and Period";

export const LeaveExistsSameTypeandToDateOverlap = "Leave Exists With Same Type and ToDate Overlap";
export const LeaveExistsDifferentTypeandToDateOverlap = "Leave Exists With Different Type and ToDate Overlap";
export const LeaveExistsSameTypeandFromDateOverlap = "Leave Exists With Same Type and FromDate Overlap";
export const LeaveExistsDifferentTypeandFromDateOverlap = "Leave Exists With Different Type and FromDate Overlap";

export const LeaveRequestExistsSameTypeandToDateOverlap = "Leave Request Exists With Same Type and ToDate Overlap";
export const LeaveRequestExistsDifferentTypeandToDateOverlap = "Leave Request Exists With Different Type and ToDate Overlap";
export const LeaveRequestExistsSameTypeandFromDateOverlap = "Leave Request Exists With Same Type and FromDate Overlap";
export const LeaveRequestExistsDifferentTypeandFromDateOverlap = "Leave Request Exists With Different Type and FromDate Overlap";

export const LeaveExistsSameTypeBetweenSelectedPeriod = "Leave Exists With Same Type And Between The Selected Period";
export const LeaveExistsDifferentTypeBetweenSelectedPeriod = "Leave Exists With Different Type And Between The Selected Period";

export const LeaveRequestExistsSameTypeBetweenSelectedPeriod = "Leave Request Exists With Same Type And Between The Selected Period";
export const LeaveRequestExistsDifferentTypeBetweenSelectedPeriod = "Leave Request Exists With Different Type And Between The Selected Period";

export const LeaveExceededDailyDuration = "Leave Exceeded Daily Duration";
export const LeaveExceededWeeklyDuration = "Leave Exceeded Weekly Duration";
export const LeaveExceededMonthlyDuration = "Leave Exceeded Monthly Duration";
export const LeaveExceededYearlyDuration = "Leave Exceeded Yearly Duration";
export const LeaveExceededAllServiceTimeDuration = "Leave Exceeded All Service Time Duration";
export const LeaveExceededDailyOccurrence = "Leave Exceeded Daily Occurrence";
export const LeaveExceededWeeklyOccurrence = "Leave Exceeded Weekly Occurrence";
export const LeaveExceededMonthlyOccurrence = "Leave Exceeded Monthly Occurrence";
export const LeaveExceededYearlyOccurrence = "Leave Exceeded Yearly Occurrence";
export const LeaveExceededAllServiceTimeOccurrence = "Leave Exceeded All Service Time Occurrence";
export const LeaveAllowedAfter = "Leave Allowed After ";
export const LeaveAllowedBefore = "Leave Allowed Before ";
export const AllowedFlexibleDurationIs = "Allowed Flexible Duration Is ";


export const NoOrgLevelsFound = "No Orgnization Level(s) Found. Please add level(s) first to add organization units.";
export const LookUp = ' LookUp';

//Employee Leaves Request
export const EmployeeLeavesRequest = "Leaves Request";
export const LeaveRequest = "Leave Request";
export const LeaveRequestAttachments = "Leave Request Attachment";

//Plan
export const BusinessPlan = 'Business Plan';
export const PlanName = 'Plan Name';
export const PlanDescription = 'Plan Description';
export const MonthDuration = 'Month Duration';
export const CurrentlyAvailable = 'Currently Available';

export const RegistrationDate = 'Registration Date';
export const RegistrationStatus = 'Registration Status';
export const RegisteredCompanies = 'Registered Companies';
export const CompanyRegistration = 'Company Registration';
export const IsFinalApproval = 'Is Final Approval';


// HCM Summary Page
export const MyRequests = 'My Requests';
export const HistoryRequests = 'Requests History';
export const SearchMyRequests = 'Search My Requests';
export const MySummary = 'My Summary';
export const ScheduleTimeDetails = 'Schedule Time';
export const FirstIn = 'First In';
export const LastOut = 'Last Out';
export const ExpectedOut = 'Expected Out';
export const WorkDuration = 'Work Duration';
export const WorkProgress = 'Work Progress';
export const AbsentCount = 'Absent';
export const DailyLeaveCount = 'Daily Leave(s)';
export const HourlyLeaveCount = 'Hourly Leave(s)';
export const ViolationCount = 'Violation(s)';
export const RequestLeave = 'Leave Request';
export const AttendanceTransactionRequest = 'Transaction Request';
export const In = 'In';
export const Out = 'Out';
export const PreviousTransaction = 'Previous Transaction';
export const ViolationTitle = 'Violations';
export const ViolationSearch = 'Search Violations';
export const ViolationType = 'Violation Type';
export const ViolationDate = 'Violation Date';
export const ViolationDuration = 'Violation Duration';
export const ViolationStatus = 'Violation Status';
export const ViolationDetails = 'Violation Details';
export const EmployeeViolations = 'Employee Violations';
export const Delay = 'Delay';
export const EarlyOut = 'EarlyOut';
export const OutDuration = 'Out Duration';
export const MissingIn = 'Missing In';
export const MissingOut = 'Missing Out';
export const Justify = 'Justify';
export const Calendar = 'Calendar';
export const TransactionTitle = 'Transaction List';
export const TransactionSearch = 'Search Transactions';
export const today = 'today';
export const calbuttontoday = 'today';
export const calbuttonmonth = 'month';
export const calbuttonweek = 'week';
export const calbuttonday = 'day';
export const calbuttonlist = 'list';
export const EmployeeRequests = 'Employee Requests';
export const EmployeeLastTransaction = 'Employee Last Transaction';
export const Attendance = 'Attendance';
export const EmployeeScheuleDetails = 'Employee Scheule Details';
export const EmployeeCalendar = 'Employee Calendar';
export const EmployeeDashboard = 'Employee Dashboard';
export const SummaryPage = 'Summary Page';
export const EmployeeTransactions = 'Employee Transactions';
export const DateRange = 'Date Range';
export const MonthSelection = 'Month Selection';
export const RequestDailyLeave = 'Daily Leave Request';
export const RequestHourlyLeave = 'Hourly Leave Request';

// HCM Manager Summary Page
export const ContactDetails = 'Contact Details';
export const JobTitle = 'Job Title';
export const January = 'January';
export const February = 'February';
export const March = 'March';
export const April = 'April';
export const May = 'May';
export const June = 'June';
export const July = 'July';
export const Augest = 'Augest';
export const September = 'September';
export const October = 'October';
export const November = 'November';
export const December = 'December';


export const DeleteGroupConfirmMsg = 'Group should not be used in the system in order to process the deletion.Are Your Sure You want to delete this group.';
export const DeleteGroupSucess = 'Security Group has been deleted successfully.';
export const DeleteGroupError = 'Security Group can not be deleted. Please make sure it is not used in the system to process the deletion.';

export const NewPassword = 'New Password';
export const ChangePassword = 'Change Password';
export const EditDocumentTemplate = 'Edit Document Template';
export const ApprovedApps = "Approved Apps"
export const RejectedApps = "Rejected Apps"
export const MyApps = "My Apps"
export const InProgressApps = "In Progress Apps"
export const AssignedApps = "Assigned Apps"
export const WorkedApps = "Previously Worked Apps"
export const loadingapp = "Loading Application Details"
export const SubmitNow = "Submit Now"
export const Next = "Next"
export const Back = "Back"
export const ReturnHome = "Return Home"
export const Previous = "Previous"
export const HomePage = "Home Page"
export const EditDocument = "Edit Document"
export const DefineTemplates = "Templates Definition"
export const DocumentMetaData = 'Document Data';
export const Published = 'Published';
export const NotPublished = 'Not Published';
export const OldPassword = 'Old Password';
export const UserInfo = 'User Info';
export const MoveToDefinition = 'Move to Basic Definitions';
export const DefaultFormTemplate = 'Default Form Template';
export const LibraryDeleteSuccessMsg = 'Library Has been Delete Successfully';
export const LibraryDeleteErrorMsg = 'Library cannot be Deleted, Please make sure Library Does not contain any Folders or documents.';
export const NoDocumentsAllowed = 'Folder Grouping Only ( No Document Allowed)';
export const Permissions = 'Permissions';
export const PrivilligeTypes = 'Privillige Types';
export const DocumentDetails = 'Document Details';
export const ItemDetails = 'Folder Details';
export const UploadDocumentForApproval = 'Upload Document For Approval';
export const ApprovedDocument = "Approved Documents"
export const UnderApprovalDocument = "Under Approval Documents"
export const MyDocumentswaitingforapproval = " My Documents waiting for approval"
export const RecentlySharedDocuments = "Recently Shared Documents"
export const EditItem = "Edit Folder"
export const UseTemplate = "Use Template"
export const DocumentInfo = "Document Info"
export const ActivityLog = "Activity Log"
export const HasDocTemplate = 'Has Document Template';
export const HasPassword = 'Has Password';
export const Verify = 'Verify';
export const ZipFileContent = 'Zip File Content';
export const compressedSize = 'Compressed Size';
export const uncompressedSize = 'Uncompressed Size';
export const DocumentTemplates = 'Document Templates';
export const LibraryAccessablity = 'Library Accessablity';
export const FolderMetaDataTemplates = 'Folder Meta Data Templates';
export const NoDocumentTemplates = 'No Document Templates ';
export const AllDocumentTemplates = 'All Document Templates';
export const SpecificDocumentTemplates = 'Specific Document Templates';
export const HasFolderMetaData = 'Has Folder Meta Data';
export const BasedOnPrivilliges = 'Based On Privilliges';
export const Public = 'Public - For All Users';
export const LibraryDocumentTemplates = 'Library Document Templates';
export const DefaultDocumentTemplate = 'Default Document Template ';
export const DocumentaprovalWorkflow = 'Document Aproval Workflow';
export const SpecificworkflowforLibrary = 'Specific workflow for Library ';
export const AddPriviliges = "Add Priviliges";
export const ChangeDocumentTemplate = "Change Document Template";
export const ChooseFileOrDropitHere = "Choose File Or Drop Files Here";
export const ShowFilter = "Show Filter";
export const HideFilter = "Hide Filter";
export const AdvanceSettings = "Advance Settings";
export const AllLibraries = "All Libraries";
export const SearchDocument = 'Search Document';
export const DocumentList = 'Search Document List';
export const ListMyCompletedApplications = 'My Completed Applications';
export const ListMyPendingApplications = 'My Pending Applications';
export const PreviouslyWorkedApplications = 'Previously Worked Applications';
export const HCM = 'HCM';
export const DigitalProcess = 'Digital Process';
export const Documents = 'Documents';
export const HR = 'HR';
export const NotificationTemplate = 'Notifications Template';
export const LstNotificationTemplate = 'List Of Notifications Template';
export const Notifications = 'Notifications';
export const AddNotifications = 'Add Notifications';
export const TemplateName_LL = "Template Name Local Language";
export const NotificationTemplate_LL = 'Notifications Template Local Language';
export const TemplateType = "Notification Template Type";
export const OrgLogo = 'Organization Logo';
export const TreeView = 'Tree View';
export const ChartView = 'Chart View';
export const SendToApplicant = 'Send To Applicant';
export const SendToAssignee = 'Send To Assignee';
export const General = 'General';
export const SpecificFormTemplate = 'Specific Form Template';
export const Directory = 'Directory';
export const NewName = 'New Name';
export const TemplateAbbreviation = "Template Abbreviation";
export const Num = '#';
export const Template = 'Template';
export const Createddate = 'Created Date';
export const UploadDocuments = "Uploaded Documents";
export const TimeLine = "TimeLine";
export const UserProfile = "User Profile";
export const ChangePasswordMsg = "Default User Password cannot be changed other than by the user.";

//Messages
export const Application = "Application";
export const ServiceDocumentRules = "Service Document Rules";
export const RulesSuccessSave = 'Rules Have Been Saved Successfully.';
export const RulesErrorSave ='Rules has not Been Saved Successfully.';
export const RulesSuccessDelete = 'Rules Have Been Delete Successfully.';
export const RulesErrorDelete = 'Rules has not Been Delete Successfully.';
export const DynamicData = "Dynamic Data";
export const PleaseSelectRow = 'Please Select Rows To Delete';
export const MandatoryField = ' Field is Mandatory';
export const ApplicationSuccess = 'Your Application has been submitted Succesfully.';
export const LibraryArchive = 'Library Archive';
export const ApplicationCreateSuccess = 'Application Have Been Created Successfully.';
export const ApplicationCreateError = 'Application cannot be Created Successfully.';
export const SelectActionMsg =  'Please Select action before submittion';
export const SelectAssignmentMsg =  'Please Select Manual Assignment or Select Assign to Wokflow';
export const SelectPrivilligeMsg =  'Please Select Privillige to assign';
export const SelectReasonMsg = 'Please Select reason of action before submittion';
export const SelectChecklistMsg = 'Please Select Checklist before submittion';
export const AttachmentNotFoundMsg = 'Application Attachment Not Found, Please Contact Support';
export const SelectAttachmentDeleteMsg = 'Please Select Attachment to Delete';
export const AttachmentDeleteMsg = 'Attachment have been removed';
export const AttachmentAddMsg = 'Application Attachment Has Been Added';
export const SelectCategoryMsg ='Please Select By Service Category.';
export const ServiceNameMsg = 'Please Enter Service Name To Process.';
export const Service_LLNameMsg = 'Please Enter Service Name in Local Language To Process.';
export const ServiceAbbreviationMsg = 'Please Enter Service Abbreviation To Process.';
export const FormTemplateSelectMsg = 'Please Select FormTemplate To Process.';
export const WorkFlowSelectMsg = 'Please Select WorkFlow To Process.';
export const ServiceSaveSuccess = 'Service  Have Been Saved Successfully.';
export const AttachmentMandatoryMsg = ' is Mandatory. Please upload the file.';
export const ServiceSaveError = 'Service cannot be Saved Successfully.';
export const ServiceDeleteValidationMsg = "Service  can not  Be Deleted because it is used in Application Flow.";
export const ServiceDeleteSuccess =  "Service  Have Been Deleted Successfully.";
export const ServiceDeleteError =  "Service  cannot be Deleted Successfully.";
export const WorkGroupprivilligesMsg =  ': Work Group is already added in the privilliges.';
export const OrgUnitprivilligesMsg =  ': Org Unit  is already added in the privilliges.';
export const SecurityGroupprivilligesMsg =  ': Security Group is already added in the privilliges.';
export const UserprivilligesMsg =  ': User is already added in the privilliges.';
export const SelectInternalPriviligeMsg =  'Please Select Internal Privilliges To Process.';
export const SelectContactMsg = 'Please Select CRM Contact To Process.';
export const SaveServiceSecuritySuccessMsg = 'Service Security Have Been Saved Successfully.';
export const SaveServiceSecurityErrorMsg = 'Service Security cannot be Saved Successfully.';
export const SaveServiceDocSuccessMsg = 'Service Document Have Been Saved Successfully.';
export const SaveServiceDocErrorMsg = 'Service Document cannot be Saved Successfully.';
export const DeleteServiceDocSuccessMsg = 'Service Document Have Been Delete Successfully.';
export const DeleteServiceDocErrorMsg = 'Service Document cannot be Deleted Successfully.';
export const ServiceSecurity = 'Service Security';
export const ServiceArchive = 'Service Library Archive';
export const ServiceArchiveSaveSucessMsg = 'Service Archive Have Been Saved Successfully.';
export const ServiceArchiveSaveErrorMsg = 'Service Archive cannot be Saved Successfully.';
export const ServiceTemplateText = 'Service Template Text';
export const ServiceTemplateTextSaveSucessMsg = 'Template Text Have Been Saved Successfully.';
export const ServiceTemplateTextSaveErrorMsg = 'Template Text cannot be Saved Successfully.';
export const ServiceIntroSaveSucessMsg = 'Service Introduction Text Have Been Saved Successfully.';
export const ServiceIntroSaveErrorMsg =  'Service Introduction Text cannot be Saved Successfully.';
export const ServiceTOASaveSucessMsg = 'Service TOA Text Have Been Saved Successfully.';
export const ServiceTOASaveErrorMsg ='Service TOA Text cannot be Saved Successfully.';
export const ServiceOutPutSaveSucessMsg = 'Document Template has Been Saved Successfully';
export const SelectFieldToAdd ="Please Select Field To Add.";
export const ServiceAdvanceConfigureMsg = "Configuring Advance Settings Need Form Template and Workflow to be Selected in Service Definition.";
export const ServiceNotification = 'Service Notification';
export const ServiceNotificationSaveSucessMsg = 'Service Notification Have Been Saved Successfully';
export const ServiceNotificationDeleteSucessMsg = 'Service Archive have been Deleted Successfully.';
export const ServiceNotificationDeleteErrorMsg = 'Service Archive cannot be Deleted Successfully.';
export const DocumentUploadedSucess = 'Document Has been uploaded succesfully';
export const DocumentUploadedError = 'Document cannot be Saved Successfully.';
export const DocNameValidation = 'Please Enter Document Name.';
export const DocPassValidation = 'Please Enter Password For the Document.';
export const PasswordMatchValidation = 'Password Should match Confirm Password.';
export const SelectOptionToUploadDocumet = 'Please Select a option to create or uplaod document.';
export const UploadFileValidation = 'Please Upload a File.';
export const SelectTemplateValidation = 'Please Select a Template.';
export const MandatoryDocumentDataValidation = 'Please Fill Mandatory Document Data Fields.';
export const UploadFilePreviewValidation = 'Please Upload Document To Preview';
export const EnterPasswordSuccessMsg = 'Entered Password is correct';
export const EnterPasswordErrorMsg = 'Entered Password is not correct';
export const DocDeleteSuccessMsg = 'Document  has been  Deleted Successfully.';
export const DocDeleteErrorMsg = 'Document cannot be Deleted.';
export const DocCopySuccessMsg ='Document has been copied Successfully.';
export const DocCopyErrorMsg = 'Document cannot be copied.';
export const DocMoveSuccessMsg = 'Document has been moved Successfully.';
export const DocMoveErrorMsg = 'Document  cannot be Moved.';
export const DocRenameSuccessMsg = 'Document Name has been changed Successfully.';
export const DocRenameErrorMsg = 'Document Name cannot be Saved.'
export const LibraryHierarchyItem = 'Library Hierarchy Item'
export const LibraryHierarchyItemNameValidation = 'Please Enter Hierarchy Name';
export const LibraryHierarchyItemName_LLValidation = 'Please Enter Hierarchy Name in Local Language';
export const LibraryHierarchyItemSuccessMsg = 'Library Heirarchy Details Have Been Saved Successfully.';
export const LibraryHierarchyItemErrorMsg = 'Library Heirarchy Details cannot be Saved Successfully.';
export const DocAppErrorMsg =  ': Document for approval cannot be submitted Succesfully.';
export const DocAppSuccessrMsg =  ': Document for approval cannot be submitted Succesfully.'
export const TemplateNameMsg = 'Please Enter Template Name To Process.';
export const Template_LLNameMsg = 'Please Enter Template Name in Local Language To Process.';
export const TemplateAbbreviationMsg = 'Please Enter Template Abbreviation To Process.';
export const TemplateSaveSuccess = 'Template  Have Been Saved Successfully.';
export const TemplateSaveError = 'Template  cannot be Saved Successfully.';
export const TemplateDeleteValidationMsg = "Template  can not  Be Deleted because it is used in Document and Library.";
export const TemplateDeleteSuccess =  "Template  Have Been Deleted Successfully.";
export const TemplateDeleteError =  "Template  cannot be Deleted Successfully.";
export const SaveTemplateDocSuccessMsg = 'Template Document Have Been Saved Successfully.';
export const SaveTemplateDocErrorMsg = 'Template Document cannot be Saved Successfully.';
export const DeleteTemplateDocSuccessMsg = 'Template Document Have Been Delete Successfully.';
export const DeleteTemplateDocErrorMsg = 'Template Document cannot be Deleted Successfully.';
export const Priviliges = 'Priviliges';
export const OutPutDocument = 'OutPut Document';
export const PrivilegesSaveMsgSuccess = 'Privileges Have Been Saved Successfully';
export const PrivilegesDeleteMsgSuccess = 'Privileges Have Been Delete Successfully';
export const OutPutDocumentDeleteMsgSuccess =' OutPut Document Have Been Deleted Successfully';
export const OutPutDocumentSaveMsgSuccess ='OutPut Document Have Been Saved Successfully';
export const OutPutDocumentNameMsg = 'Please Enter Document Name';
export const SelectDownloadEmailMsg =  'Please Select Download or Email Status.';
export const ApplicationReAssignSuccess = 'Application  Have Been Re-Assinged Successfully.';
export const ApplicationReAssignError =  'Error while Re-Assinging Application.';
export const SelectUserMsg = 'Please Select User.';
export const SelectSecurityGroupMsg ='Please Select Security Group.';
export const SelectOrgUnitrMsg = 'Please Select Org Unit.';
export const SelectWorkGroupMsg = 'Please Select Work Group.';
export const SelectPrivilliegeTypeMsg =  'Please Select Privilliege Type.';
export const ApiParamSaveSuccess =  'API Params  Have Been Saved Successfully.';
export const ApiParamSaveError =  'API Params cannot be Saved Successfully.';
export const ApiParamDeleteSuccess =  'Api Param Have Been deleted Successfully.';
export const ApiParamDeleteError =  'Api Param cannot be deleted Successfully.';
export const ApiSaveSuccess =  'Dynamic Service Api  has Been Saved Successfully.';
export const ApiSaveError =  'Dynamic Service Api cannot be Saved Successfully.';
export const ApiDeleteSuccess =  'Dynamic Service Api Have Been deleted Successfully.';
export const ApiDeleteError =  'Dynamic Service Api cannot be deleted Successfully.';
export const ContactCompany = 'Contact Company';
export const ContactCompanySaveSuccess = 'Company Details Have been Saved Successfully';
export const ContactCompanyDocDeleteSuccess = 'Company Documents Have been Deleted Successfully';