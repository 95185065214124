
export class Registration {
  registrationId: number;
  planId: number;
  countryId: number;
  companyName: string;
  userName: string;
  password: string;
  confirmpassword: string;
  email: string;
  phoneNumber: string;
  userId: number;
  constructor() {
    this.registrationId = 0;
    this.planId = 0;
    this.countryId = 0;
    this.companyName = '';
    this.userName = '';
    this.password = '';
    this.confirmpassword = '';
    this.email = '';
    this.phoneNumber = '';
    this.userId = 0;
  }
}
export class Sys_plans_Company_registration {
  registrationId: number;
  fK_PlanId: number;
  fK_OrgCompanyId: number;
  registrationDate: Date;
  expiryDate: Date;
  oldRegistrationId: number;
  fK_lkpRegistrationStatus: number;
  registrationStatus: string;
  companyName: string;
  planName: string;
  planMonthDuration: number;
  constructor() {
    this.registrationId = 0;
    this.fK_PlanId = 0;
    this.fK_OrgCompanyId = 0;
    this.oldRegistrationId = 0;
    this.fK_lkpRegistrationStatus = 0;
    this.registrationDate = new Date();
    this.expiryDate = new Date();
  }
}

export class Sys_Plans {
  planId: number;
  planName: string;
  planCode: string;
  planDescription: string;
  monthDuration: number;
  freeMonths: number;
  fees: number;
  numberEmployees: number;
  numberWorkflows: number;
  numberServices: number;
  currentlyAvailable: boolean;
  cREATED_BY: string;
  cREATED_DATE: Date;
  lAST_UPDATE_BY: string;
  lAST_UPDATE_DATE: Date;
  planForms: Sys_Plan_Forms[];
  
  constructor() {
    this.planId = 0;
    this.planName = '';
    this.planCode = '';
    this.planDescription = '';
    this.monthDuration = 0;
    this.freeMonths = 0;
    this.fees = 0;
    this.numberEmployees = 0;
    this.numberWorkflows = 0;
    this.numberServices = 0;
    this.currentlyAvailable = false;
    this.cREATED_BY = '';
    this.lAST_UPDATE_BY = '';
    this.cREATED_DATE = new Date();
    this.lAST_UPDATE_DATE = new Date();
    this.planForms = [];
  }
}

export class Sys_Plan_Forms {
  fK_PlanId: number;
  fK_ModuleId: number;
  fK_FormId: number;
  constructor() {
    this.fK_PlanId = 0;
    this.fK_ModuleId = 0;
    this.fK_FormId = 0;
  }
}


export class Plan_ServiceUrls {
  RegisterCompany: string;
  GetAllPlans: string;
  ActivatePlan: string;

  constructor() {
    this.RegisterCompany = 'Plan/RegisterCompany';
    this.GetAllPlans = 'Plan/GetAllPlans';
    this.ActivatePlan = 'Plan/Activate';
  }
}